<div class="fantasy-panel rounded shadow overflow-hidden">
  <div class="row mx-0">
    <div class="col-lg-7 px-0 bg-muted">
      <div class="game-summary">
        <div class="text-center line-height-18 text-info">
          <p class="pt-3 mb-1 font-size-12">{{ 'pick.player.next.game.title' | translate }}</p>
          <p class="font-size-16 font-weight-bold mb-0">{{ 'pick.player.next.game.description' | translate }}</p>
        </div>
        <div class="row team-vs-team d-flex justify-content-center align-items-center mx-0">
          <div class="team home col-4">
            <img
              *ngIf="game.HomeTeamLogo"
              class="d-flex ml-auto mr-auto mt-3 mt-lg-0"
              src="{{ game.HomeTeamLogo | contentImage: '7' }}"
            />
          </div>
          <div class="versus col-4 text-center">
            <div class="svg svg-black vs-svg ml-auto mr-auto" [inlineSVG]="'assets/images/icons/vs.svg'"></div>
          </div>
          <div class="team away col-4">
            <img
              *ngIf="game.AwayTeamLogo"
              class="d-flex ml-auto mr-auto mt-3 mt-lg-0"
              src="{{ game.AwayTeamLogo | contentImage: '7' }}"
            />
          </div>
        </div>
        <div class="line-height-18 font-size-14 text-center">
          <div class="text-info">{{ gameDate | date: 'MMM dd' | uppercase }} | {{ gameTime }}</div>
        </div>
      </div>
    </div>
    <div class="col-lg-5 py-2 px-3 right-col">
      <div class="player-select text-center">
        <div class="player-image mx-auto rounded-circle border overflow-hidden">
          <img src="assets/images/placeholders/no-player-image.png" class="w-100 h-100 m-auto" />
        </div>
        <div class="question-text text-primary font-weight-bold font-size-12">
          {{ 'pick.player.next.game.no.player' | translate }}
        </div>
        <button class="btn btn-success btn-select shadow font-weight-bold w-100 mb-2" [disabled]="true">
          {{ 'pick.player.next.game.select.button' | translate }}
        </button>

        <fgb-fantasy-player-select-confirm [isGamePending]="true" [game]="game"> </fgb-fantasy-player-select-confirm>
      </div>
    </div>
  </div>
</div>
