<div class="survey-answer row my-3">
    <div class="col-md-6 order-md-0 order-12">
      <div
        class="survey-answer-container"
        [ngClass]="hasSelectedCorrect ? 'justify-content-lg-end justify-content-center mr-0 mr-lg-4' : 'justify-content-around'"
      >
        <div
          class="survey-answer-item m-2"
          [ngClass]="hasSelectedCorrect ? 'correct' : 'incorrect'"
          *ngFor="let answer of selectedAnswers"
        >
          <div class="image-container">
            <img src="{{ answer.ImageURL | contentImage: '7' }}" alt="{{ answer.ImageURL }}" />
          </div>
          <div class="answer-text text-center text-white" *ngIf="!hasSelectedCorrect">
            <ng-container *ngIf="hasSelectedCorrect else incorrect">
                <span>{{ 'survey.completed.quiz.image.answer.correct' | translate : { fgbdefault: 'Correct' } }}</span>
            </ng-container>

            <ng-template #incorrect>
                <span>{{ 'survey.completed.quiz.image.answer.incorrect' | translate : { fgbdefault: 'Incorrect' } }}</span>
            </ng-template>
          </div>
        </div>
        <div class="survey-answer-item correct m-2" *ngIf="!hasSelectedCorrect">
          <div class="image-container">
            <img src="{{ correctAnswer.ImageURL | contentImage: '7' }}" alt="{{ correctAnswer.ImageURL }}" />
          </div>
          <div class="answer-text text-center text-center text-white">
            <span>{{ 'survey.completed.quiz.image.answer.correct' | translate : { fgbdefault: 'Correct' } }}</span>
          </div>
        </div>
      </div>
    </div>
    <div class="survey-answer-text-container col-md-6 order-md-12 order-0">
      <ng-container *ngIf="hasSelectedCorrect; else incorrect">
        <div class="survey-message text-success">
          <div>
            <ng-container *ngIf="pointsWon; else noPoints">
              <div class="text-center font-weight-bold font-size-14 font-opensans-bold">{{ 'quiz.completed.correct.label' | translate : { fgbdefault: 'Correct Answer !' }  }}</div>
              <ng-container *notInPopulation="[ populations.Partial, populations.Default ]">
                <div class="text-center points-won font-weight-bold">+{{ pointsWon }} Points</div>
              </ng-container>
            </ng-container>
            <ng-template #noPoints>
              <div class="font-weight-bold font-size-14 font-opensans-bold">{{ 'quiz.completed.correct.label' | translate : { fgbdefault: 'Correct Answer !' }  }}</div>
            </ng-template>
          </div>
        </div>
      </ng-container>
      <ng-template #incorrect>
        <div class="survey-message text-danger">
          <div>
            <div class="text-center font-weight-bold font-size-14 font-opensans-bold">{{ 'quiz.completed.wrong.label' | translate : { fgbdefault: 'Wrong Answer !' } }}</div>
            <div class="text-center points-won font-weight-bold text-black">{{ 'quiz.completed.wrong.message' | translate: { fgbdefault: 'Better luck next time' } }}</div>
          </div>
        </div>
      </ng-template>
    </div>
  </div>
  