<ng-container *ngIf="transactions$ | async as transactions">
  <ng-container *ngIf="transactions$ | async as transactions">
    <div *ngFor="let day of transactions | keyvalue: keepOrder">
      <h5 class="transaction-date mt-4 mb-3">{{ day.key }}</h5>
      <div class="mb-0 mb-lg-5">
        <div class="card my-3 py-2 px-4 border-0" *ngFor="let transaction of day.value">
          <div class="row">
            <div class="col-8 text-left">
              <div class="font-weight-bold text-black transaction-text">
                {{ transaction.TransactionDescription }}
              </div>

              <div class="transaction-subtext">
                {{ transaction.TransactionDateUTC | UtcToLocaltimePipe: 'hh:mm a' | uppercase }}
              </div>
            </div>
            <div class="col-4 text-right pt-2">
              <div class="font-weight-bold transaction-text text-capitalize"
                  [ngClass]="{
                    'text-success': transaction.Amount >= 0,
                    'text-danger': transaction.Amount < 0
                  }"  
              >
                {{ transaction.Amount >= 0 ? '+ ' : ''
                }}{{ transaction.Amount | FortressCurrencyPipe: (transactionType === transactionTypes.loyalty ? 'PTS' : 'USD') }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <ng-container *ngIf="(!transactions || !hasTransactions) && transactionType === transactionTypes.loyalty">
      <div class="no-activity">
        <span class="icon material-icons material-history-icon mx-auto text-grey mt-5 mb-2">history</span>
        <h5 class="text-quaternary text-center mb-0 font-weight-bold font-size-16">{{ 'transaction.list.no.activity.title' | translate: { fgbdefault: 'Activity' } }}</h5>
        <p class="text-info text-center font-size-12 activity-description">{{ 'transaction.list.no.activity.description' | translate : { fgbdefault: 'No results found. Please try selecting a different date range' } }}</p>
      </div>
    </ng-container>
    <ng-container *ngIf="(!transactions || !hasTransactions) && transactionType === transactionTypes.ecash">
      <div>
        <img class="no-ecash-image d-flex mx-auto" src="assets/images/icons/no-transactions.svg" />
        <h5 class="text-quaternary text-center mb-0 font-weight-bold font-size-16">{{ 'transaction.list.no.transaction.title' | translate : { fgbdefault: 'No Transactions' } }}</h5>
        <p class="text-info text-center font-size-12">{{ 'transaction.list.no.transaction.description' | translate : { fgbdefault: 'Available for the dates selected' } }}</p>
      </div>
    </ng-container>
  </ng-container>
</ng-container>
<div *ngIf="loading" class="mt-4">
  <fgbcl-loading></fgbcl-loading>
</div>
