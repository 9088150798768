import { Component, OnDestroy, OnInit } from '@angular/core';
import { combineLatest, Observable, Subject } from 'rxjs';
import {
  PartnerQuery,
  PartnerPendingQuery,
  MemberDetails,
  MemberQuery,
  PartnerService,
  Partner,
  PartnerPending,
} from '@fgb/core';
import { takeUntil } from 'rxjs/operators';
import { Populations } from 'src/app/shared/utilities/populations';

@Component({
  selector: 'fgb-partner-page',
  templateUrl: './partner-page.component.html',
  styleUrls: ['./partner-page.component.scss'],
})
export class PartnerPageComponent implements OnInit, OnDestroy {
  constructor(
    private partnerQuery: PartnerQuery,
    private pendingQuery: PartnerPendingQuery,
    public memberQuery: MemberQuery,
    private partnerService: PartnerService
  ) {}
  showAddForm: boolean;
  showAcceptForm: boolean;
  partnersCount: number;
  memberDetails$: Observable<MemberDetails | undefined>;
  partnerLimit$: Observable<number>;
  partners: Partner[];
  pendingPartners: PartnerPending[];
  currentPartnerCount: number;
  populations = Populations;

  private _destroyed$ = new Subject();

  ngOnInit() {
    this.partnerLimit$ = this.partnerService.getPartnerLimit();

    combineLatest([this.partnerQuery.selectAll(), this.pendingQuery.selectAll()])
      .pipe(takeUntil(this._destroyed$))
      .subscribe(([partners, pendingPartners]) => {
        this.partners = partners;
        this.pendingPartners = pendingPartners;

        if (this.partners && this.pendingPartners) {
          this.currentPartnerCount = this.partners.length + this.pendingPartners.length;
        }
      });

    this.memberDetails$ = this.memberQuery.selectMemberDetails();
  }

  ngOnDestroy() {
    this._destroyed$.next();
    this._destroyed$.unsubscribe();
  }

  toggleAddForm() {
    this.showAddForm = !this.showAddForm;
    this.showAcceptForm = false;
  }

  toggleAcceptForm() {
    this.showAcceptForm = !this.showAcceptForm;
    this.showAddForm = false;
  }
}
