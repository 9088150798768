<div class="banner d-flex">
  <div class="banner-logo"></div>
</div>

<!--Barcode-->
<div class="d-flex" *ifScreenSize="screenType.Mobile">
  <div class="container mt-4">
    <fgb-barcode></fgb-barcode>
  </div>
</div>

<div class="d-flex flex-column">
  <ng-container *ifScreenSize="screenType.Desktop">
    <div class="game-nav-wrapper" *inPopulation="[ populations.Default, populations.Partial ]">
      <fgb-game-nav></fgb-game-nav>
    </div>
  </ng-container>

  <ng-container *ifScreenSize="screenType.Mobile">
    <div class="game-nav-wrapper" *inPopulation="[ populations.Default ]">
      <fgb-game-nav></fgb-game-nav>
    </div>
  </ng-container>

  <div class="container voucher-wrapper my-4">
    <fgb-vouchers></fgb-vouchers>
  </div>
</div>

<!--Scorecards-->
<ng-container *ifScreenSize="screenType.Mobile">
  <ng-container *inPopulation="[ populations.Default ]">
    <fgbcl-scorecard-list-view [version2Design]="true" icon="chevron_right"></fgbcl-scorecard-list-view>
  </ng-container>

  <ng-container *notInPopulation="[ populations.Default ]">
    <div class="challenges-container">
      <fgbcl-scorecard-carousel [showAllView]="true" [version2Design]="true" icon="chevron_right"></fgbcl-scorecard-carousel>
    </div>
  </ng-container>
</ng-container>

<ng-container *ifScreenSize="screenType.Desktop">
  <ng-container *inPopulation="[ populations.Orange, populations.Partner, populations.Kids ]">
    <div class="challenges-container">
      <fgbcl-scorecard-carousel [showAllView]="true" [version2Design]="true" icon="chevron_right"></fgbcl-scorecard-carousel>
    </div>
  </ng-container>
  <ng-container *inPopulation="[ populations.Partial, populations.Default ]">
    <fgbcl-scorecard-carousel [showAllView]="false" [version2Design]="true" icon="chevron_right"></fgbcl-scorecard-carousel>
  </ng-container>
</ng-container>

<ng-container *ifScreenSize="screenType.Desktop">
  <div class="container" *inPopulation="[ populations.Default, populations.Partial ]">
    <fgbcl-badge-overview-list [designV2]="true"></fgbcl-badge-overview-list>
  </div>
</ng-container>

<!--Rewards-->
<div class="pb-4 rewards-container" *inPopulation="[ populations.Orange, populations.Partner, populations.Kids ]">
  <div class="container">
    <ng-container *inPopulation="[ populations.Orange, populations.Partner ]">
      <ng-container [ngTemplateOutlet]="rewardsTemplate"
                    [ngTemplateOutletContext]="{ $implicit: '' }">
      </ng-container>
    </ng-container>
    <ng-container *inPopulation="[ populations.Kids ]">
      <ng-container [ngTemplateOutlet]="rewardsTemplate"
                    [ngTemplateOutletContext]="{ $implicit: 'home.page.kids.featured.rewards.title' | translate }">
      </ng-container>
    </ng-container>
  </div>
</div>

<ng-template #rewardsTemplate let-title>
  <fgbcl-featured-rewards [showAllPath]="'/rewards/marketplace'" [title]="title"></fgbcl-featured-rewards>
</ng-template>

<ng-container *notInPopulation="[ populations.Default ]">
  <div class="video-container">
    <ng-container *ngIf="worksheets$ | withLoading | async as worksheets">
        <div *ngFor="let worksheetGroup of worksheets.data">
          <ng-container *ngIf="worksheetGroup.Items?.length > 0">
              <div class="container video-wrapper mb-4" *ngFor="let item of worksheetGroup.Items">
                <h2>{{ item?.Title }}</h2>
                  <fgb-worksheet-item [worksheetItem]="item" [worksheetId]="worksheetGroup.WorksheetId"></fgb-worksheet-item>
              </div>
          </ng-container>
        </div>
    </ng-container>
  </div>
</ng-container>

<ng-container *inPopulation="[ populations.Kids ]">
  <div class="featured-games-container bg-primary">
    <div class="container">
      <fgb-featured-surveys></fgb-featured-surveys>
    </div>
  </div>
</ng-container>

<!-- Announcements -->
<div class="container announcement-container">
  <div class="row">
    <div class="col-12">
      <fgbcl-announcements></fgbcl-announcements>
    </div>
  </div>
</div>  

<!--Vouchers-->
<!-- <ng-container *inPopulation="[populations.Orange]">
  <div class="container mb-4">
    <fgb-vouchers></fgb-vouchers>
  </div>
</ng-container> -->

