<div class="banner" role="banner">
</div>

<div class="container">
  <div class="pt-4">
    <fgbcl-terms-and-conditions [type]="termsAndConditionsType" [defaultTerms]="defaultTemplate"></fgbcl-terms-and-conditions>
  </div>
</div>

<ng-template #defaultTemplate>
  <p class="terms-section-content text-left mb-3">T&Cs not available</p>
</ng-template>
