<div class="points-management-placeholder mb-4">
  <div class="row">
    <div class="col-lg-5 col-12">
      <img src="https://via.placeholder.com/388x162.png" />
    </div>

    <div class="col-lg-2 col-12 px-4 d-flex flex-column justify-content-between">
      <div class="row">
        <div class="col-6 col-md-12 points-balance-box pt-2 mb-3">
          <p class="points-balance-text font-weight-bold mb-0">Points Balance</p>
          <h3 class="points-balance-value">{{ loyaltyPointsCard$ | async | number: '1.0-0' }}</h3>
        </div>

        <div *ngIf="memberDetails$ | async as memberDetails" class="col-6 col-md-12 account-number-box pt-2">
          <p class="account-number-text mb-0">Account number</p>
          <p class="account-number-value">{{ memberDetails!.ExternalRefNumber }}</p>
        </div>
      </div>
    </div>

    <div class="col-lg-5 placeholder-text-box px-4 col-12">
      <p>
        NATS PLUS Members have the opportunity to share their points. Easily transfer your Red Carpet Rewards points to partners
        by selecting them from the list below. To transfer points to non-partners please enter their Account Number. After you’ve
        made your selection and allocated your points please click on “Transfer”.
      </p>
    </div>
  </div>
</div>
