<div class="full-page w-100 d-md-flex d-block px-0">
  <!--Left Side-->
  <img class="login-banner" draggable="false" src="assets/images/login-registration/ducks-login-banner.png" />
  <!--Right Side-->
  <div class="p-4 right-side-login text-center mx-auto">
    <img src="assets/images/logo.svg" />

    <div class="d-flex align-items-center justify-content-center my-5 py-lg-5 py-0">
      <h5 class="text-black text-center my-5" [innerHTML]="'maintenance.page.description' | translate | markdownTranslate"></h5>
    </div>
  </div>
</div>
