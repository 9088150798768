<!--Banner-->
<div class="d-none d-lg-block">
  <div class="banner" role="banner">
  </div>
  <!--Mixed Loyalty Nav-->
  <fgb-mixed-loyalty-nav></fgb-mixed-loyalty-nav>
</div>
<!--Poimt Summary Bar-->
<div class="container">
  <fgb-points-summary-bar [backButtonLink]="'/rewards/marketplace'" [backButtonTitle]="'Raffles'"></fgb-points-summary-bar>
</div>
<!--Lotto Details-->
<fgbcl-lotto-details [lottoId]="id"></fgbcl-lotto-details>
<!--Back Button-->
<div class="container">
  <div class="text-quaternary pt-3 cursor-pointer d-none d-lg-flex w-20 align-items-center" [routerLink]="'/rewards/marketplace'">
    <i class="icon material-icons">chevron_left</i>
    <span class="font-size-12 font-weight-bold">{{ 'back.to.rewards' | translate }}</span>
  </div>
</div>
