<div class="d-block">
  <ul class="nav nav-secondary mixed-loyalty-nav">
    <ng-container *notInPopulation="[ populations.Partial, populations.Kids ]">
      <ng-container *ngTemplateOutlet="challengesTemplate"></ng-container>
    </ng-container>
    <ng-container *inPopulation="[ populations.Partial ]">
      <ng-container *ifScreenSize="screenType.Desktop">
        <ng-container *ngTemplateOutlet="challengesTemplate"></ng-container>
      </ng-container>
      <ng-container *ifScreenSize="screenType.Mobile">
        <a class="nav-item" 
            [routerLink]="'/badges'" 
            [routerLinkActive]="'active'" 
            [routerLinkActiveOptions]="{ exact: true }">
              <div class="icon material-icons" aria-hidden="true">local_police</div>
              <label>{{ 'account.nav.challenges' | translate }}</label>
        </a>  
      </ng-container>
    </ng-container>
    <ng-template #challengesTemplate>
      <a class="nav-item" 
          [ngClass]="{
            'active': isActiveLink
          }" 
          [routerLink]="[link]" 
          [routerLinkActive]="'active'" 
          [routerLinkActiveOptions]="{ exact: true }">
            <div class="icon material-icons" aria-hidden="true">local_police</div>
            <label>{{ 'account.nav.challenges' | translate }}</label>
      </a>  
    </ng-template>
    <ng-container *notInPopulation="[ populations.Kids ]">
      <a
        class="d-block d-lg-none nav-item"
        [routerLink]="['/achievements']"
        [routerLinkActive]="'active'"
        [routerLinkActiveOptions]="{ exact: true }"
      >
        <div class="icon material-icons" aria-hidden="true">verified</div>
        <label>{{ 'account.nav.achievements' | translate }}</label>
      </a>
    </ng-container>
    <ng-container *inPopulation="[ populations.Kids ]">
      <a
        class="nav-item"
        [routerLink]="['/worksheet']"
        [routerLinkActive]="'active'"
        [routerLinkActiveOptions]="{ exact: true }"
      >
        <div class="icon material-icons worksheet-icon" aria-hidden="true">draw</div>
        <label>{{ 'account.nav.worksheets' | translate : { fgbdefault: 'Worksheets' } }}</label>
      </a>
    </ng-container>
    <a
      class="nav-item"
      [routerLink]="['/games/quiz']"
      [routerLinkActive]="'active'"
      [routerLinkActiveOptions]="{ exact: true }"
    >
      <div class="icon material-icons" aria-hidden="true">live_help</div>
      <label>{{ 'games.nav.trivia' | translate }}</label>
    </a>
    <a
      class="nav-item"
      [routerLink]="['/games/polls']"
      [routerLinkActive]="'active'"
      [routerLinkActiveOptions]="{ exact: true }"
    >
      <div class="icon material-icons" aria-hidden="true">assessment</div>
      <label>{{ 'games.nav.polls' | translate }}</label>
    </a>
    <ng-container *notInPopulation="[ populations.Kids ]">
      <a
        class="nav-item reward-nav-item"
        [routerLink]="['/games/predictor']"
        [routerLinkActive]="'active'"
        [routerLinkActiveOptions]="{ exact: true }"
        attr.aria-label="{{ 'game.nav.predictor.screenreader' | translate: { fgbdefault: 'Move to Predictor page' } }}"
      >
        <div class="icon material-icons" aria-hidden="true">flaky</div>
        <label>{{ 'games.nav.predictor' | translate }}</label>
      </a>
    </ng-container>
  </ul>
</div>
