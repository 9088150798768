<!--Barcode-->
<div class="d-flex" *ifScreenSize="screenType.Mobile">
  <div class="container my-4">
      <fgb-barcode [options]="{ showWalletButton: showWalletButton }"></fgb-barcode>
  </div>
</div>

<nav class="nav nav-secondary mixed-loyalty-nav my-0">
  <a
    class="nav-item"
    [routerLink]="['/rewards/marketplace']"
    [routerLinkActive]="'active'"
    [routerLinkActiveOptions]="{ exact: true }"
  >
    <fgbcl-loyalty-icon [loyaltyType]="'marketplace'"></fgbcl-loyalty-icon>
    <label>
      <ng-container *notInPopulation="[ populations.Kids ]">
        {{
          'rewards.nav.marketplace' | translate
        }}
      </ng-container>
      <ng-container *inPopulation="[ populations.Kids ]">
        {{
          'rewards.nav.kids.rewards' | translate : { fgbdefault: 'Rewards' }
        }}
      </ng-container>
    </label>
  </a>
  <a
    class="nav-item"
    [routerLink]="['/rewards/events']"
    [routerLinkActive]="'active'"
    [routerLinkActiveOptions]="{ exact: true }"
  >
    <fgbcl-loyalty-icon [loyaltyType]="3"></fgbcl-loyalty-icon>
    <ng-container *notInPopulation="[ populations.Kids ]">
      <label>{{ 'rewards.nav.events' | translate }}</label>
    </ng-container>

    <ng-container *inPopulation="[ populations.Kids ]">
      <label>{{ 'rewards.nav.kids.events' | translate : { fgbdefault: 'Events' } }}</label>
    </ng-container>
  </a> 
  <ng-container *notInPopulation="[ populations.Kids ]">
    <a
      class="nav-item"
      [routerLink]="['/rewards/auctions']"
      [routerLinkActive]="'active'"
      [routerLinkActiveOptions]="{ exact: true }"
    >
      <fgbcl-loyalty-icon [loyaltyType]="'auction'"></fgbcl-loyalty-icon>
      <label>{{ 'rewards.nav.auctions' | translate }}</label>
    </a>
    <a
      class="nav-item"
      [routerLink]="['/rewards/raffles']"
      [routerLinkActive]="'active'"
      [routerLinkActiveOptions]="{ exact: true }"
    >
      <fgbcl-loyalty-icon [loyaltyType]="'lotto'"></fgbcl-loyalty-icon>
      <label>{{ 'rewards.nav.lotto' | translate }}</label>
    </a>
  </ng-container>
  <ng-container *notInPopulation="[ populations.Kids ]">
    <a
      class="nav-item d-none d-lg-block"
      [routerLink]="['/rewards/wallet']"
      [routerLinkActive]="'active'"
      [routerLinkActiveOptions]="{ exact: true }"
    >
      <fgbcl-loyalty-icon [loyaltyType]="'wallet'"></fgbcl-loyalty-icon>
      <label>{{ 'rewards.nav.wallet' | translate }}</label>
    </a>
  </ng-container>
  <ng-container *inPopulation="[ populations.Kids ]">
    <a
      class="nav-item"
      [routerLink]="['/rewards/wallet']"
      [routerLinkActive]="'active'"
      [routerLinkActiveOptions]="{ exact: true }"
    >
      <fgbcl-loyalty-icon [loyaltyType]="'wallet'"></fgbcl-loyalty-icon>
      <label>{{ 'rewards.nav.wallet' | translate }}</label>
    </a>
  </ng-container>
</nav>
