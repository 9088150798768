import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import {
  ActionLabels,
  MarketplaceExtendedQuery,
  MarketplaceItemExtended,
  MarketplaceService,
  ProcessProviderId,
} from '@fgb/core';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { Populations } from 'src/app/shared/utilities/populations';

@Component({
  selector: 'fgb-marketplace-detail-page',
  templateUrl: './marketplace-detail-page.component.html',
  styleUrls: ['./marketplace-detail-page.component.scss'],
})
export class MarketplaceDetailPageComponent implements OnInit {
  id: number;
  actionLabels = ActionLabels;
  marketplaceItem$: Observable<MarketplaceItemExtended | undefined>;
  marketplaceItemType: string;
  marketplaceItemGroup: string;
  backLink: string = '/rewards/marketplace';
  populations = Populations;

  constructor(
    private route: ActivatedRoute,
    private marketplaceExtendedQuery: MarketplaceExtendedQuery,
    private marketplaceService: MarketplaceService
  ) {}

  ngOnInit() {
    this.id = +(this.route.snapshot.paramMap.get('id') || 0);

    this.marketplaceService
      .fetchMarketplaceItemById(this.id)
      .toPromise()
      .then(() => {
        this.marketplaceItem$ = this.marketplaceExtendedQuery.selectExtendedMarketplaceItem(this.id).pipe(
          tap((item) => {
            if (item) {
              this._updateMarketplaceItemType(item);
            }
          })
        );
      });
  }

  private _updateMarketplaceItemType(marketplaceItem: MarketplaceItemExtended) {
    switch (marketplaceItem.ProcessProviderId) {
      case ProcessProviderId.Event:
        this.backLink = '/rewards/events';
        this.marketplaceItemType = 'Experiences';
        this.marketplaceItemGroup = 'Experiences';
        break;
      case ProcessProviderId.VirtualEvent:
        this.backLink = '/rewards/events';
        this.marketplaceItemType = 'Event';
        this.marketplaceItemGroup = 'Events';
        break;
      case ProcessProviderId.Product:
        this.backLink = '/rewards/products';
        this.marketplaceItemType = 'Marketplace';
        this.marketplaceItemGroup = 'Marketplace';
        break;
      default:
        this.marketplaceItemType = 'Marketplace';
        this.marketplaceItemGroup = 'Marketplace';
    }
  }
}
