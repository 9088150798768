
<div class="banner" role="banner">
</div>

<fgb-account-nav></fgb-account-nav>
<div class="container mt-lg-4">
  <div class="row">
    <div class="col-12 col-lg-6 questions-container">
      <div class="d-lg-block d-none">
        <h5 class="mb-3 mt-lg-0 member-card-title">{{ 'account.member.card.title' | translate }}</h5>
        <fgb-member-card showSeating="true"></fgb-member-card>
      </div>
      <div class="mt-2 mt-lg-4 mb-3">
        <h5 class="text-left mb-3 mt-4 mt-lg-0 edit-question-title">{{ 'account.answer.title' | translate }}</h5>
        <fgbcl-edit-questions></fgbcl-edit-questions>
      </div>
    </div>
    <div class="col-12 col-lg-6 user-details-container">
      <h5 class="my-3 mt-lg-0 account-details-title">{{ 'account.details.title' | translate }}</h5>
      <fgb-account-details></fgb-account-details>
    </div>
  </div>
</div>
