import { Component, Input, OnInit } from '@angular/core';
import { CampaignEntitlements } from '@fgb/core';

@Component({
  selector: 'fgb-campaigns-list-item',
  templateUrl: './campaigns-list-item.component.html',
  styleUrls: ['./campaigns-list-item.component.scss'],
})
export class CampaignsListItemComponent implements OnInit {
  @Input() campaignEntitlement: CampaignEntitlements;
  constructor() {}

  ngOnInit(): void {}
}
