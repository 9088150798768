import { Component, OnInit } from '@angular/core';
import { TicketQuery, Ticket } from '@fgb/core';
import { Observable } from 'rxjs';
import { GameQuery } from '@fgb/core';
import { Game } from '@fgb/core';

@Component({
  selector: 'fgb-ticket-transfer-success-page',
  templateUrl: './ticket-transfer-success-page.component.html',
  styleUrls: ['./ticket-transfer-success-page.component.scss']
})
export class TicketTransferSuccessPageComponent implements OnInit {
  transferedTickets$: Observable<Ticket[]>;
  selectedGame: Game;

  constructor(
    private ticketQuery: TicketQuery,
    private gameQuery: GameQuery
  ) {}

  ngOnInit() {
    this.transferedTickets$ = this.ticketQuery.selectTransferredTickets();
    this.selectedGame = this.gameQuery.getActive() as Game;
  }
}
