<div class="banner d-flex">
</div>

<!--Barcode-->
<div class="d-flex d-lg-none">
  <div class="container barcode-container my-4">
    <fgb-barcode></fgb-barcode>
  </div>
</div>

<!--Vouchers-->
<ng-container *ifScreenSize="screenType.Mobile">    
    <ng-container *inPopulation="[populations.Default]">
      <div class="container my-4">
        <fgb-vouchers></fgb-vouchers>
      </div>
    </ng-container>
</ng-container>

<div class="mt-lg-0 mt-2" *notInPopulation="[ populations.Kids ]">
    <fgb-game-nav></fgb-game-nav>
</div>

<ng-container *ifScreenSize="screenType.Desktop">
    <fgbcl-scorecard-carousel [showAllView]="false" [version2Design]="true" icon="chevron_right"></fgbcl-scorecard-carousel>
</ng-container>

<ng-container *ifScreenSize="screenType.Mobile">    
  <ng-container *notInPopulation="[ populations.Kids ]">
    <fgbcl-scorecard-list-view [version2Design]="true" icon="chevron_right"></fgbcl-scorecard-list-view>
  </ng-container>
  <ng-container *inPopulation="[ populations.Kids ]">
    <fgbcl-scorecard-carousel [showAllView]="false" [version2Design]="true" icon="chevron_right"></fgbcl-scorecard-carousel>
    <div class="container">
      <fgbcl-badge-overview-list [designV2]="true"></fgbcl-badge-overview-list>
    </div>
  </ng-container>
</ng-container>

<div class="container mt-4" *ifScreenSize="screenType.Desktop">
    <fgbcl-badge-overview-list [designV2]="true"></fgbcl-badge-overview-list>
</div>
