import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from 'src/app/shared/shared.module';
import { QRCodeModule } from 'angularx-qrcode';
import { RouterModule } from '@angular/router';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { InlineSVGModule } from 'ng-inline-svg';
import { MobilePassComponent } from '../passes/components/mobile-pass/mobile-pass.component';
import { MobilePassModalComponent } from '../passes/components/mobile-pass-modal/mobile-pass-modal.component';
import { MissingTranslationHandler, TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { FGBMissingTranslationHandler, FGBTranslationLoader } from '@fgb/core';
import { HttpClient } from '@angular/common/http';

@NgModule({
  declarations: [MobilePassComponent, MobilePassModalComponent],
  exports: [MobilePassComponent],
  imports: [
    NgbModule,
    CommonModule,
    SharedModule,
    QRCodeModule,
    RouterModule,
    InlineSVGModule.forRoot(),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useClass: FGBTranslationLoader,
        deps: [HttpClient],
      },
      missingTranslationHandler: {
        provide: MissingTranslationHandler,
        useClass: FGBMissingTranslationHandler,
      },
    }),
  ],
})
export class PassesModule {}
