import { Component, OnDestroy, OnInit, Renderer2 } from '@angular/core';
import { LoggingService, MemberQuery, PopulationService, SignalRHub } from '@fgb/core';
import { ScreenType } from '@fgb/portal-component-library/src/lib/directives';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { Populations } from 'src/app/shared/utilities/populations';

@Component({
  selector: 'fgb-master-page',
  templateUrl: './master-page.component.html',
  styleUrls: ['./master-page.component.scss'],
})
export class MasterPageComponent implements OnInit, OnDestroy {
  private _destroyed$ = new Subject();
  screenType = ScreenType;
  populationId = 0;

  constructor(private signalRHub: SignalRHub, 
              private logService: LoggingService, 
              private memberQuery: MemberQuery,
              private populationService: PopulationService,
              private renderer: Renderer2) {}

  ngOnInit() {
    this.signalRHub.connectionEstablished$.pipe(takeUntil(this._destroyed$)).subscribe((connection) => {
      this.logService.debug('SignalR connection', connection);
    });
    
    this.populationId = this.memberQuery.getPopulationId();
    this.populationService.fetchPopulations().toPromise();

    this.setTheme();
  }

  ngOnDestroy(): void {
    this._destroyed$.next();
    this._destroyed$.complete();
  }

  setTheme(): void{
    this.renderer.addClass(document.body, this._getTheme(this.memberQuery.getUserContext().populationName as Populations));
  }
  
  /**
   * Get theme class name based on population
   */
  private _getTheme(population: Populations): string{
    const suffix = 'theme';
    switch(population){
      case Populations.Orange:
        return `orange-alliance-${suffix}`;
      case Populations.Partial:
        return `partial-plan-${suffix}`;
      case Populations.Partner:
        return `partner-${suffix}`;
      case Populations.Kids:
        return `wild-wingers-${suffix}`;
      default:
        // Fan as default theme
        return `fan-${suffix}`;
    }
  }
}
