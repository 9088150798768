<ng-container *ngIf="memberCard$ | async as memberCard; else loadingRef">
  <h2 class="accessibility-only">Member Card</h2>
  <div class="member-card text-white shadow-none">
    <div class="d-flex flex-column h-100">
      <div class="d-flex content-wrapper">
        <!--Name and ID-->
        <div class="card-id text-left">
          <p class="mb-1 text-secondary card-name w-100" *ngIf="memberDetails$ | async as memberDetails">
            {{ memberDetails.FirstName }}
          </p>
          <div class="d-flex font-size-12">
            <div>
              <label *inPopulation="[ populations.Orange ]">
                <div>
                  <label class="mb-1">{{ 'membercard.productcode.orange' | translate : { fgbdefault: 'Season' } }}</label>
                </div>
              </label>
              <label *inPopulation="[ populations.Default ]">
                <div>
                  <label class="mb-1">{{ 'membercard.productcode.fan' | translate : { fgbdefault: 'Ducks Fan' } }}</label>
                </div>
              </label>
              <label *inPopulation="[ populations.Partial ]">
                <div>
                  <label class="mb-1">{{ 'membercard.productcode.partial' | translate : { fgbdefault: 'Plan Holder' } }}</label>
                </div>
              </label>
              <label *inPopulation="[ populations.Partner ]">
                <div>
                  <label class="mb-1">{{ 'membercard.productcode.partner' | translate : { fgbdefault: 'Partner' } }}</label>
                </div>
              </label>
            </div>
            <div class="px-1">-</div>
            <div>{{ memberCard.ExternalRefNumber }}</div>
          </div>
        </div>
          <!--Discount-->
          <ng-container *inPopulation="[ populations.Partner ]">
            <ng-container *ngIf="memberCard?.DiscountLevel">
              <div class="card-id text-left pl-3 ml-auto mb-2">
                <label class="mb-1">{{ 'member.card.discount' | translate }}</label>
                <div class="font-size-14">{{ memberCard?.DiscountLevel }}%</div>
              </div>
            </ng-container>
          </ng-container>
      </div>
      <hr class="bg-white" />
      <div class="content-wrapper d-flex">
      <ng-container *inPopulation="[populations.Orange, populations.Partner ]">
        <!--Balances-->
        <div class="d-flex justify-content-between align-items-center">
          <div class="d-flex align-items-end mb-1">
            <div class="card-account-no text-left pr-3" *ngIf="pointsBalance$">
              <label class="mb-1">{{ 'member.card.points.balance' | translate }}</label>
              <div class="font-size-14">{{ pointsBalance$ | async | number }}</div>
            </div>
            <div class="card-id text-left pl-3 pr-3" *ngIf="ecash$">
              <label class="mb-1">{{ 'member.card.ecash.balance' | translate }}</label>
              <div class="font-size-14">{{ ecash$ | async | FortressCurrencyPipe }}</div>
            </div>
            <ng-container *inPopulation="[ populations.Orange ]">
              <div class="card-id text-left pl-3 pr-3" *ngIf="memberCard?.DiscountLevel">
                <label class="mb-1">{{ 'member.card.discount.label' | translate : { fgbdefault: 'Concessions' } }}</label>
                <div class="font-size-14">{{ memberCard?.DiscountLevel }}%</div>
              </div>
              <div class="card-id text-left pl-3 pr-3" *ngIf="memberCard?.RetailDiscountLevel">
                <label class="mb-1">{{ 'member.card.retail.discount.label' | translate : { fgbdefault: 'Merchandise' } }}</label>
                <div class="font-size-14">{{ memberCard?.RetailDiscountLevel }}%</div>
              </div>
            </ng-container>
          </div>
        </div>
      </ng-container>
      </div>
    </div>
  </div>
</ng-container>

<ng-template #loadingRef>
  <fgbcl-loading></fgbcl-loading>
</ng-template>
