import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import { Ticket, TicketQuery, GameQuery, Game, GameService, GameDataSource } from '@fgb/core';

import * as _moment from 'moment';
import { delay, tap } from 'rxjs/operators';
const moment = _moment;

@Component({
  selector: 'fgb-tickets-page',
  templateUrl: './tickets-page.component.html',
  styleUrls: ['./tickets-page.component.scss'],
})
export class TicketsPageComponent implements OnInit {
  selectedTickets: number = 0;
  gameNumber: number;
  ticketList$: Observable<Ticket[]>;
  transferredTicketList$: Observable<Ticket[]>;
  availableTicketList$: Observable<Ticket[]>;
  pastGame: boolean = false;
  minDateString: string = '0001-01-01T00:00:00';
  activeGame$: Observable<Game | undefined>;
  constructor(
    private route: ActivatedRoute,
    private ticketQuery: TicketQuery,
    private gameQuery: GameQuery,
    private gameService: GameService
  ) {}

  ngOnInit() {
    let gameNumber = this.route.snapshot.paramMap.get('gameNumber');
    if (gameNumber) {
      this.gameNumber = +gameNumber;
    }

    this.transferredTicketList$ = this.ticketQuery.selectTransferredTicketsForGame(this.gameNumber).pipe(delay(0));

    this.ticketList$ = this.ticketQuery.selectTicketsForGame(this.gameNumber).pipe(
      tap((tickets) => tickets.sort((a, b) => a.Flag - b.Flag)),
      delay(0)
    );

    this.availableTicketList$ = this.ticketQuery.selectAvaialbleTicketsForGame(this.gameNumber).pipe(delay(0));

    this.fetchGamesAndSetActiveGame();
    this.activeGame$ = (this.gameQuery.selectActive() as Observable<Game | undefined>).pipe(tap((game) => this.isPastGame(game)));
  }

  /**
   * Will check if the current
   * game is in the past
   * @param game game to be checked
   */
  isPastGame(game: Game | undefined) {
    if (!!game) {
      if (moment.utc(game.Date).endOf('day').isBefore(moment.utc())) {
        this.pastGame = true;
      }
    }
  }

  /**
   * Will fetch data if not cached but will also
   * set a game as active.
   */
  fetchGamesAndSetActiveGame() {
    let activeGame = this.gameQuery.getActive() as Game;
    if (activeGame) {
      if (moment.utc(activeGame.Date).endOf('day').isBefore(moment.utc())) {
        this.pastGame = true;
      }
    } else {
      this.gameService.fetchGamesAndSetActiveGame(this.gameNumber, GameDataSource.ExternalRef);
    }
  }

  TransferWindowExists(startDate: Date | string, endDate: Date | string) {
    return !(
      moment.utc(startDate).isSame(moment.utc(this.minDateString)) || moment.utc(endDate).isSame(moment.utc(this.minDateString))
    );
  }

  IsWindowOpen(startDate: string | Date, endDate: string | Date) {
    return moment.utc(startDate).isBefore(moment.utc()) && moment.utc(endDate).isAfter(moment.utc());
  }
}
