<div class="banner" role="banner">
</div>

<div class="container">
  <div class="contact-us-box rounded p-3 bg-white shadow">
    <div class="col-12 col-md-8 offset-lg-2">
      <form [formGroup]="contactUsForm">
        <div class="contact-title-text font-weight-bold mt-2 mb-1 font-size-12">
          {{ 'contact.us.category.title' | translate }}
        </div>
        <div>
          <div class="dropdown">
            <select class="contact-us-dropdown w-100 text-left rounded p-2 text-black font-size-14" formControlName="category">
              <option *ngFor="let category of categories$ | async" [value]="category">
                {{ category }}
              </option>
            </select>
          </div>
        </div>
        <div>
          <div class="contact-title-text font-weight-bold mt-2 mb-1 font-size-12">
            {{ 'contact.us.message.title' | translate }}
          </div>
          <textarea class="message-box input-group h-200 rounded" formControlName="message"></textarea>
          <div
            class="alert alert-danger"
            *ngIf="contactUsForm.get('message')?.hasError('required') && contactUsForm.get('message')?.touched"
          >
            {{ 'contact.us.message.required' | translate }}
          </div>
          <div
            class="alert alert-danger"
            *ngIf="contactUsForm.get('message')?.hasError('empty') && contactUsForm.get('message')?.touched"
          >
            {{ 'contact.us.message.empty' | translate : { fgbdefault: 'Message cannot be empty' } }}
          </div>
          <div
            class="alert alert-danger"
            *ngIf="contactUsForm.get('message')?.hasError('htmlError') && contactUsForm.get('message')?.touched"
          >
            {{ 'contact.us.message.html.error' | translate : { fgbdefault: 'Message should not contain any code' } }}
          </div>
        </div>
        <button
          class="send-button font-size-14 d-block ml-auto mr-auto rounded mt-4 bg-secondary font-weight-bold text-white mb-2 border-0"
          type="submit"
          #submitBtn
        >
          {{ 'contact.us.submit.button' | translate }}
        </button>
        <div class="success-message text-center text-success" *ngIf="showSuccessMessage$ | async">
          {{ 'contact.us.message.successful' | translate }}
        </div>
      </form>
    </div>
  </div>
</div>
