<div class="card redeem-card rounded py-3 mr-2 cursor-pointer">
  <div class="d-flex justify-content-center mb-2">
    <div class="back-button"></div>
    <img
      [ngClass]="{
        'image-opacity': campaignEntitlement.campaignRedemptions_Quantity == 0
      }"
      alt=""
      [src]="campaignEntitlement.image_URL"
      class="logo mb-3 rounded"
    />
    <ng-container *ngIf="campaignEntitlement.campaignRedemptions_Quantity == 0">
      <span class="back-button icon material-icons text-grey text-right"> lock </span>
    </ng-container>
    <ng-container *ngIf="campaignEntitlement.campaignRedemptions_Quantity > 0">
      <span class="back-button icon material-icons text-success text-right font-weight-bold"> check_circle </span>
    </ng-container>
  </div>
  <div class="text-center font-size-12 font-weight-bold campaign-name">{{ campaignEntitlement.CampaignName }}</div>

  <div class="font-size-12 text-danger text-center pt-3">
    {{ 'offer.expiry' | translate }} {{ campaignEntitlement.Campaign_ExpiryDate | date : 'MM/dd/YYYY' }}
  </div>
</div>
