import { Component, OnInit } from '@angular/core';
import { Survey, SurveyQuery, SurveyService, SurveyType } from '@fgb/core';
import { ScreenType } from '@fgb/portal-component-library/src/lib/directives';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { combineLatest, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { OwlCarouselSettings } from 'src/app/shared/utilities/owl-carousel-settings';

@Component({
  selector: 'fgb-featured-surveys',
  templateUrl: './featured-surveys.component.html',
  styleUrls: ['./featured-surveys.component.scss'],
})
export class FeaturedSurveysComponent implements OnInit {

  screenType = ScreenType;
  mixedSurveys$: Observable<Survey[]>;
  customOptions: OwlOptions = {
    autoHeight: true,
    margin: OwlCarouselSettings.Margin,
    autoWidth: true,
    pullDrag: false,
    autoplay: OwlCarouselSettings.Autoplay,
    autoplayTimeout: OwlCarouselSettings.AutoplayTimeout,
    autoplaySpeed: OwlCarouselSettings.AutoplaySpeed,
    navSpeed: 700,
    navText: ['', ''],
    responsive: {
      0: { items: 1 },
      540: { items: 1 },
      740: { items: 1 },
    },
  };
  
  constructor(private surveyQuery: SurveyQuery, 
              private surveyService: SurveyService) {}

  ngOnInit(): void {
    this.surveyService.fetchAllSurveys(true, true, false, true).toPromise();
    
    let predictors$ = this.surveyQuery
      .selectFavoriteSurvey(SurveyType.Predictor)
      .pipe(map((survey) => survey.filter((s) => !s.ComingSoon)));
    let polls$ = this.surveyQuery
      .selectFavoriteSurvey(SurveyType.Poll)
      .pipe(map((survey) => survey.filter((s) => !s.ComingSoon)));
    let quizzes$ = this.surveyQuery
      .selectFavoriteSurvey(SurveyType.Trivia)
      .pipe(map((survey) => survey.filter((s) => !s.ComingSoon)));

    this.mixedSurveys$ = combineLatest([ predictors$, polls$, quizzes$ ]).pipe(
      map(([ predictor, polls, quizzes ]) => ([ ...polls, ...quizzes, ...predictor ]))
    );
  }

  trackByFn(index: number, item: Survey){
    return index;
  }
}
