import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { config, CompanyQuery, Company, CompanyOfferModel, CompanyOfferQuery, AnnouncementService, WorksheetService, WorksheetQuery } from '@fgb/core';
import { ScreenType } from '@fgb/portal-component-library/src/lib/directives';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { OwlCarouselSettings } from 'src/app/shared/utilities/owl-carousel-settings';
import { Populations } from 'src/app/shared/utilities/populations';

@Component({
  selector: 'fgb-home-page',
  templateUrl: './home-page.component.html',
  styleUrls: ['./home-page.component.scss'],
})
export class HomePageComponent implements OnInit {
  config = config;
  companies$: Observable<Company[]>;
  worksheets$ = this.worksheetQuery.getWorksheetByRoute((''));
  featuredOffers$: Observable<CompanyOfferModel[]>;
  populations = Populations;
  screenType = ScreenType;

  customOptions: OwlOptions = {
    autoHeight: true,
    autoWidth: true,
    margin: OwlCarouselSettings.Margin,
    autoplay: OwlCarouselSettings.Autoplay,
    autoplayTimeout: OwlCarouselSettings.AutoplayTimeout,
    autoplaySpeed: OwlCarouselSettings.AutoplaySpeed,
    navSpeed: 700,
    navText: ['', ''],
    responsive: {
      0: { items: 1 },
      740: { items: 2 },
      900: { items: 3 },
    },
  };

  constructor(
    private companyQuery: CompanyQuery,
    private companyOfferQuery: CompanyOfferQuery,
    private router: Router,
    private announcementService: AnnouncementService,
    private worksheetQuery: WorksheetQuery,
    private worksheetService: WorksheetService
  ) {}

  ngOnInit() {
    this.featuredOffers$ = this.companyOfferQuery.selectFeaturedOffers();
    this.companies$ = this.companyQuery.selectFavouriteCompanies().pipe(
      map((companies) => {
        return companies;
      })
    );
    this.announcementService.fetchAnnouncements().toPromise();
    
    this.worksheetService.fetchWorksheetByRoute('').toPromise();

  }

  navigateToOffer(offerId: number) {
    this.router.navigateByUrl(`offers/details/${offerId}`);
  }
}
