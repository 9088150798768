<div class="survey-container rounded poll">
  <div class="survey-header text-center">
    <div *ngIf="survey.ImageURL" class="image-container-polls w-100 overflow-hidden position-relative bg-white rounded-top">
      <img class="h-100 w-100" src="{{ survey.ImageURL | contentImage : '7' }}" alt="{{ survey.ImageURL }}" />
    </div>
    <div class="text-white text-center mb-3 pt-3">
      <h3 class="mb-lg-3">{{ 'poll.title' | translate }}</h3>
      <ng-container *notInPopulation="[populations.Kids, populations.Partial, populations.Default]">
        <div class="text-capitalize font-size-14">{{ 'poll.description' | translate }}</div>
      </ng-container>
      <ng-container *inPopulation="[populations.Kids, populations.Partial, populations.Default]">
        <div class="text-capitalize font-size-14">
          {{ 'poll.no.points.description' | translate : { fgbdefault: 'Pick Your Answer' } }}
        </div>
      </ng-container>
    </div>
    <div class="survey-info">
      <div class="d-flex align-items-center w-100 position-relative">
        <div class="d-flex align-items-center question-container">
          <div class="gIcon-polls material-icons inline-block my-0 text-white">assessment</div>
        </div>
        <div class="px-3 d-none d-lg-flex text-white font-weight-bold survey-question">
          <p>
            {{ survey.SurveyQuestion }}
          </p>
        </div>

        <ng-container *notInPopulation="[populations.Partial, populations.Default, populations.Kids]">
          <div
            class="text-center font-weight-bold text-white font-size-16 points"
            [ngClass]="pointsWon > 0 ? 'd-block d-lg-none' : 'd-none'"
          >
            +{{ pointsWon }} {{ 'poll.points' | translate }}
          </div>
        </ng-container>
        <div class="text-right text-white countdown-container" [ngClass]="{ countdown: survey.HasBeenAnsweredByUser }">
          <ng-container *ngIf="status !== displayStatus.ComingSoon">
            <span class="icon icon-clock pr-1"></span>
            <fgbcl-countdown
              [endDateTime]="survey.EndDate"
              [isUTC]="true"
              class="font-size-16 font-weight-bold"
            ></fgbcl-countdown>
          </ng-container>
        </div>
      </div>
      <div class="survey-question px-3 d-flex d-lg-none mt-3 text-white font-size-14 font-weight-bold">
        {{ survey.SurveyQuestion }}
      </div>
    </div>
  </div>

  <ng-container *ngIf="status != displayStatus.ComingSoon; else comingSoon">
    <ng-container *ngIf="survey.HasBeenAnsweredByUser; else answer">
      <fgb-survey-item-answer-completed-poll [survey]="survey" [pointsWon]="pointsWon"></fgb-survey-item-answer-completed-poll>
    </ng-container>
    <ng-template #answer>
      <fgb-survey-item-answer [survey]="survey"></fgb-survey-item-answer>
    </ng-template>
  </ng-container>
</div>

<ng-template #comingSoon>
  <div class="coming-soon d-flex align-items-center justify-content-center">
    <p class="text-center font-weight-bold text-primary m-0">
      {{ 'survey.item.quiz.coming.soon.text' | translate : { fgbdefault: 'Coming soon' } }}
    </p>
  </div>
</ng-template>
