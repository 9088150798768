<div class="d-flex banner" role="banner">
</div>

<fgb-account-nav></fgb-account-nav>

<div class="container mt-4">
  <div class="points-history">
    <fgb-transaction-history [transactionTypes]="[transactionTypes.loyalty]" [accountView]="true"></fgb-transaction-history>
  </div>
</div>
