<div class="container d-lg-none d-block">
  <div class="mt-4">
    <fgbcl-barcode [showCashBackToggle]="true"></fgbcl-barcode>
  </div>
</div>

<div class="d-block d-lg-none">
  <ul class="nav nav-secondary account-details-nav">
    <a class="nav-item" [routerLink]="['/payment']" [routerLinkActive]="'active'" [routerLinkActiveOptions]="{ exact: true }">
      <span class="icon material-icons material-mobile-nav" aria-hidden="true">credit_card</span>
      <label class="pl-1">{{ 'nav.ecash' | translate }}</label>
    </a>
    <a
      class="nav-item"
      [routerLink]="['/ecashhistory']"
      [routerLinkActive]="'active'"
      [routerLinkActiveOptions]="{ exact: true }"
    >
      <span class="icon material-icons material-mobile-nav" aria-hidden="true">history</span>
      <label class="pl-1">{{ 'nav.ecash.history' | translate }}</label>
    </a>
  </ul>
</div>

<div class="container mt-4">
  <fgb-transaction-history
    [transactionTypes]="[transactionTypes.ecash, transactionTypes.payment]"
    [ecashView]="true"
  ></fgb-transaction-history>
</div>
