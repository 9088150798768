<div class="banner" role="banner">
  <h1>Arena Cash</h1>
</div>

<!-- <fgb-arena-cash-nav></fgb-arena-cash-nav> -->
<div class="container">
  <div class="row">
    <div class="col-12">
      <div
        class="transfer-info-box d-flex justify-content-center align-items-center bg-white text-primary text-center font-weight-bold rounded shadow p-2"
      >
        To transfer funds, enter the recipients email address and desired amount below.
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-lg-4 col-12">
      <div class="current-balance-box bg-primary rounded shadow">
        <div class="current-balance">
          <span class="icon icon-money-bag-coins text-white">
            <span class="current-balance-text font-size-16 text-white position-relative">Current Balance - </span></span
          >
          <span class="ecash-text font-size-16 position-relative text-success">{{
            combinedCard$ | async | FortressCurrencyPipe: 'USD'
          }}</span>
        </div>
        <div class="account-no">
          <span class="text-white font-size-12 font-weight-bold"
            >Account No.: {{ (memberDetails$ | async)?.ExternalRefNumber }}</span
          >
        </div>
      </div>
    </div>
    <div class="col-lg-8 col-12">
      <div class="arena-cash-box bg-white rounded shadow">
        <fgb-transfer-arena-cash></fgb-transfer-arena-cash>
      </div>
    </div>
  </div>
</div>
