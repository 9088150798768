<!--Barcode-->
<div class="d-flex d-lg-none">
  <div class="container barcode-container my-4">
    <fgb-barcode></fgb-barcode>
  </div>
</div>

<!--Vouchers-->
<ng-container *inPopulation="[populations.Default]">
  <div class="d-flex d-lg-none">
    <div class="container my-4">
      <fgb-vouchers></fgb-vouchers>
    </div>
  </div>
</ng-container>

<div class="mt-lg-0 mt-2 game-nav-wrapper">
  <fgb-game-nav></fgb-game-nav>
</div>

<div class="container mt-4">
  <ng-container *ngIf="predictors$ | async as predictors">
    <div *ngIf="!predictors.length">
      <div class="no-items w-100 text-center">
        <div class="icon material-icons-two-tone no-results-placeholder-icon text-center" aria-hidden="true">flaky</div>
        <div class="no-results-placeholder-title font-weight-bold text-primary text-uppercase">
          {{ 'predictor.placeholder.title' | translate }}
        </div>
        <div class="no-results-placeholder-text text-info mb-5">{{ 'predictor.placeholder.description' | translate }}</div>
      </div>
    </div>
    <div *ngFor="let predictor of predictors">
      <fgb-survey-item [survey]="predictor"></fgb-survey-item>
    </div>
  </ng-container>
</div>
