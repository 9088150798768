<div role="contentinfo" class="footer text-white text-center">
  <div class="footer-quicklinks d-block w-100">
    <div class="footer-container links font-weight-bold font-size-14 d-none d-lg-flex">
      <a class="text-white" [routerLink]="['/terms']">{{ 'nav.terms' | translate }}</a>
      <ng-container *notInPopulation="[ populations.Kids ]">
        <a class="text-white" [routerLink]="['/contact']">{{ 'nav.contact.us' | translate }}</a>
      </ng-container>
      <a class="text-white" [routerLink]="['/faq']">{{ 'nav.faq' | translate }}</a>
    </div>
  </div>
  <div class="footer-text bg-black d-flex align-items-center justify-content-center w-100">
    <div class="footer-content mt-lg-2 mt-0 d-flex">
      <ng-container *notInPopulation="[ populations.Default ]">
        <img class="footer-logo mb-lg-2 mb-3" src="assets/images/footer/footer-logo-2.svg" draggable="false" />
      </ng-container>
      <ng-container *inPopulation="[ populations.Default ]">
        <img class="footer-logo mb-lg-2 mb-3" src="assets/images/footer/footer-logo-1.svg" draggable="false" />
      </ng-container>
      <div
        class="text-white font-size-12 pl-3 text-center footer-rights"
        [innerHTML]="'nav.club.rights' | translate | markdownTranslate"
      ></div>
    </div>
  </div>
</div>
