import { Component, OnInit, Input } from '@angular/core';
import { LoggingService, MobilePass, MobilePassQuery, MobilePassService } from '@fgb/core';
import { DeviceDetectorService } from 'ngx-device-detector';
import { MobilePassModalComponent } from '../mobile-pass-modal/mobile-pass-modal.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Observable } from 'rxjs';
import { Populations } from 'src/app/shared/utilities/populations';
import { ScreenType } from '@fgb/portal-component-library/src/lib/directives';

@Component({
  selector: 'fgb-mobile-pass',
  templateUrl: './mobile-pass.component.html',
  styleUrls: ['./mobile-pass.component.scss'],
})
export class MobilePassComponent implements OnInit {
  @Input() memberId: string;
  @Input() separateTop = false;
  mobilePassUrl$: Observable<MobilePass | undefined>;
  populations = Populations;
  screenType = ScreenType;

  constructor(
    public deviceDetector: DeviceDetectorService,
    private loggingService: LoggingService,
    private modalService: NgbModal,
    private mobilePassService: MobilePassService,
    private mobilePassQuery: MobilePassQuery
  ) {}

  ngOnInit() {
    this.mobilePassService.fetchPass(this.memberId).toPromise();
    this.mobilePassUrl$ = this.mobilePassQuery.selectFirst();
  }

  downloadPassDesktop(url: string) {
    if (!this.deviceDetector.isMobile()) {
      // Produce a QR code for the mobile link so a user is redirected to the mobile link
      // when scanning on their phone.
      this.loggingService.info('Desktop detected, formatting QR code.');
      const modal = this.modalService.open(MobilePassModalComponent);
      modal.componentInstance.url = url;
    }
  }
}
