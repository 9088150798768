<nav id="navbar" class="navbar navbar-dark navbar-expand-lg d-none d-lg-flex">
  <a class="navbar-brand py-0" [routerLink]="['/']" routerLinkActive="router-link-active">
    <label id="homeLink" class="accessibility-only">Go To Home Page</label>
    <div id="brand-logo"></div>
  </a>

  <button
    class="navbar-toggler"
    type="button"
    (click)="isCollapsed = !isCollapsed"
    aria-controls="navbarSupportedContent"
    aria-expanded="false"
    attr.aria-label="{{ 'nav.toggle.screenreader' | translate }}"
  >
    <span class="navbar-toggler-icon"></span>
  </button>

  <!-- Left Side Content -->
  <div class="navbar-collapse" [ngbCollapse]="isCollapsed" id="navbarSupportedContent">
    <div class="nav-container">
      <ul class="navbar-nav mr-auto" #greedyNav>
        <ng-container *inPopulation="[populations.Orange, populations.Partner, populations.Kids ]">
          <li class="nav-item active">
            <a class="nav-link mx-1" [routerLink]="['/rewards/marketplace']" routerLinkActive="router-link-active">
              <ng-container *notInPopulation="[ populations.Kids ]">
                {{
                  'nav.rewards' | translate
                }}
              </ng-container>
              <ng-container *inPopulation="[ populations.Kids ]">
                {{
                  'nav.kids.rewards' | translate : { fgbdefault: 'Rewards' }
                }}
              </ng-container>
            </a>
          </li>
          <li class="nav-item active">
            <a class="nav-link mx-1" [routerLink]="['/badges']" routerLinkActive="router-link-active">{{
              'nav.challenges' | translate
            }}</a>
          </li>
        </ng-container>
        <ng-container *inPopulation="[populations.Partial]">
          <li class="nav-item active">
            <a class="nav-link mx-1" [routerLink]="['/rewards/wallet']" routerLinkActive="router-link-active">{{
              'nav.wallet' | translate
            }}</a>
          </li>
        </ng-container>

        <ng-container *inPopulation="[ populations.Default ]">
            <li class="nav-item active">
              <a class="nav-link mx-1" [routerLink]="['/badges']" routerLinkActive="router-link-active">{{
                'nav.challenges' | translate
              }}</a>
            </li>
        </ng-container>

        <ng-container *inPopulation="[ populations.Kids ]">
          <li class="nav-item active">
            <a class="nav-link mx-1" [routerLink]="['/worksheet']" routerLinkActive="router-link-active">{{
              'nav.worksheets' | translate : { fgbdefault: 'Activities' }
            }}</a>
          </li>
          <li class="nav-item active">
            <a class="nav-link mx-1" [routerLink]="['/gallery']" routerLinkActive="router-link-active">{{
              'nav.gallery' | translate : { fgbdefault: 'Gallery' }
            }}</a>
          </li>
        </ng-container>
        
        <ng-container *fgbWebsiteContentFeatureFlag="'feature.flag.nav.wild.wingers'">
          <ng-container *notInPopulation="[ populations.Kids ]">
            <li class="nav-item active">
              <a class="nav-link mx-1" [routerLink]="['/guardians']" routerLinkActive="router-link-active">{{
                  'nav.wild.wingers' | translate : { fgbdefault: 'Wild Wingers' }
              }}</a>
            </li>
          </ng-container>
        </ng-container>
      </ul>
    </div>
    <!-- Right Side Content -->
    <div class="form-inline my-2 my-lg-0">
      <ng-container *inPopulation="[ populations.Orange, populations.Partner ]">
        <div class="balance font-size-14 text-white border-right">
          <div class="d-flex align-items-center" *ngIf="loyaltyPointsCard$">
            <span
              class="icon material-icons currency-icons pr-1"
              role="img"
              attr.aria-label="{{ 'nav.points.screenreader' | translate }}"
              >emoji_events</span
            >
            <span class="font-weight-bold">{{ loyaltyPointsCard$ | async | FortressCurrencyPipe : 'PTS' }}</span>
          </div>
        </div>
        <div class="balance font-size-14 text-white pl-0">
          <div class="d-flex align-items-center" *ngIf="combinedCard$">
            <span
              class="icon material-icons currency-icons"
              role="img"
              attr.aria-label="{{ 'nav.ecash.screenreader' | translate }}"
              >attach_money</span
            >
            <span class="font-weight-bold">{{ combinedCard$ | async | number : '0.2-2' }}</span>
          </div>
        </div>
      </ng-container>
      <!--New Dropdown (Needs Manage Tickets update before being looked at again)-->
      <div class="member" ngbDropdown #ddAccount="ngbDropdown" [autoClose]="'outside'" focusTab>
        <button
          class="nav-link btn text-white d-flex align-items-center mx-2 px-0 justify-content-end"
          id="namedropdown"
          ngbDropdownToggle
          attr.aria-label="{{ 'nav.account.dropdown.screenreader' | translate }}"
          tabindex="-1"
        >
          <div *ngIf="memberDetails$ | async as memberDetails; else memberDetailsLoading" class="user-box h-100 d-inline-block">
            <div class="font-size-14 text-left">{{ memberDetails!.FirstName }}</div>
          </div>
        </button>

        <div
          ngbDropdownMenu
          (click)="closeDropDown(ddAccount)"
          aria-labelledby="namedropdown"
          id="account-dropdown"
          class="shadow text-center dropdown-menu p-0 account-dropdown mt-2"
        >
          <div class="text-info">
            <ng-container *notInPopulation="[ populations.Kids ]">
              <a
                [routerLink]="['/account']"
                routerLinkActive="router-link-active"
                class="d-flex align-items-center account-btn text-black py-0 px-2 font-size-14 nav-item-fixed-height rounded-top"
                ngbDropdownItem>
                <span class="icon material-icons material-dropdown-icon mr-2" aria-hidden="true">person</span>
                <div class="dropdown-link text-left">
                  <div class="mt-2">{{ 'nav.dropdown.account' | translate : { fgbdefault: 'Account' } }}</div>
                  <div class="nav-bar-little-text text-left" *ngIf="card$ | async as memberCard">
                    <div class="nav-bar-little-text" *inPopulation="[ populations.Orange ]">
                      {{ 'nav.dropdown.orange' | translate : { fgbdefault: 'Season' } }}
                    </div>
                    <div class="nav-bar-little-text" *inPopulation="[ populations.Default ]">
                      {{ 'nav.dropdown.fan' | translate : { fgbdefault: 'Duck Fan' } }}
                    </div>
                    <div class="nav-bar-little-text" *inPopulation="[ populations.Partial ]">
                      {{ 'nav.dropdown.partial' | translate : { fgbdefault: 'Plan Holder' } }}
                    </div>
                    <div class="nav-bar-little-text" *inPopulation="[ populations.Partner ]">
                      {{ 'nav.dropdown.partner' | translate : { fgbdefault: 'Partner' } }}
                    </div>
                  </div>
                </div>
              </a>
            </ng-container>
            <ng-container *inPopulation="[ populations.Kids ]">
              <a
                class="d-flex align-items-center account-btn text-black py-0 px-2 font-size-14 nav-item-fixed-height rounded-top"
                ngbDropdownItem>
              <span class="icon material-icons material-dropdown-icon mr-2" aria-hidden="true">person</span>
              <div class="dropdown-link text-left">
                <div class="mt-2">{{ 'nav.dropdown.account' | translate : { fgbdefault: 'Account' } }}</div>
                <div class="nav-bar-little-text text-left" *ngIf="card$ | async as memberCard">
                  <div class="nav-bar-little-text">
                    {{ 'nav.dropdown.kids' | translate : { fgbdefault: 'Wild Wingers' } }}
                  </div>
                </div>
              </div>
            </a>
            </ng-container>
          </div>
          <div>
            <hr class="text-muted w-90 my-0" />
          </div>
          <ng-container *notInPopulation="[ populations.Kids ]">
            <div class="text-primary">
              <a
                href="https://am.ticketmaster.com/ducks/"
                target="_blank"
                class="d-flex align-items-center py-0 px-2 nav-item-fixed-height ticketing"
                ngbDropdownItem
              >
                <span class="icon material-icons material-dropdown-icon mr-2">confirmation_number</span>
                <div class="dropdown-link text-left">
                  <div class="font-size-14">{{ 'nav.ticketing' | translate }}</div>
                </div>
              </a>
            </div>
          </ng-container>
          <div>
            <hr class="text-muted w-90 my-0" />
          </div>
          <ng-container *inPopulation="[ populations.Orange, populations.Partner, populations.Kids ]">
            <div class="text-black">
              <a
                [routerLink]="['/rewards/wallet']"
                routerLinkActive="router-link-active"
                class="d-flex align-items-center text-black py-0 px-2 nav-item-fixed-height"
                ngbDropdownItem
              >
                <span class="icon material-icons material-dropdown-icon mr-2" aria-hidden="true">account_balance_wallet</span>
                <div class="dropdown-link text-left">
                  <div class="font-size-14">{{ 'nav.wallet' | translate }}</div>
                </div>
              </a>
            </div>
            <div>
              <hr class="text-muted w-90 my-0" />
            </div>
          </ng-container>
          <div>
            <ng-container *notInPopulation="[ populations.Kids ]">
              <button
                class="logout-btn d-flex align-items-center px-2 nav-item-fixed-height rounded-bottom"
                (click)="logout()"
                ngbDropdownItem
                attr.aria-label="{{ 'nav.log.out.screenreader' | translate }}">
                <span class="icon material-icons material-dropdown-icon mr-2 text-danger" aria-hidden="true"
                  >power_settings_new</span
                >
                <div class="dropdown-link text-left">
                  <div class="text-danger logout-btn py-1 font-size-14 font-weight-bold" ngbDropdownItem (click)="logout()">
                    {{ 'nav.log.out' | translate }}
                  </div>
                </div>
              </button>
            </ng-container>
            <ng-container *inPopulation="[ populations.Kids ]">
              <button
                class="back-to-guardian-btn d-flex align-items-center px-2 nav-item-fixed-height rounded-bottom mb-2"
                (click)="switchToGuardian()"
                ngbDropdownItem
                attr.aria-label="{{ 'nav.back.to.guardian.screenreader' | translate }}">
                <i class="icon material-icons material-dropdown-icon mr-2 text-primary" aria-hidden="true"
                  >logout</i>
                <div class="dropdown-link text-left">
                  <div class="text-primary logout-btn py-1 font-size-14">
                    {{ 'nav.back.to.guardian' | translate : { fgbdefault: 'Back to Guardian' } }}
                  </div>
                </div>
              </button>
            </ng-container>
          </div>
        </div>
      </div>
    </div>
  </div>
</nav>

<ng-template #memberDetailsLoading>
  <div class="user-box d-block text-center text-on-primary px-4">
    <fgbcl-loading [size]="'small'"></fgbcl-loading>
  </div>
</ng-template>
