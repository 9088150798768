<div class="banner d-flex">
  <div class="banner-logo"></div>
</div>
  <ng-container *inPopulation="[populations.Orange, populations.Partner]">
    <div class="d-none" *ifScreenSize="screenType.Desktop">
      <fgb-mixed-loyalty-nav></fgb-mixed-loyalty-nav>
    </div>
  </ng-container>
  
  <ng-container *inPopulation="[ populations.Kids ]">
    <fgb-mixed-loyalty-nav [showWalletButton]="false"></fgb-mixed-loyalty-nav>
  </ng-container>

<ng-container *inPopulation="[ populations.Orange, populations.Partial, populations.Partner ]">
  <div class="d-block" *ifScreenSize="screenType.Mobile">
    <div class="container pt-4">
      <fgb-barcode></fgb-barcode>
    </div>
    <!-- <ul class="nav nav-secondary payment-nav">
      <ng-container *fgbWebsiteContentFeatureFlag="'feature.flag.nav.payment'">
        <a
          class="nav-item primary-theme py-2"
          [routerLink]="['/payment']"
          [routerLinkActive]="'active'"
          [routerLinkActiveOptions]="{ exact: true }"
        >
          <span class="icon material-icons material-mobile-nav" aria-hidden="true">credit_card</span>
          <label>{{ 'navbar.payment' | translate }}</label>
        </a>
      </ng-container>
      <a
        class="nav-item primary-theme py-2"
        [routerLink]="['/rewards/wallet']"
        [routerLinkActive]="'active'"
        [routerLinkActiveOptions]="{ exact: true }"
      >
        <span class="icon material-icons material-mobile-nav" aria-hidden="true">account_balance_wallet</span>
        <label>{{ 'navbar.wallet' | translate }}</label>
      </a>
    </ul> -->
  </div>
</ng-container>

<ng-container *inPopulation="[populations.Orange, populations.Partial, populations.Partner]">
  <!-- Campaign Offers -->
  <fgb-campaigns-offers-list></fgb-campaigns-offers-list>
</ng-container>

<ng-container *inPopulation="[ populations.Orange, populations.Partner, populations.Kids, populations.Partial ]">
  <!-- Rewards -->
  <ng-container
    *ngIf="
      {
        walletItemsUnclaimed: walletItemsUnclaimed$ | async
      } as walletItems;
      else noWalletItems
    "
  >
    <div class="wallet-redeem-carousel pt-4">
      <div class="container">
        <ng-container *ngIf="walletItems.walletItemsUnclaimed && walletItems.walletItemsUnclaimed.length; else noWalletItems">
          <fgbcl-rewards-wallet-redeem-list
            [unRedeemedItems]="walletItems.walletItemsUnclaimed"
          ></fgbcl-rewards-wallet-redeem-list>
        </ng-container>
      </div>
    </div>
  </ng-container>

  <ng-template #noWalletItems>
    <div class="container">
      <h5 class="no-wallet-items mt-5 mb-3 font-weight-bold">{{ 'rewards.wallet.no.items' | translate }}</h5>
      <div class="border">
        <div class="no-wallet-items-description font-weight-bold font-size-12 text-center text-grey py-3">
          {{ 'rewards.wallet.no.items.description' | translate }}
        </div>
      </div>
    </div>
  </ng-template>

  <div class="container mt-3 text-center">
    <a class="font-size-14 claimed-text font-weight-bold" [routerLink]="['/rewards/claimed']">
      {{ 'rewards.wallet.view.claimed' | translate }}
    </a>
  </div>
</ng-container>
