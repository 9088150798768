<div class="full-page registration-payment-layout">
  <div class="registration-panel registration-payment bg-white">
    <div class="container">
      <div class="row">
        <div class="col-lg-12 payment-panel">

          <!-- PAYMENT SUMMARY -->
            <div *ngIf="basket$ | async as basket; else loading" class="p-lg-4 p-1">
              <ng-container *ngIf="basket">
                <div class="d-flex justify-content-center align-items-center mb-4 d-lg-none">
                  <img alt="" src="assets/images/logo-small.svg" class="payment-logo" />
                </div>
                <h2 class="mt-0 registration-payment-title">{{ 'fgbcl.registration.payment.review.purchase.title' | translate : { fgbdefault: 'Review Purchases' } }}</h2>
                <p class="registration-payment-instruction">{{ 'fgbcl.registration.payment.instruction' | translate : { fgbdefault: 'Please review your memberships below and proceed to payment screen.'} }}</p>

                <div class="row">
                  <!-- Left Side -->
                  <div class="col-lg-7 registration-payment-basket">
                        <div class="registration-basket-item" *ngFor="let basketItem of basket.BasketItems">
                          <!-- TODO: No xd for image -->
                          <!-- <img *ngIf="basketItem.ImageURL" alt="" class="membership-image mr-2" [src]="basketItem.ImageURL" /> -->
                          <span class="registration-basket-item-desc"><span class="basket-label">{{ basketItem.DisplayText }}</span> <span class="basket-item"> - {{ basketItem.MembershipOption.DisplayName }}</span></span>
                          <span class="registration-basket-item-cost"><span class="basket-label">{{ 'fgbcl.registration.payment.cost.label' | translate : { fgbdefault : 'Cost' } }}</span> <span class="price ml-2">{{ basketItem.Price / 100 | FortressCurrencyPipe }}</span></span>
                  
                        </div>
                        <button class="back-btn d-none d-lg-flex" (click)="back()"><i class="material-icons">chevron_left</i>{{ 'fgbcl.registration.payment.back.button' | translate : { fgbdefault : 'Back' }  }}</button>
                  </div>

                  <!-- Right Side -->
                  <div [ngClass]="{ 'd-none': editingBillingAddress || editingShippingAddress }" class="col-lg-5 ">
                 
                    <!-- Billing Address Card -->
                    <ng-container *ngTemplateOutlet="addressTemplate; 
                                  context: { 
                                    $implicit: billingAddressForm?.value, 
                                    title: 'fgbcl.registration.payment.billing.address.title' | translate : { fgbdefault: 'Billing Address' },
                                    type: 'billing'
                                  }">
                    </ng-container>

                    <!-- Shipping Address Card -->
                    <ng-container *ngTemplateOutlet="addressTemplate; 
                                  context: { 
                                    $implicit: shippingAddressForm?.value, 
                                    title: 'fgbcl.registration.payment.shipping.address.title' | translate : { fgbdefault: 'Shipping Address' },
                                    instruction: 'fgbcl.registration.payment.shipping.address.instruction' | translate : { fgbdefault: '*We are not able to accept P.O. Boxes please ensure your shipping address is a valid street address.' },
                                    type: 'shipping'
                                  }">
                    </ng-container>

                    <!-- Server Side Errors -->
                    <fgbcl-errors></fgbcl-errors>

                    <!-- Giftcode input -->
                    <div class="input-group rounded mb-lg-2 mb-3 mt-lg-4">
                      <input
                        type="text"
                        class="form-control bg-muted gift-code-input"
                        [(ngModel)]="giftCodeInput"
                        (blur)="detectGiftCodeInput(giftCodeInput)"
                      />
                      <div class="input-group-append">
                        <button
                          class="btn btn-success gift-code-btn text-white form-control"
                          type="button"
                          [disabled]="!giftCodeInput"
                          (click)="applyGiftCode()"
                          (blur)="clearErrorMessage()"
                        >
                          {{ 'fgbcl.registration.payment.apply.giftcode.button' | translate : { fgbdefault: 'Apply Gift Code' } }}
                        </button>
                      </div>
                    </div>

                    <!-- Summary -->
                    <div class="card summary-card">
                        <h5 class="summary-title">{{ 'fgbcl.registration.payment.summary.title' | translate : { fgbdefault: 'Summary' } }}</h5>
                        <div class="summary-item">
                          <span class="summary-label">{{ 'fgbcl.registration.payment.summary.membership.total.label' | translate : { fgbdefault: 'Membership total:' } }}</span>
                          <span class="summary-value">{{ basket.TotalPrice / 100 | FortressCurrencyPipe }}</span>
                        </div>
                        <div class="summary-item">
                          <span class="summary-label">{{ 'fgbcl.registration.payment.summary.shipping.label' | translate : { fgbdefault: 'Shipping:' } }}</span>
                          <span class="summary-value">{{ basket.TotalShipping / 100 | FortressCurrencyPipe }}</span>
                        </div>
                        <div class="summary-item" *ngIf="basket?.TotalTax > 0">
                          <span class="summary-label">{{ 'fgbcl.registration.payment.summary.tax.label' | translate : { fgbdefault: 'Tax:' } }}</span>
                          <span class="summary-value">{{ basket.TotalTax / 100 | FortressCurrencyPipe }}</span>
                        </div>
                        <!-- TODO: No XD for gift codes -->
                        <div class="summary-total" *ngIf="appliedGiftCodes && appliedGiftCodes.length > 0">
                          <span class="summary-label">{{ 'fgbcl.registration.payment.summary.discount.label' | translate : { fgbdefault: 'Discount Code(s):' } }}</span>
                          <div class="gift-code-wrapper d-flex flex-column">
                            <span class="summary-value" *ngFor="let giftCode of appliedGiftCodes">
                              <div class="heading-text d-flex align-items-center">
                                <button id="remove-giftcode" (click)="removeGiftCode(giftCode.Code)">
                                  <label class="accessibility-only">{{
                                    'registration.remove.giftcode.screenreader' | translate
                                  }}</label>
                                  <i aria-hidden="true" class="material-icons">cancel</i>
                                </button>
                                {{ giftCode.Code }}
                              </div>
                              <div class="gift-code-apply">
                                <span *ngIf="giftCode.EntitlementValueType == 0"
                                  >- {{ giftCode.EntitlementValue / 100 | FortressCurrencyPipe }}</span
                                >
                                <span *ngIf="giftCode.EntitlementValueType == 1">- {{ giftCode.EntitlementValue }}%</span>
                                <span *ngIf="giftCode.EntitlementValueType == 2">FREE</span>
                              </div>
                            </span>
                          </div>
                        </div>
                        <hr>
                        <div class="summary-total">
                          <span class="summary-label">{{ 'fgbcl.registration.payment.summary.total.label' | translate : { fgbdefault: 'Total (Incl. Shipping):' } }}</span>
                          <span class="summary-value">{{ totalBasketPrice / 100 | FortressCurrencyPipe }}</span>
                        </div>
                    </div>
                    <!-- <div class="rounded bg-muted p-2 my-2">
                      <h6 class="mb-3">Summary</h6>
                      <div class="payment-breakdown-container">
                        <div class="row text-info font-size-12">
                          <div class="col-9">Items:</div>
                          <div class="col-3 text-right">{{ basket.TotalPrice / 100 | FortressCurrencyPipe }}</div>
                        </div>
                        <div class="row text-info font-size-12">
                          <div class="col-9">Shipping:</div>
                          <div class="col-3 text-right">{{ basket.TotalShipping / 100 | FortressCurrencyPipe }}</div>
                        </div>
                        <div class="row text-info font-size-12">
                          <div class="col-9">Tax:</div>
                          <div class="col-3 text-right">{{ basket.TotalTax / 100 | FortressCurrencyPipe }}</div>
                        </div>
                        <div class="row font-size-12" *ngIf="appliedGiftCodes && appliedGiftCodes.length > 0">
                          <div class="col-12">Discount Code(s):</div>
                          <ng-container *ngFor="let giftCode of appliedGiftCodes">
                            <div class="col-9 heading-text">
                              <button (click)="removeGiftCode(giftCode.Code)">
                                <label class="accessibility-only">{{
                                  'registration.remove.giftcode.screenreader' | translate
                                }}</label>
                                <span aria-hidden="true">X</span>
                              </button>
                              {{ giftCode.Code }}
                            </div>
                            <div class="col-3 text-right">
                              <span *ngIf="giftCode.EntitlementValueType == 0"
                                >- {{ giftCode.EntitlementValue / 100 | FortressCurrencyPipe }}</span
                              >
                              <span *ngIf="giftCode.EntitlementValueType == 1">- {{ giftCode.EntitlementValue }}%</span>
                              <span *ngIf="giftCode.EntitlementValueType == 2">FREE</span>
                            </div>
                          </ng-container>
                        </div>
                      </div>
                      <div class="row font-weight-bold font-size-14 mt-2">
                        <div class="col-9">Total (Incl. Shipping):</div>
                        <div class="col-3 text-info text-right">{{ totalBasketPrice / 100 | FortressCurrencyPipe }}</div>
                      </div>
                    </div> -->
                    <div class="action-wrapper">
                      <button class="btn btn-danger d-flex d-lg-none" (click)="back()">{{ 'fgbcl.registration.payment.back.button' | translate : { fgbdefault : 'Back' } }}</button>
                      <button class="btn btn-success payment-btn" (click)="submitStep()">
                        <span class="d-lg-flex d-none">
                          {{ 'fgbcl.registration.payment.button' | translate : { fgbdefault: 'Proceed to Payment' } }}
                        </span>
                        <span class="d-lg-none d-flex">
                          {{ 'fgbcl.registration.payment.mobile.button' | translate : { fgbdefault: 'Payment' } }}
                        </span>
                      </button>  
                    </div>
                  </div>

                  <!-- EDIT BILLING ADDRESS FORM -->
                  <ng-container *ngIf="editingBillingAddress">
                    <div class="col-lg-5">
                      <div class="position-relative rounded bg-muted registration-payment-billing-form">
                        <div class="form-header">
                          <h6 class="form-header-title">{{ 'fgbcl.registration.payment.billing.form.title' | translate : { fgbdefault: 'Billing Address' } }}</h6>
                          <a class="clear-btn" (click)="clearAddressForm(billingAddressForm)">{{ 'fgbcl.registration.payment.form.clear.button' | translate : { fgbdefault: 'Clear Form' } }}</a>
                        </div>
                        <form [formGroup]="billingAddressForm">
                          <div class="form-row">
                            <div class="form-group col-12">
                              <label for="FirstName">{{ 'fgbcl.registration.payment.form.firstname.label' | translate : { fgbdefault: 'First Name' } }}<span class="text-danger">*</span></label>
                              <input
                                id="FirstName"
                                class="form-control"
                                type="text"
                                formControlName="FirstName"
                              />
                              <div class="error-message" *ngIf="billingAddressForm.get('FirstName')?.hasError('required') && billingAddressForm.get('FirstName')?.touched">
                                {{ 'fgbcl.registration.payment.form.firstname.error.required' | translate : { fgbdefault: 'Please enter your first name.' } }}
                              </div>
                              <div class="error-message" *ngIf="billingAddressForm.get('FirstName')?.hasError('empty') && billingAddressForm.get('FirstName')?.touched">
                                {{ 'fgbcl.registration.payment.form.firstname.error.empty' | translate : { fgbdefault: 'First Name cannot be empty.' } }}
                              </div>
                            </div>

                            <div class="form-group col-12">
                              <label for="Surname">{{ 'fgbcl.registration.payment.form.lastname.label' | translate : { fgbdefault: 'Surname' } }}<span class="text-danger">*</span></label>
                              <input
                                id="Surname"
                                class="form-control"
                                type="text"
                                formControlName="Surname"
                              />
                              <div class="error-message" *ngIf="billingAddressForm.get('Surname')?.hasError('required') && billingAddressForm.get('Surname')?.touched">
                                {{ 'fgbcl.registration.payment.form.lastname.error.required' | translate : { fgbdefault: 'Please enter your surname.' } }}
                              </div>
                              <div class="error-message" *ngIf="billingAddressForm.get('Surname')?.hasError('empty') && billingAddressForm.get('Surname')?.touched">
                                {{ 'fgbcl.registration.payment.form.lastname.error.empty' | translate : { fgbdefault: 'Surname cannot be empty.' } }}
                              </div>
                            </div>
                          </div>

                          <div class="form-group">
                            <label for="Street">{{ 'fgbcl.registration.payment.form.street.label' | translate : { fgbdefault: 'Street' } }}<span class="text-danger">*</span></label>
                            <input
                              id="Street"
                              class="form-control"
                              name="Street"
                              type="text"
                              formControlName="Street"
                            />
                            <div class="error-message" *ngIf="billingAddressForm.get('Street')?.hasError('required') && billingAddressForm.get('Street')?.touched">
                              {{ 'fgbcl.registration.payment.form.street.error.required' | translate : { fgbdefault: 'Please enter a value.' } }}
                            </div>
                            <div class="error-message" *ngIf="billingAddressForm.get('Street')?.hasError('empty') && billingAddressForm.get('Street')?.touched">
                              {{ 'fgbcl.registration.payment.form.street.error.empty' | translate : { fgbdefault: 'Street cannot be empty.' } }}
                            </div>
                          </div>

                          <div class="form-group">
                            <label for="Town">{{ 'fgbcl.registration.payment.form.city.label' | translate : { fgbdefault: 'City'} }}<span class="text-danger">*</span></label>
                            <input
                              id="Town"
                              class="form-control"
                              name="Town"
                              type="text"
                              formControlName="Town"
                            />
                            <div class="error-message" *ngIf="billingAddressForm.get('Town')?.hasError('required') && billingAddressForm.get('Town')?.touched">
                              {{ 'fgbcl.registration.payment.form.town.error.required' | translate : { fgbdefault: 'Please enter a value.' } }}
                            </div>
                            <div class="error-message" *ngIf="billingAddressForm.get('Town')?.hasError('empty') && billingAddressForm.get('Town')?.touched">
                              {{ 'fgbcl.registration.payment.form.town.error.empty' | translate : { fgbdefault: 'Town cannot be empty.' } }}
                            </div>
                          </div>

                          <div class="form-group">
                            <label for="County">{{ 'fgbcl.registration.payment.form.state.label' | translate : { fgbdefault: 'State' } }}<span class="text-danger">*</span></label>
                            <input
                              id="County"
                              class="form-control"
                              name="County"
                              type="text"
                              formControlName="County"
                            />
                            <div class="error-message" *ngIf="billingAddressForm.get('County')?.hasError('required') && billingAddressForm.get('County')?.touched">
                              {{ 'fgbcl.registration.payment.form.county.error.required' | translate : { fgbdefault: 'Please enter a value.' } }}
                            </div>
                            <div class="error-message" *ngIf="billingAddressForm.get('County')?.hasError('empty') && billingAddressForm.get('County')?.touched">
                              {{ 'fgbcl.registration.payment.form.county.error.empty' | translate : { fgbdefault: 'County cannot be empty.' } }}
                            </div>
                          </div>

                          <div class="form-group">
                            <label for="Postcode">{{ 'fgbcl.registration.payment.form.postcode.label' | translate : { fgbdefault : 'Post Code' } }}<span class="text-danger">*</span></label>
                            <input
                              id="Postcode"
                              class="form-control"
                              name="Postcode"
                              type="text"
                              formControlName="Postcode"
                            />
                            <div class="error-message" *ngIf="billingAddressForm.get('Postcode')?.hasError('required') && billingAddressForm.get('Postcode')?.touched">
                              {{ 'fgbcl.registration.payment.form.postcode.error.required' | translate : { fgbdefault: 'Please enter a postcode.' } }}
                            </div>
                            <div class="error-message" *ngIf="billingAddressForm.get('Postcode')?.hasError('empty') && billingAddressForm.get('Postcode')?.touched">
                              {{ 'fgbcl.registration.payment.form.postcode.error.empty' | translate : { fgbdefault: 'Postcode cannot be empty.' } }}
                            </div>
                            <div class="error-message" *ngIf="billingAddressForm.get('Postcode')?.hasError('pattern') && billingAddressForm.get('Postcode')?.touched">
                              {{ 'fgbcl.registration.payment.form.postcode.error.pattern' | translate : { fgbdefault: 'Please enter a valid postcode.' } }}
                            </div>
                          </div>

                          <div class="form-group">
                            <label for="Country">{{ 'fgbcl.registration.payment.form.country.label' | translate : { fgbdefault: 'Country'} }}<span class="text-danger">*</span></label>
                            <fgbcl-select-country formControlName="Country"></fgbcl-select-country>
                            <div class="error-message" *ngIf="billingAddressForm.get('Country')?.hasError('required') && billingAddressForm.get('Country')?.touched">
                              {{ 'fgbcl.registration.payment.form.country.error.required' | translate : { fgbdefault: 'Please enter a value.' } }}
                            </div>
                            <div class="error-message" *ngIf="billingAddressForm.get('Country')?.hasError('empty') && billingAddressForm.get('Country')?.touched">
                              {{ 'fgbcl.registration.payment.form.country.error.empty' | translate : { fgbdefault: 'Country cannot be empty.' } }}
                            </div>
                          </div>
                        </form>
                        <div class="d-flex justify-content-between action-wrapper">
                          <button class="btn btn-danger cancel-btn" (click)="cancelBillingForm()">{{ 'fgbcl.registration.payment.form.cancel.button' | translate : { fgbdefault: 'Cancel' } }}</button>
                          <button class="btn btn-success save-btn" (click)="updateBillingAddress()">{{ 'regustration.payment.form.save.button' | translate : { fgbdefault: 'Save' } }}</button>
                        </div>
                      </div>
                    </div>
                  </ng-container>

                  <!-- Shipping Address Form -->
                  <ng-container *ngIf="editingShippingAddress">
                    <div class="col-lg-5">
                      <div class="position-relative rounded bg-muted registration-payment-shipping-form">
                        <div class="form-header">
                          <h6 class="form-header-title">{{ 'fgbcl.registration.payment.shipping.form.title' | translate: { fgbdefault: 'Shipping Address' } }}</h6>
                          <a class="clear-btn" (click)="clearAddressForm(shippingAddressForm)">{{ 'fgbcl.registration.payment.form.clear.button' | translate : { fgbdefault: 'Clear Form' } }}</a>
                        </div>
                        <form [formGroup]="shippingAddressForm">
                          <div class="form-row">
                            <div class="form-group col-12">
                              <label for="FirstName">{{ 'fgbcl.registration.payment.shipping.form.firstname.label' | translate : { fgbdefault: 'First Name' } }}<span class="text-danger">*</span></label>
                              <input
                                id="FirstName"
                                class="form-control"
                                type="text"
                                formControlName="FirstName"
                              />
                              <div class="error-message" *ngIf="shippingAddressForm.get('FirstName')?.hasError('required') && shippingAddressForm.get('FirstName')?.touched">
                                {{ 'fgbcl.registration.payment.form.firstname.error.required' | translate : { fgbdefault: 'Please enter your first name.' } }}
                              </div>
                              <div class="error-message" *ngIf="shippingAddressForm.get('FirstName')?.hasError('empty') && shippingAddressForm.get('FirstName')?.touched">
                                {{ 'fgbcl.registration.payment.form.firstname.error.empty' | translate : { fgbdefault: 'First Name cannot be empty.' } }}
                              </div>
                            </div>

                            <div class="form-group col-12">
                              <label for="Surname">{{ 'fgbcl.registration.payment.form.lastname.label' | translate: { fgbdefault: 'Surname' } }}<span class="text-danger">*</span></label>
                              <input
                                id="Surname"
                                class="form-control"
                                type="text"
                                formControlName="Surname"
                              />
                              <div class="error-message" *ngIf="shippingAddressForm.get('Surname')?.hasError('required') && shippingAddressForm.get('Surname')?.touched">
                                {{ 'fgbcl.registration.payment.form.lastname.error.required' | translate : { fgbdefault: 'Please enter your surname.' } }}
                              </div>
                              <div class="error-message" *ngIf="shippingAddressForm.get('Surname')?.hasError('empty') && shippingAddressForm.get('Surname')?.touched">
                                {{ 'fgbcl.registration.payment.form.lastname.error.empty' | translate : { fgbdefault: 'Surname cannot be empty.' } }}
                              </div>
                            </div>
                          </div>

                          <div class="form-group">
                            <label for="Street">{{ 'fgbcl.registration.payment.form.street.label' | translate : { fgbdefault : 'Street' } }}<span class="text-danger">*</span></label>
                            <input
                              id="Street"
                              class="form-control"
                              name="Street"
                              type="text"
                              formControlName="Street"
                            />
                            <div class="error-message" *ngIf="shippingAddressForm.get('Street')?.hasError('required') && shippingAddressForm.get('Street')?.touched">
                              {{ 'fgbcl.registration.payment.form.street.error.required' | translate : { fgbdefault: 'Please enter a value.' } }}
                            </div>
                            <div class="error-message" *ngIf="shippingAddressForm.get('Street')?.hasError('empty') && shippingAddressForm.get('Street')?.touched">
                              {{ 'fgbcl.registration.payment.form.street.error.empty' | translate : { fgbdefault: 'Street cannot be empty.' } }}
                            </div>
                          </div>

                          <div class="form-group">
                            <label for="Town">{{ 'fgbcl.registration.payment.form.town.label' | translate : { fgbdefault: 'City' } }}<span class="text-danger">*</span></label>
                            <input
                              id="Town"
                              class="form-control"
                              name="Town"
                              type="text"
                              formControlName="Town"
                            />
                            <div class="error-message" *ngIf="shippingAddressForm.get('Town')?.hasError('required') && shippingAddressForm.get('Town')?.touched">
                              {{ 'fgbcl.registration.payment.form.town.error.required' | translate : { fgbdefault: 'Please enter a value.' } }}
                            </div>
                            <div class="error-message" *ngIf="shippingAddressForm.get('Town')?.hasError('empty') && shippingAddressForm.get('Town')?.touched">
                              {{ 'fgbcl.registration.payment.form.town.error.empty' | translate : { fgbdefault: 'City cannot be empty.' } }}
                            </div>
                          </div>

                          <div class="form-group">
                            <label for="County">{{ 'fgbcl.registration.payment.form.county.label' | translate: { fgbdefault : 'State' } }}<span class="text-danger">*</span></label>
                            <input
                              id="County"
                              class="form-control"
                              name="County"
                              type="text"
                              formControlName="County"
                            />
                            <div class="error-message" *ngIf="shippingAddressForm.get('County')?.hasError('required') && shippingAddressForm.get('County')?.touched">
                              {{ 'fgbcl.registration.payment.form.county.error.required' | translate : { fgbdefault: 'Please enter a value.' } }}
                            </div>
                            <div class="error-message" *ngIf="shippingAddressForm.get('County')?.hasError('empty') && shippingAddressForm.get('County')?.touched">
                              {{ 'fgbcl.registration.payment.form.county.error.empty' | translate : { fgbdefault: 'State cannot be empty.' } }}
                            </div>
                          </div>

                          <div class="form-group">
                            <label for="Postcode">{{ 'fgbcl.registration.payment.form.postcode.label' | translate : { fgbdefault: 'Post Code' } }}<span class="text-danger">*</span></label>
                            <input
                              id="Postcode"
                              class="form-control"
                              name="Postcode"
                              type="text"
                              formControlName="Postcode"
                            />
                            <div class="error-message" *ngIf="shippingAddressForm.get('Postcode')?.hasError('required') && shippingAddressForm.get('Postcode')?.touched">
                              {{ 'fgbcl.registration.payment.form.postcode.error.required' | translate : { fgbdefault: 'Please enter a valid zip code.' } }}
                            </div>
                            <div class="error-message" *ngIf="shippingAddressForm.get('Postcode')?.hasError('empty') && shippingAddressForm.get('Postcode')?.touched">
                              {{ 'fgbcl.registration.payment.form.postcode.error.empty' | translate : { fgbdefault: 'Postcode cannot be empty.' } }}
                            </div>
                            <div class="error-message" *ngIf="shippingAddressForm.get('Postcode')?.hasError('pattern') && shippingAddressForm.get('Postcode')?.touched">
                              {{ 'fgbcl.registration.payment.form.postcode.error.pattern' | translate : { fgbdefault: 'Please enter a valid postcode.' } }}
                            </div>
                          </div>

                          <div class="form-group">
                            <label for="Country">{{ 'fgbcl.registration.payment.form.country.label' | translate : { fgbdefault: 'Country' } }}<span class="text-danger">*</span></label>
                            <fgbcl-select-country formControlName="Country"></fgbcl-select-country>
                            <div class="error-message" *ngIf="shippingAddressForm.get('Country')?.hasError('required') && shippingAddressForm.get('Country')?.touched">
                              {{ 'fgbcl.registration.payment.form.country.error.required' | translate : { fgbdefault: 'Please enter a value.' } }}
                            </div>
                            <div class="error-message" *ngIf="shippingAddressForm.get('Country')?.hasError('empty') && shippingAddressForm.get('Country')?.touched">
                              {{ 'fgbcl.registration.payment.form.country.error.empty' | translate : { fgbdefault: 'Country cannot be empty.' } }}
                            </div>
                          </div>
                        </form>
                        <div class="d-flex justify-content-between action-wrapper">
                          <button class="btn btn-danger cancel-btn" (click)="cancelShippingForm()">{{ 'fgbcl.registration.payment.form.cancel.button' | translate : { fgbdefault: 'Cancel' } }}</button>
                          <button class="btn btn-success save-btn" (click)="updateShippingAddress()">{{ 'fgbcl.registration.payment.form.save.button' | translate : { fgbdefault: 'Save' } }}</button>
                        </div>
                      </div>
                    </div>
                  </ng-container>  
                  
                </div>
              </ng-container>
            </div>
  

          <!-- EMBEDDED PAYMENT WINDOW -->
          <!-- <ng-container *ngIf="paymentInitialised">
            <div *ngIf="basket$ | async as basket; else loading" class="rounded bg-muted p-2 my-2">
              <h6 class="mb-4">Summary</h6>
              <div class="row text-info font-size-12">
                <div class="col-9">Items:</div>
                <div class="col-3">{{ basket.TotalPrice / 100 | FortressCurrencyPipe }}</div>
              </div>
              <div class="row text-info font-size-12">
                <div class="col-9">Shipping:</div>
                <div class="col-3">{{ basket.TotalShipping / 100 | FortressCurrencyPipe }}</div>
              </div>
              <div class="row text-info font-size-12">
                <div class="col-9">Tax:</div>
                <div class="col-3">{{ basket.TotalTax / 100 | FortressCurrencyPipe }}</div>
              </div>
              <fgbcl-errors></fgbcl-errors>
              <div class="row font-size-12">
                <div class="col-12">Discount Code(s):</div>
                <ng-container *ngFor="let giftCode of appliedGiftCodes">
                  <div class="col-9">{{ giftCode.Code }}</div>
                  <div class="col-3">
                    <span *ngIf="giftCode.EntitlementValueType == 0"
                      >- {{ giftCode.EntitlementValue / 100 | FortressCurrencyPipe }}</span
                    >
                    <span *ngIf="giftCode.EntitlementValueType == 1">- {{ giftCode.EntitlementValue }}%</span>
                    <span *ngIf="giftCode.EntitlementValueType == 2">FREE</span>
                  </div>
                </ng-container>
              </div>
              <div class="row font-weight-bold font-size-14 mt-2">
                <div class="col-9">Total (Incl. Shipping):</div>
                <div class="col-3 text-info">{{ totalBasketPrice / 100 | FortressCurrencyPipe }}</div>
              </div>
            </div>
          </ng-container> -->

          <!-- <fgbcl-registration-navigation
            *ngIf="!editingBillingAddress && !paymentInitialised"
            (nextButtonClicked)="submitStep()"
            [stepType]="stepType"
          ></fgbcl-registration-navigation> -->
        </div>
      </div>
    </div>
  </div>
</div>

<!-- Address Card -->
<ng-template #addressTemplate let-address let-title="title" let-type="type" let-instruction="instruction">
  <div class="card address-card">
    <h5 class="registration-payment-address-title">{{ title }}</h5>
    <p class="registration-payment-address-instruction" *ngIf="instruction">{{ instruction }}</p>
    <div class="registration-payment-address">
      <div class="address-container">
        <ng-container *ngTemplateOutlet="addressName; context: { $implicit: address.Street }"></ng-container>
        <ng-container *ngTemplateOutlet="addressName; context: { $implicit: address.Town }"></ng-container>
        <ng-container *ngTemplateOutlet="addressName; context: { $implicit: address.County }"></ng-container>
        <ng-container *ngTemplateOutlet="addressName; context: { $implicit: address.Postcode }"></ng-container>
        <ng-container *ngTemplateOutlet="addressName; context: { $implicit: address.Country, lastItem: true }"></ng-container>
      </div>

      <ng-container *ngIf="type === 'billing'">
        <i class="material-icons edit-btn" (click)="editBillingAddress()">edit</i>
      </ng-container>

      <ng-container *ngIf="type === 'shipping'">
        <i class="material-icons edit-btn" (click)="editShippingAddress()">edit</i>
      </ng-container>
    </div>
  </div>

  <!-- Template to display comma if address is not null -->
  <ng-template #addressName let-item let-lastItem="lastItem">
    <ng-container *ngIf="item">{{ item }}<ng-container *ngIf="!lastItem">, </ng-container></ng-container>
  </ng-template>

</ng-template>

<!-- Payment Modal -->
<ng-template #paymentModal>
  <div class="registration-payment-modal">
    <div class="registration-payment-modal-header">
      <i class="material-icons">credit_card</i>
      <h5 class="registration-payment-modal-title">{{ 'fgbcl.registration.payment.modal.title' | translate : { fgbdefault: 'Add Payment Details' } }}</h5>
      <i class="material-icons close-btn" (click)="closeModal()">highlight_off</i>
    </div>
    <div class="registration-payment-modal-info">
      <p class="registration-payment-modal-instruction">
        {{ 'fgbcl.registration.payment.modal.instruction' | translate : { fgbdefault: 'Please add your payment details below and click "Submit" to complete the purchase' } }}
      </p>
    </div>
    <div class="payment-info">
      <p>
        <span class="total-label">{{ 'fgbcl.registration.payment.modal.total.label' | translate : { fgbdefault: 'Total (Incl. Shipping):' } }}</span>
        <span class="total-amount">{{ totalBasketPrice / 100 | FortressCurrencyPipe }}</span>
      </p>
    </div>
    <div class="payment-container">
      <div class="payment-form">
        <!-- Payment Provider Form -->
        <div id="paymentdiv">
          <div class="text-center p-4">
            <fgbcl-loading></fgbcl-loading>
          </div>
        </div>      
    </div>
    </div>
  </div>
</ng-template>

<ng-template #unappliedGiftCodeModal let-modal>
  <div class="text-center transfer-status-modal">
    <p class="font-weight-bold text-center text-danger mt-4 mb-0">{{ 'fgbcl.registration.payment.modal.giftcode.not.applied.title' | translate : { fgbdefault: 'The following gift code has not been applied:' } }}</p>
    <p class="text-info unapplied-code-instruction">{{ 'fgbcl.registration.payment.modal.giftcode.not.applied.instruction' | translate: { fgbdefault: 'Please remove or apply this code' } }}</p>
    <p class="font-weight-bold text-center text-success my-4">
      {{ giftCodeInput }}
    </p>
    <div class="d-flex justify-content-between mx-lg-4 mx-2">
      <button class="btn btn-danger font-weight-bold my-4" (click)="modal.dismiss('close'); removeUnappliedGiftCode()">
        {{ 'fgbcl.registration.payment.modal.remove.button' | translate : { fgbdefault: 'Remove Code' } }}
      </button>
      <button class="btn btn-success font-weight-bold my-4" (click)="modal.dismiss('close'); applyGiftCode()">
        {{ 'fgbcl.registration.payment.modal.apply.button' | translate : { fgbdefault: 'Apply Code' } }}
      </button>
    </div>
  </div>
</ng-template>


<!-- LOADING SPINNER  -->
<ng-template #loading>
    <div class="text-center p-4">
      <fgbcl-loading></fgbcl-loading>
    </div>
</ng-template>
