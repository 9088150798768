<div class="mobile-nav-menu d-flex position-fixed flex-column d-lg-none">
  <!-- NAV BAR -->
  <div class="mobile-nav-bar d-flex justify-content-between">
    <a class="nav-item font-weight-bold brand p-1" [routerLink]="['/']" routerLinkActive="router-link-active">
      <label id="homeLink" class="accessibility-only">Go To Home Page</label>
      <div id="brand-logo"></div>
    </a>
    <ng-container *inPopulation="[ populations.Orange, populations.Partner, populations.Kids ]">
      <a class="nav-item" [routerLink]="['/rewards/marketplace']">
        <span class="icon material-icons material-mobile-nav">redeem</span>
        <ng-container *notInPopulation="[ populations.Kids ]">
          <div class="top-nav-text font-weight-bold">{{ 'nav.rewards' | translate }}</div>
        </ng-container>
        <ng-container *inPopulation="[ populations.Kids ]">
          <div class="top-nav-text font-weight-bold">{{ 'nav.rewards.kids' | translate : { fgbdefault: 'Rewards' } }}</div>
        </ng-container>
      </a>
    </ng-container>
    <ng-container *inPopulation="[ populations.Kids ]">
      <a class="nav-item"  [routerLink]="['/badges']">
        <i class="icon material-icons material-mobile-nav">local_police</i>
        <div class="top-nav-text font-weight-bold">{{ 'nav.challenges' | translate }}</div>
      </a>
    </ng-container>
    <ng-container *inPopulation="[ populations.Orange, populations.Partner, populations.Partial ]">
      <a class="nav-item" [routerLink]="['/rewards/wallet']">
        <span class="icon material-icons material-mobile-nav">account_balance_wallet</span>
        <div class="top-nav-text font-weight-bold">{{ 'nav.wallet' | translate }}</div>
      </a>
    </ng-container>
    <button class="nav-item font-weight-bold navbar-toggler m-0 px-2" (click)="isCollapsed = !isCollapsed">
      <span class="icon material-icons material-mobile-nav hamburger-icon">menu</span>
    </button>
  </div>

  <!-- NAV SCREEN -->
  <div [ngbCollapse]="isCollapsed" class="mobile-nav-menu-dropdown">
    <div class="d-flex flex-column p-1 mobile-nav-menu-content">
      <!-- Close Button -->
      <button
        class="close-button position-absolute border-0"
        attr.aria-label="{{ 'modal.close' | translate }}"
        (click)="isCollapsed = !isCollapsed"
      >
        <span class="icon font-weight-bold icon-cancel-circle"></span>
      </button>
      <!-- Club Logo -->
      <div class="d-flex align-items-center mt-4 mb-3 mx-3 mobile-nav-header">
        <ng-container *notInPopulation="[populations.Default, populations.Kids ]">
          <img class="logo" src="assets/images/logos/brand-logo-white.svg" draggable="false" />
        </ng-container>
        <ng-container *inPopulation="[populations.Default]">
          <img class="logo" src="assets/images/PatronLogo.jpg" draggable="false" />
        </ng-container>
        <ng-container *inPopulation="[ populations.Kids ]">
          <img class="kids-logo" src="assets/images/logo_kids.svg" draggable="false" />
        </ng-container>
      </div>
      <div class="mt-2 member-info">
        <div class="w-100" *ngIf="memberCard$ | async as card">
          <div class="barcode-content mx-3 pb-2">
            <div class="d-flex justify-content-between align-items-center">
              <!--Name and ID-->
              <div class="card-id text-left">
                <h5 class="mb-1 card-name w-80 text-secondary" *ngIf="memberDetails$ | async as memberDetails">
                  {{ memberDetails.FirstName }}
                </h5>
                  <div class="d-flex product-code font-size-12">

                    <ng-container *inPopulation="[ populations.Orange ]">
                      <div>{{ 'mobile.menu.orange' | translate : { fgbdefault: 'Season' } }} -</div>
                      <div class="card-member-id pl-1">{{ card.ExternalRefNumber }}</div>
                    </ng-container>

                    <ng-container *inPopulation="[ populations.Default ]">
                      <div>{{ 'mobile.menu.fan' | translate : { fgbdefault: 'Duck Fan' } }} -</div>
                      <div class="card-member-id pl-1">{{ card.ExternalRefNumber }}</div>
                    </ng-container>
                    
                    <ng-container *inPopulation="[ populations.Partial ]">
                      <div>{{ 'mobile.menu.partial' | translate : { fgbdefault: 'Plan Holder' } }} -</div>
                      <div class="card-member-id pl-1">{{ card.ExternalRefNumber }}</div>
                    </ng-container>

                    <ng-container *inPopulation="[ populations.Partner ]">
                      <div>{{ 'mobile.menu.partner' | translate : { fgbdefault: 'Partner' } }} -</div>
                      <div class="card-member-id pl-1">{{ card.ExternalRefNumber }}</div>
                    </ng-container>

                    <ng-container *fgbWebsiteContentFeatureFlag="'feature.flag.nav.wild.wingers'">
                      <ng-container *inPopulation="[ populations.Kids ]">
                        <div>{{ 'mobile.menu.kids' | translate : { fgbdefault: 'Wild Wingers' } }} -</div>
                        <div class="card-member-id pl-1">{{ card.ExternalRefNumber }}</div>
                      </ng-container>
                    </ng-container>

                  </div>
      
              </div>
            </div>
            <ng-container *inPopulation="[populations.Orange, populations.Partner ]">
              <hr class="separator" />
            </ng-container>
            <div class="d-flex justify-content-between align-items-center">
              <ng-container *inPopulation="[populations.Orange, populations.Partner ]">
                <div class="d-flex align-items-end mb-1 justify-content-between w-100">
                  <div class="card-account-no text-left pr-3" *ngIf="pointsBalance$">
                    <label class="mb-0">{{ 'member.card.points.balance' | translate }}</label>
                    <div class="value">{{ pointsBalance$ | async | number }}</div>
                  </div>
                  <div class="card-id ecash-balance text-left pl-3 pr-3" *ngIf="ecash$">
                    <label class="mb-0">{{ 'member.card.ecash.balance' | translate }}</label>
                    <div class="value">{{ ecash$ | async | FortressCurrencyPipe }}</div>
                  </div>
                  <div class="card-id ecash-balance text-left pl-3 pr-3" *ngIf="card?.DiscountLevel">
                    <label class="mb-0">{{ 'mobile.nav.menu.discount.label' | translate : { fgbdefault: 'F&B' } }}</label>
                    <div class="value">{{ card?.DiscountLevel }}%</div>
                  </div>
                  <div class="card-id ecash-balance text-left pl-3 pr-3" *ngIf="card?.RetailDiscountLevel">
                    <label class="mb-0">{{ 'mobile.nav.menu.retail.discount.label' | translate : { fgbdefault: 'Merch' } }}</label>
                    <div class="value">{{ card?.RetailDiscountLevel }}%</div>
                  </div>
                </div>
              </ng-container>
              <ng-container *fgbWebsiteContentFeatureFlag="'feature.flag.nav.payment'">
                <ng-container *ngIf="cardToken$ | async as cardToken; else addCard">
                  <div class="text-left card-text font-size-12">
                    <ng-container *ngIf="!cardToken.Frozen; else frozen">
                      {{ 'barcode.payment.card' | translate }}
                      <span class="font-weight-bold text-success"> {{ 'barcode.payment.card.active' | translate }} </span>
                    </ng-container>
                    <ng-template #frozen>
                      {{ 'barcode.payment.card' | translate }}
                      <span class="font-weight-bold text-frozen"> {{ 'barcode.payment.card.frozen' | translate }}</span>
                    </ng-template>
                    <div>
                      <span
                        >{{ cardToken?.CardNumber | creditCardNumber | slice : -9 }}
                        <ng-container [ngSwitch]="cardToken!.CardType">
                          <img class="card-logo" *ngSwitchCase="1" src="assets/images/payment-icons/_Mastercard.svg" />
                          <img class="card-logo" *ngSwitchCase="2" src="assets/images/payment-icons/_AMEX.svg" />
                          <img class="card-logo" *ngSwitchCase="3" src="assets/images/payment-icons/_Visa.svg" />
                          <img class="card-logo" *ngSwitchCase="4" src="assets/images/payment-icons/_Discover.svg" />
                          <img class="card-logo" *ngSwitchCase="5" src="assets/images/payment-icons/_JCB.svg" />
                          <img class="card-logo" *ngSwitchCase="7" src="assets/images/payment-icons/_DinnersClub.svg" />
                          <img class="card-logo" *ngSwitchCase="8" src="assets/images/payment-icons/_UnionPay.svg" />
                          <div class="card-logo" *ngSwitchDefault></div>
                        </ng-container>
                      </span>
                    </div>
                  </div>
                </ng-container>
                <ng-template #addCard>
                  <button
                    type="button"
                    class="btn wallet-button border-0 p-0 d-flex justify-content-center align-items-center"
                    [routerLink]="['/payment']"
                  >
                    <span class="icon icon-settings-1 ml-2"></span>
                    <span class="ml-1 wallet-button-text text-white">{{ 'wallet.add.payment.card' | translate }}</span>
                  </button>
                </ng-template>
              </ng-container>
            </div>
            <hr class="separator" />

            <ng-container *inPopulation="[ populations.Kids ]">
              <div class="d-flex justify-content-center align-items-center">
                <a class="guardian-btn text-primary d-flex align-items-center justify-content-center" (click)="switchToGuardian()">
                  <i class="icon material-icons mr-1">logout</i>
                  {{ 'mobile.nav.back.to.guardians.button' | translate : { fgbdefault: 'Back to Guardian' } }}
                </a>
              </div>
              <hr class="separator" />
            </ng-container>
          </div>
        </div>
      </div>

      <!-- Nav Buttons -->
      <div class="mobile-nav-button-container">

        <ng-container *notInPopulation="[ populations.Kids ]">
          <a
            class="nav-item font-weight-bold large-item"
            [routerLink]="['/account']"
            [routerLinkActive]="'active'"
            [routerLinkActiveOptions]="{ exact: true }">
            <span class="icon material-icons material-mobile-dropdown mr-3" aria-hidden="true">person</span>
            <div class="nav-text font-weight-bold">{{ 'nav.account' | translate }}</div>
          </a>
        </ng-container>

        <ng-container *inPopulation="[ populations.Kids ]">
          <a
            class="nav-item"
            [routerLink]="['/rewards/marketplace']"
            [routerLinkActive]="'active'"
            [routerLinkActiveOptions]="{ exact: true }">
            <span class="icon material-icons material-mobile-dropdown mr-3" aria-hidden="true">redeem</span>
            <div class="nav-text font-weight-bold">{{ 'nav.rewards.kids' | translate : { fgbdefault: 'Rewards' } }}</div>
          </a>
          <a class="nav-item" 
             [routerLink]="['/rewards/wallet']"
             [routerLinkActive]="'active'"
             [routerLinkActiveOptions]="{ exact: true }">
              <span class="icon material-icons material-mobile-dropdown mr-3" aria-hidden="true">account_balance_wallet</span>
              <div class="nav-text font-weight-bold">{{ 'nav.wallet' | translate }}</div>
          </a>
          <a
            class="nav-item"
            [routerLink]="['/badges']"
            [routerLinkActive]="'active'"
            [routerLinkActiveOptions]="{ exact: true }">
            <span class="icon material-icons material-mobile-dropdown mr-3" aria-hidden="true">local_police</span>
            <div class="nav-text font-weight-bold">{{ 'nav.challenges' | translate }}</div>
          </a>
          <a
            class="nav-item"
            [routerLink]="['/worksheet']"
            [routerLinkActive]="'active'"
            [routerLinkActiveOptions]="{ exact: true }">
            <span class="icon material-icons-outlined material-mobile-dropdown mr-3" aria-hidden="true">draw</span>
            <div class="nav-text font-weight-bold">{{ 'nav.worksheet' | translate : { fgbdefault: 'Activities' } }}</div>
          </a>
          <a
            class="nav-item"
            [routerLink]="['/gallery']"
            [routerLinkActive]="'active'"
            [routerLinkActiveOptions]="{ exact: true }">
            <span class="icon material-icons material-mobile-dropdown mr-3" aria-hidden="true">photo_library</span>
            <div class="nav-text font-weight-bold">{{ 'nav.gallery' | translate : { fgbdefault: 'Gallery' } }}</div>
          </a>
        </ng-container>

        <ng-container *inPopulation="[ populations.Default ]">
          <a
            class="nav-item"
            [routerLink]="['/badges']"
            [routerLinkActive]="'active'"
            [routerLinkActiveOptions]="{ exact: true }"
          >
            <span class="icon material-icons material-mobile-dropdown mr-3" aria-hidden="true">local_police</span>
            <div class="nav-text font-weight-bold">{{ 'nav.challenges' | translate }}</div>
          </a>
        </ng-container>
        <ng-container *inPopulation="[populations.Partial]">
          <a
            class="nav-item"
            [routerLink]="['/rewards/wallet']"
            [routerLinkActive]="'active'"
            [routerLinkActiveOptions]="{ exact: true }"
          >
            <span class="icon material-icons material-mobile-dropdown mr-3" aria-hidden="true">account_balance_wallet</span>
            <div class="nav-text font-weight-bold">{{ 'nav.wallet' | translate }}</div>
          </a>
          <a
            class="nav-item"
            [routerLink]="['/badges']"
            [routerLinkActive]="'active'"
            [routerLinkActiveOptions]="{ exact: true }"
          >
            <span class="icon material-icons material-mobile-dropdown mr-3" aria-hidden="true">local_police</span>
            <div class="nav-text font-weight-bold">{{ 'nav.challenges' | translate }}</div>
          </a>
        </ng-container>
        <ng-container *inPopulation="[populations.Orange, populations.Partner]">
          <a
            class="nav-item"
            [routerLink]="['/rewards/marketplace']"
            [routerLinkActive]="'active'"
            [routerLinkActiveOptions]="{ exact: true }"
          >
            <span class="icon material-icons material-mobile-dropdown mr-3" aria-hidden="true">redeem</span>
            <div class="nav-text font-weight-bold">{{ 'nav.rewards' | translate }}</div>
          </a>
          <a
            class="nav-item"
            [routerLink]="['/badges']"
            [routerLinkActive]="'active'"
            [routerLinkActiveOptions]="{ exact: true }"
          >
            <span class="icon material-icons material-mobile-dropdown mr-3" aria-hidden="true">local_police</span>
            <div class="nav-text font-weight-bold">{{ 'nav.challenges' | translate }}</div>
          </a>
        </ng-container>
        <ng-container *fgbWebsiteContentFeatureFlag="'feature.flag.nav.payment'">
          <a
            class="nav-item"
            [routerLink]="['/payment']"
            [routerLinkActive]="'active'"
            [routerLinkActiveOptions]="{ exact: true }"
          >
            <span class="icon material-icons material-mobile-dropdown mr-3" aria-hidden="true">credit_card</span>
            <div class="nav-text font-weight-bold">{{ 'nav.payment' | translate }}</div>
          </a>
        </ng-container>

        <ng-container *notInPopulation="[ populations.Kids ]">
          
          <ng-container *fgbWebsiteContentFeatureFlag="'feature.flag.nav.wild.wingers'">
            <a class="nav-item" 
              [routerLink]="['/guardians']" 
              [routerLinkActive]="'active'" 
              [routerLinkActiveOptions]="{ exact: true }">
                <span class="icon material-icons material-mobile-dropdown mr-3" aria-hidden="true">family_restroom</span>
                <div class="nav-text font-weight-bold">{{ 'nav.wild.wingers' | translate : { fgbdefault: 'Wild Wingers' } }}</div>
            </a>
          </ng-container>
          <a class="nav-item" href="https://am.ticketmaster.com/ducks/" target="_blank">
            <span class="icon material-icons material-mobile-dropdown mr-3">confirmation_number</span>
            <div class="nav-text font-weight-bold">{{ 'nav.ticketing' | translate }}</div>
          </a>
          <a class="nav-item" 
             [routerLink]="['/contact']" 
             [routerLinkActive]="'active'" 
             [routerLinkActiveOptions]="{ exact: true }">
              <span class="icon material-icons material-mobile-dropdown mr-3" aria-hidden="true">email</span>
              <div class="nav-text font-weight-bold">{{ 'nav.contact.us' | translate }}</div>
          </a>
        </ng-container>

        <a class="nav-item" 
           [routerLink]="['/terms']" 
           [routerLinkActive]="'active'" 
           [routerLinkActiveOptions]="{ exact: true }">
            <span class="icon material-icons material-mobile-dropdown mr-3" aria-hidden="true">article</span>
            <div class="nav-text font-weight-bold">{{ 'nav.terms' | translate }}</div>
        </a>

        <a class="nav-item" 
           [routerLink]="['/faq']" 
           [routerLinkActive]="'active'" 
           [routerLinkActiveOptions]="{ exact: true }">
            <span class="icon material-icons material-mobile-dropdown mr-3" aria-hidden="true">help</span>
            <div class="nav-text font-weight-bold">{{ 'nav.faq' | translate }}</div>
        </a>

        <ng-container *notInPopulation="[ populations.Kids ]">
          <a class="nav-item logout" (click)="logout()">
            <span class="icon material-icons material-mobile-dropdown mr-3" aria-hidden="true">power_settings_new</span>
            <div class="nav-text font-weight-bold">{{ 'nav.log.out' | translate }}</div>
          </a>
        </ng-container>
      </div>
    </div>
  </div>
</div>
