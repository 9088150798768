<div class="points-summary-bar d-flex justify-content-between d-lg-none my-2">
  <!-- Back Button -->
  <div class="text-quaternary cursor-pointer d-flex align-items-center d-lg-none" [routerLink]="[backButtonLink]">
    <i class="material-icons">chevron_left</i>
    <ng-container *notInPopulation="[ populations.Kids ]">
      <span class="font-size-12 font-weight-bold">{{ 'back.to.rewards' | translate }}</span>
    </ng-container>
    <ng-container *inPopulation="[ populations.Kids ]">
      <span class="font-size-12 font-weight-bold">{{ 'back.to.rewards.kids' | translate }}</span>
    </ng-container>
  </div>
  <!-- Points Bar -->
  <div class="justify-content-center flex-grow-1 flex-shrink-1 h-100">
    <ng-container *notInPopulation="[ populations.Kids ]">
      <fgb-points-summary></fgb-points-summary>
    </ng-container>
  </div>
</div>
