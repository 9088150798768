<div class="full-page bg-primary w-100">
  <div class="registration-panel overflow-hidden">
    <div class="row">
      <div class="mobile-header d-lg-none d-flex">
        <img src="assets/images/login-registration/ducks-logo.png" draggable="false">
      </div>
      <div class="col-lg-6 d-none d-lg-block login-banner"></div>
      <div class="user-login-panel col-lg-6 col-12 text-left bg-white">
        <div class="container h-100">
          <div class="row h-100">
            <div class="col-12">
              <router-outlet></router-outlet>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
