import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'fgb-ticket-transfer-page',
  templateUrl: './ticket-transfer-page.component.html',
  styleUrls: ['./ticket-transfer-page.component.scss'],
})
export class TicketTransferPageComponent implements OnInit {
  constructor() {}

  ngOnInit() {
  }
}
