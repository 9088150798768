<div class="d-none d-lg-block">
  <div class="banner" role="banner">
  </div>

  <fgb-mixed-loyalty-nav></fgb-mixed-loyalty-nav>
</div>

<div *ngIf="auctionBid$ | async as item; else noItem" class="container">
  <!-- Points Summary -->
  <fgb-points-summary-bar [backButtonLink]="'/rewards/wallet'" [backButtonTitle]="'Wallet'"></fgb-points-summary-bar>

  <!-- Server Side Errors -->
  <fgbcl-errors></fgbcl-errors>

  <div class="d-block d-lg-flex mt-lg-3 pt-lg-3">
    <!-- Image -->
    <div class="loyalty-details-image card shadow border-0 rounded overflow-hidden p-0">
      <img
        class="card-img"
        src="{{ item.ImageUrl | contentImage: '7' }}"
        alt="{{ item.ImageUrl }}"
        [useDefault]="true"
        [loyaltyType]="'auction'"
        draggable="false"
      />
      <div class="card-img-overlay d-flex flex-column justify-content-start p-0">
        <fgbcl-loyalty-icon class="corner-icon bg-auction text-on-auction" [loyaltyType]="'auction'"></fgbcl-loyalty-icon>
      </div>
    </div>

    <!-- Details -->
    <div class="loyalty-details w-100">
      <h4 class="font-weight-bold text-capitalize pl-0 pl-lg-3 pt-3 pt-lg-0">{{ item.ItemName }}</h4>

      <!-- Card Body -->
      <div class="card-body d-flex flex-column justify-content-between pr-3 pl-0 pl-lg-3 pb-4 pt-2 pt-lg-3">
        <div class="row mb-2">
          <div class="col-12">
            <!-- Item Details -->
            <div class="row mb-2">
              <!-- Points -->
              <div class="col-6 col-lg-4">
                <span
                  class="svg svg-grey svg-marketplace-details-icons mr-2"
                  [inlineSVG]="'assets/images/icons/points-icon.svg'"
                ></span>
                <span class="font-size-18 pt-1">{{ item.LastBid | FortressCurrencyPipe: 'PTS' }}</span>
              </div>
              <!-- Status -->
              <div class="col-6">
                <span
                  class="svg svg-grey svg-marketplace-details-icons mr-2"
                  [inlineSVG]="'assets/images/icons/auctions-bids.svg'"
                ></span>
                <span class="font-size-18 pt-1">{{ item.EndDate | date: 'MMM d, yyyy' }}</span>
              </div>
            </div>

            <div *ngIf="!item.RedeemStatus" class="col-12 col-lg-4">
              <div class="qr my-4">
                <qrcode [qrdata]="item.Barcode" [size]="256" [level]="'M'" [usesvg]="true"></qrcode>
              </div>
            </div>

            <!-- Description  -->
            <div class="mb-2">
              <label class="pt-2 pb-2 pr-2 pl-0 mb-0 mt-4 text-primary mr-3">{{ 'wallet.description' | translate }}</label>
              <hr class="border-top border-disabled mt-0 mb-2" />
              <fgbcl-short-long-description [description]="item.ItemDescription"></fgbcl-short-long-description>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="text-primary pt-3 cursor-pointer d-none d-lg-block" [routerLink]="'/rewards/wallet'">
    <span class="icon icon-arrow-1-left font-weight-bold pr-2 font-size-10"></span>
    <span class="font-size-14 font-weight-bold">{{ 'wallet.back.to.wallet' | translate }}</span>
  </div>
</div>

<ng-template #noItem>
  <div class="text-center m-5">
    <i>{{ 'wallet.no.items.found' | translate }}</i>
  </div>
</ng-template>
