import { Component, OnInit, OnDestroy, Renderer2 } from '@angular/core';
import { RegistrationStepQuery, RegistrationStepService, LoggingService, StepType, WizardType } from '@fgb/core';
import { ActivatedRoute, Router, NavigationEnd } from '@angular/router';
import { Subscription } from 'rxjs';

@Component({
  selector: 'fgb-registration-layout-page',
  templateUrl: './registration-layout-page.component.html',
  styleUrls: ['./registration-layout-page.component.scss']
})
export class RegistrationLayoutPageComponent implements OnInit, OnDestroy {
  wizardType?: WizardType | undefined;
  wizardTypes = WizardType;
  private routerSubscription: Subscription;
  private wizardTypeSubscription: Subscription;

  constructor(
    private registrationStepQuery: RegistrationStepQuery,
    private registrationStepService: RegistrationStepService,
    private logService: LoggingService,
    private router: Router,
    private route: ActivatedRoute,
    private renderer: Renderer2
  ) {}

  ngOnInit() {
    this.wizardTypeSubscription = this.registrationStepQuery.selectWizardType().subscribe(wizardType => {
        this.wizardType = wizardType;
    });

    // Check once initially to make sure the initial step is one we should be able to get to.
    this.checkCurrentStepIsAccessible();

    this.routerSubscription = this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        // Navigation has finished on a new step so we should check that it is one we should be able to get to.
        this.checkCurrentStepIsAccessible();
        this.checkRouteAndDisableMobileHeader();
      }
    });

    this.checkRouteAndDisableMobileHeader();
    this.removeTheme();
  }

  ngOnDestroy(): void {
    if (this.routerSubscription) {
      this.routerSubscription.unsubscribe();
    }

    if (this.wizardTypeSubscription) {
      this.wizardTypeSubscription.unsubscribe();
    }
  }

  /**
   * Checks if the step type configured in the app routing module for the current route is a step that is part of
   * the current registration process. If not then it redirects to the correct next step for the user to complete.
   */
  private checkCurrentStepIsAccessible(): void {
    this.logService.debug('Checking if the current activated route is accessible in the current registration process...');

    const firstRouteChild = this.route.snapshot.firstChild;
    if (!firstRouteChild) {
      this.logService.debug('Activated route does not have a child route - failed to determine step type of the route.');
      return;
    }

    const stepType: StepType = firstRouteChild.data.registrationStep;
    if (!stepType) {
      this.logService.debug('No step type found for the activated route.', this.route.snapshot);
      return;
    }

    const stepInRegistration: boolean = this.registrationStepQuery.isStepAvailable(stepType);
    if (!stepInRegistration) {
      this.logService.warning('This registration step is not available as part of the current registration process.', stepType);
      this.registrationStepService.navigateToNextStep();
      return;
    }

    this.logService.debug('Step type is accessible in the current registration process', stepType);
  }

  /**
   * Remove all theme classes from the body element.
   * This is to prevent the registration pages from inheriting the theme styles.
   * The theme will be set again when the user logs in.
   */
  removeTheme(): void{
    document.body.classList.forEach((className) => {
      if(className.includes('theme')){
        this.renderer.removeClass(document.body, className);
      }
    });
  }

  // Check if route is children or memberships and disable mobile header
  checkRouteAndDisableMobileHeader(): void{
    const path = this.router.url.split('/').reverse()[0];
    const mobileHeader = document.querySelector('.mobile-header');
    const userLoginPagel = document.querySelector('.user-login-panel');

    if(path === 'children' || path === 'memberships'){
      this.renderer.addClass(mobileHeader, 'hidden');
      this.renderer.addClass(userLoginPagel, 'full-h');
    }else{
      this.renderer.removeClass(mobileHeader, 'hidden');
      this.renderer.removeClass(userLoginPagel, 'full-h');
    }
  }
}
