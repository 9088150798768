<div class="answer-container d-flex flex-row" 
     [ngClass]="{ 
      'selected': answer.UserSelected,
      'image-only': displayType === answerDisplayType.Image }">
  <ng-container *ngIf="displayType != answerDisplayType.Text">
    <img
      *ngIf="answer.ImageURL"
      class="image-container icon"
      src="{{ answer.ImageURL | contentImage: '7' }}"
      alt="{{ answer.ImageURL }}"
    />
  </ng-container>
  <div class="answer-text-wrapper w-100 progress">
    <span class="poll-percentage">{{ percentage + '%' }}</span>
    <div class="answer-text-landscape text-right">
      <span *ngIf="displayType !== answerDisplayType.Image">
        {{ answer.Title }}
      </span>
    </div>
    <div class="answer-text progress-bar text-left font-weight-bold w-100 h-100">
      <ngb-progressbar [value]="percentage"></ngb-progressbar>
    </div>
  </div>
  <div class="answer-text-bg"></div>
</div>
