import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Populations } from 'src/app/shared/utilities/populations';

@Component({
  selector: 'fgb-auction-detail-page',
  templateUrl: './auction-detail-page.component.html',
  styleUrls: ['./auction-detail-page.component.scss']
})
export class AuctionDetailPageComponent implements OnInit {
  id: number;
  populations = Populations;
  
  constructor(private route: ActivatedRoute) {}
  ngOnInit() {
    this.id = +(this.route.snapshot.paramMap.get('id') || 0);
  }
}
