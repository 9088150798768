<div class="barcode" 
     [ngClass]="{
      'apple-wallet-enabled': showAppleWallet
     }"
    *ngIf="barcode$ | async as barcode">
  <div class="p-0 d-flex flex-wrap flex-column justify-content-around align-items-center rounded shadow bg-white">
    <ng-container *ngIf="card$ | async as card">
      <div class="qr mt-3">
        <qrcode [qrdata]="barcode.Barcode" [size]="256" [level]="'M'" [usesvg]="true"></qrcode>
      </div>
      <div class="font-size-12 text-black font-italic mb-2">{{ 'barcode.no.access' | translate }}</div>
      <ng-container *ngIf="member$ | async as member">
        <!-- Not Owner Barcode Body -->
        <div class="w-100 barcode-content-container pt-3">
          <div class="barcode-content mx-3 pb-2">
            <div class="d-flex justify-content-between align-items-center" *ngIf="card$ | async as card">
              <!--Name and ID-->
              <div class="card-id text-left">
                <h5 class="mb-1 card-name w-100 text-secondary" *ngIf="memberDetails$ | async as memberDetails">
                  {{ memberDetails.FirstName }}
                </h5>
                <ng-container *inPopulation="[ populations.Orange ]">
                  <div class="d-flex product-code font-size-12 mb-2">
                    <div>{{ 'mobile.barcode.orange' | translate : { fgbdefault: 'Season' } }} -</div>
                    <div class="card-member-id pl-1">{{ card.ExternalRefNumber }}</div>
                  </div>
                </ng-container>
                <ng-container *inPopulation="[ populations.Default ]">
                  <div class="d-flex product-code font-size-12 mb-2">
                    <div>{{ 'mobile.barcode.fan' | translate : { fgbdefault: 'Ducks Fan' } }} -</div>
                    <div class="card-member-id pl-1">{{ card.ExternalRefNumber }}</div>
                  </div>
                </ng-container>
                <ng-container *inPopulation="[ populations.Partial ]">
                  <div class="d-flex product-code font-size-12 mb-2">
                    <div>{{ 'mobile.barcode.plan' | translate : { fgbdefault: 'Plan Holder' } }} -</div>
                    <div class="card-member-id pl-1">{{ card.ExternalRefNumber }}</div>
                  </div>
                </ng-container>
                <ng-container *inPopulation="[ populations.Partner ]">
                  <div class="d-flex product-code font-size-12 mb-2">
                    <div>{{ 'mobile.barcode.partner' | translate : { fgbdefault: 'Partner' } }} -</div>
                    <div class="card-member-id pl-1">{{ card.ExternalRefNumber }}</div>
                  </div>
                </ng-container>
                <ng-container *inPopulation="[ populations.Kids ]">
                  <div class="d-flex product-code font-size-12 mb-2">
                    <div>{{ 'mobile.barcode.kids' | translate : { fgbdefault: 'Wild Wingers' } }} -</div>
                    <div class="card-member-id pl-1">{{ card.ExternalRefNumber }}</div>
                  </div>
                </ng-container>
              </div>
              <ng-container *ngIf="barcodeOptions?.showWalletButton">
                <ng-container *inPopulation="[ populations.Kids ]">
                  <div class="button-wrapper">
                    <button class="btn wallet-btn btn-primary" [routerLink]="['/rewards/wallet']">
                      <i class="icon material-icons">account_balance_wallet</i>
                      {{ 'barcode.wallet.button' | translate : { fgbdefault: 'Wallet' } }}
                    </button>
                  </div>
                </ng-container>
              </ng-container>
            </div>
            <ng-container *inPopulation="[ populations.Orange, populations.Partner ]">
              <hr class="separator" />
            </ng-container>
            <div class="d-flex justify-content-between align-items-center">
              <ng-container *inPopulation="[populations.Orange, populations.Partner ]">
                <div class="d-flex align-items-end mb-1 w-100">
                  <div class="card-account-no text-left pr-3" *ngIf="pointsBalance$">
                    <label class="mb-1">{{ 'member.card.points.balance' | translate }}</label>
                    <div class="value">{{ pointsBalance$ | async | number }}</div>
                  </div>
                  <div class="card-id ecash-balance text-left pl-3 ml-3 pr-3" *ngIf="ecash$">
                    <label class="mb-1">{{ 'member.card.ecash.balance' | translate }}</label>
                    <div class="value">{{ ecash$ | async | FortressCurrencyPipe }}</div>
                  </div>
                  <ng-container *ngIf="card?.DiscountLevel">
                    <div class="text-left pl-4 ml-2 pr-3">
                      <label class="mb-1 discount-title">{{ 'member.card.discount.label' | translate : { fgbdefault: 'F&B' } }}</label>
                      <div class="value">{{ card.DiscountLevel }}%</div>
                    </div>
                  </ng-container>
                  <ng-container *ngIf="card?.RetailDiscountLevel">
                    <div class="text-left pl-3 pr-3 ml-auto">
                      <label class="mb-1 discount-title">{{ 'member.card.retail.discount.label' | translate : { fgbdefault: 'Merch' } }}</label>
                      <div class="value">{{ card.RetailDiscountLevel }}%</div>
                    </div>
                  </ng-container>
                </div>
              </ng-container>
              <!-- <ng-container *fgbWebsiteContentFeatureFlag="'feature.flag.nav.payment'">
                <ng-container *ngIf="cardToken$ | async as cardToken; else addCard">
                  <div class="text-left card-text font-size-12">
                    <ng-container *ngIf="!cardToken.Frozen; else frozen">
                      {{ 'barcode.payment.card' | translate }}
                      <span class="font-weight-bold text-success"> {{ 'barcode.payment.card.active' | translate }} </span>
                    </ng-container>
                    <ng-template #frozen>
                      {{ 'barcode.payment.card' | translate }}
                      <span class="font-weight-bold text-frozen"> {{ 'barcode.payment.card.frozen' | translate }}</span>
                    </ng-template>
                    <div>
                      <span
                        >{{ cardToken?.CardNumber | creditCardNumber | slice : -9 }}
                        <ng-container [ngSwitch]="cardToken!.CardType">
                          <img class="card-logo" *ngSwitchCase="1" src="assets/images/payment-icons/_Mastercard.svg" />
                          <img class="card-logo" *ngSwitchCase="2" src="assets/images/payment-icons/_AMEX.svg" />
                          <img class="card-logo" *ngSwitchCase="3" src="assets/images/payment-icons/_Visa.svg" />
                          <img class="card-logo" *ngSwitchCase="4" src="assets/images/payment-icons/_Discover.svg" />
                          <img class="card-logo" *ngSwitchCase="5" src="assets/images/payment-icons/_JCB.svg" />
                          <img class="card-logo" *ngSwitchCase="7" src="assets/images/payment-icons/_DinnersClub.svg" />
                          <img class="card-logo" *ngSwitchCase="8" src="assets/images/payment-icons/_UnionPay.svg" />
                          <div class="card-logo" *ngSwitchDefault></div>
                        </ng-container>
                      </span>
                    </div>
                  </div>
                </ng-container>
                <ng-template #addCard>
                  <button
                    type="button"
                    class="btn wallet-button border-0 p-0 d-flex justify-content-center align-items-center"
                    [routerLink]="['/payment']"
                  >
                    <span class="icon icon-settings-1 ml-2"></span>
                    <span class="ml-1 wallet-button-text text-white">{{ 'wallet.add.payment.card' | translate }}</span>
                  </button>
                </ng-template>
              </ng-container> -->
            </div>
          </div>
          <ng-container *fgbWebsiteContentFeatureFlag="'feature.flag.nav.payment.toggle'">
            <ng-container *inPopulation="[ populations.Orange, populations.Partner ]">
              <div class="cash-back-toggle-container px-3 py-2">
                <fgbcl-cash-back-toggle></fgbcl-cash-back-toggle>
              </div>
            </ng-container>
          </ng-container>
        </div>
      </ng-container>
    </ng-container>
    <ng-container *inPopulation="[ populations.Default ]">
      <ng-container *ngTemplateOutlet="mobilePass"></ng-container>
    </ng-container>
  </div>
  <ng-container *inPopulation="[ populations.Orange, populations.Partial, populations.Partner ]">
    <div
      [ngClass]="{
        'mt-2': showAppleWallet
      }">
      <ng-container *ngTemplateOutlet="mobilePass"></ng-container>
    </div>
  </ng-container>
</div>

<ng-template #mobilePass>
  <ng-container *ngIf="showAppleWallet">
    <div class="bg-mobile-pass mobile-pass">
      <fgb-mobile-pass class="text-white" [separateTop]="true"></fgb-mobile-pass>
    </div>
  </ng-container>
</ng-template>
