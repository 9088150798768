<div class="container">
  <form *ngIf="transferCashForm" [formGroup]="transferCashForm">
    <div class="form-group">
      <div class="row">
        <div class="col-lg-5 col-12">
          <label class="font-size-12 font-weight-bold" for="emailAddress">Email Address</label>
          <input
            type="text"
            formControlName="emailAddress"
            id="emailAddress"
            placeholder="Enter recipients email"
            class="form-control border border-black rounded font-size-14 text-black position-relative"
          />
          <div *ngIf="submitted && f.emailAddress.errors" class="alert alert-danger font-size-14">
            <div *ngIf="f.emailAddress.errors.required">Email Address is required</div>
            <div *ngIf="f.emailAddress.errors.pattern">Email Address format incorrect</div>
          </div>
        </div>
        <div class="col-lg-4 col-12">
          <label class="amount-title font-size-12 font-weight-bold" for="amount">Amount</label>
          <input
            currencyMask
            formControlName="amount"
            placeholder="{{ 0 | FortressCurrencyPipe: 'USD' }}"
            id="amount"
            class="form-control border border-black rounded font-size-14 text-black position-relative amount-control ml-auto mr-auto"
          />
          <div *ngIf="submitted && f.amount.errors" class="alert alert-danger font-size-14">
            <div *ngIf="f.amount.errors.required">Amount is required</div>
            <div *ngIf="f.amount.errors.pattern">Numbers only</div>
          </div>
        </div>
        <div class="col-lg-3 col-12">
          <button
            type="submit"
            class="btn btn-send font-size-14 font-weight-bold bg-secondary text-white w-100"
            confirmModal
            [disabled]="!transferCashForm.valid"
            [confirmContent]="confirmContent"
            (confirm)="verifyEmail()"
            [ngClass]="{ 'bg-info': !transferCashForm.valid }"
          >
            Send
          </button>
        </div>

        <!--Confirm Modal-->
        <ng-template #confirmContent>
          <p class="mb-4 font-size-16 text-black text-center">
            Are you sure that you would like to send to<br />
            <span class="text-primary">{{ transferCashForm.value.emailAddress }}</span> the amount of
            <span class="text-secondary">{{ transferCashForm.value.amount | FortressCurrencyPipe: 'USD' }}</span> ?
          </p>
        </ng-template>
      </div>

      <!-- Can't Transfer to Self Modal -->
      <ng-template #transferOwnEmail let-modal>
        <div class="modal-body">
          <div class="modal-title text-center mt-3">Transfer Failed</div>
          <p class="mb-4 p-4 font-size-16 text-black text-center">You cannot transfer to your own email address</p>
          <button
            type="button"
            class="modal-return rounded d-block ml-auto mr-auto bg-secondary text-white font-size-14 font-weight-bold text-center border-0 fail"
            data-dismiss="modal"
            aria-label="Close"
            (click)="modal.dismiss('Cross click')"
          >
            Back to Funds
          </button>
        </div>
      </ng-template>

      <!-- Transfer Fail Modal -->
      <ng-template #transferFailed let-modal>
        <div class="modal-body">
          <div class="modal-title text-center mt-3">Transfer Failed</div>
          <p class="mb-4 p-4 font-size-16 text-black text-center">
            No PayPal SixthMan Member can be found with this email…<br /><br />
            Recipient must have a Phoenix Suns Account Manager account to receive Arena Cash. Please have recipient download the
            Official Suns App and create an Account Manager account
          </p>
          <button
            type="button"
            class="modal-return rounded d-block ml-auto mr-auto bg-secondary text-white font-size-14 font-weight-bold text-center border-0 fail"
            data-dismiss="modal"
            aria-label="Close"
            (click)="modal.dismiss('Cross click')"
          >
            Back to Funds
          </button>
        </div>
      </ng-template>

      <!-- Not Enough Funds Modal -->
      <ng-template #invalidFunds let-modal>
        <div class="modal-body">
          <p class="mb-4 font-size-16 text-black text-center">
            Unfortunately you do not have enough money to send to <br />
            <span class="text-primary">{{ transferCashForm.value.emailAddress }}</span> the amount of
            <span class="text-secondary">{{ transferCashForm.value.amount | FortressCurrencyPipe: 'USD' }}</span>
          </p>
          <button
            type="button"
            class="modal-return rounded d-block ml-auto mr-auto bg-secondary text-white font-size-14 font-weight-bold text-center border-0"
            data-dismiss="modal"
            aria-label="Close"
            (click)="modal.dismiss('Cross click')"
          >
            Return to Arena Cash
          </button>
        </div>
      </ng-template>

      <fgbcl-errors></fgbcl-errors>

      <ng-container *ngIf="loading">
        <div class="loading-transactions">
          <fgbcl-loading></fgbcl-loading>
        </div>
      </ng-container>
    </div>
  </form>
</div>
