<ng-container *ngIf="mixedSurveys$ | async as mixedSurveyItems">
  <div *ngIf="mixedSurveyItems.length > 0">
    <h4 class="pt-4 pb-2 text-left text-white">{{ 'home.page.featured.games.title' | translate : { fgbdefault: 'Featured Games' } }}</h4>
    <owl-carousel-o *ifScreenSize="screenType.Desktop" [options]="customOptions" (dragging)="isDragging = $event.dragging">
      <ng-container *ngFor="let item of mixedSurveyItems; trackBy: trackByFn">
        <ng-template carouselSlide [width]="930">
          <fgb-survey-item [survey]="item"></fgb-survey-item>
        </ng-template>
      </ng-container>
    </owl-carousel-o>
    <owl-carousel-o *ifScreenSize="screenType.Mobile" [options]="customOptions" (dragging)="isDragging = $event.dragging">
      <ng-container *ngFor="let item of mixedSurveyItems; trackBy: trackByFn">
        <ng-template carouselSlide>
          <div class="w-100 pr-2">
            <fgb-survey-item [survey]="item"></fgb-survey-item>
          </div>
        </ng-template>
      </ng-container>
    </owl-carousel-o>
  </div>
</ng-container>
