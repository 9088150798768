<div class="transaction-history">
  <h3 *ngIf="ecashView" class="transaction-title text-black mb-4">
    {{ 'payment.history.page.title' | translate }}
  </h3>
  
  <div class="card b-0 mt-3 mt-lg-0 transaction-card">
    <div class="card-body transaction-body">
      <div class="d-flex flex-column flex-lg-row">
        <fgbcl-date-range-picker [(startDate)]="transactionStartDate" [(endDate)]="transactionEndDate"></fgbcl-date-range-picker>
        <button
          type="button"
          class="search-button font-weight-bold d-block border-0 text-white bg-secondary ml-auto mt-auto mb-2"
          (click)="search()"
        >
          Search
        </button>
      </div>
    </div>
  </div>
  
  <fgb-transaction-list
    *ngIf="selectedValues; else noSearch"
    [startDate]="selectedValues.start"
    [endDate]="selectedValues.end"
    [memberId]="memberId"
    [transactionType]="selectedValues.type"
  ></fgb-transaction-list>
  
  <ng-template #noSearch>
    <div class="d-flex flex-column">
      <span class="icon material-icons material-history-icon mx-auto text-grey mt-5 mb-2">history</span>
      <h5 *ngIf="ecashView" class="text-center mb-0 font-size-16">
        {{ 'account.ecash.placeholder.list.title' | translate }}
      </h5>
      <h5 *ngIf="accountView" class="text-center mb-0 font-size-16">
        {{ 'account.activity.placeholder.list.title' | translate }}
      </h5>
      <p class="text-center font-size-12 text-grey transaction-placeholder-text">
        {{ 'account.activity.placeholder.list.description' | translate }}
      </p>
    </div>
  </ng-template>
  
</div>