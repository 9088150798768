// External Dependencies
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { DatePipe, CurrencyPipe } from '@angular/common';
import { Router, RouterModule } from '@angular/router';
import { NgbModule, NgbDateParserFormatter } from '@ng-bootstrap/ng-bootstrap';
import { JwtModule } from '@auth0/angular-jwt';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HttpClient, HTTP_INTERCEPTORS } from '@angular/common/http';
import { MissingTranslationHandler, TranslateLoader, TranslateModule, TranslateService } from '@ngx-translate/core';
import { CookieService } from 'ngx-cookie-service';
import { QRCodeModule } from 'angularx-qrcode';
import { AkitaNgDevtools } from '@datorama/akita-ngdevtools';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CarouselModule } from 'ngx-owl-carousel-o';
import { ToastrModule } from 'ngx-toastr';
import { InlineSVGModule } from 'ng-inline-svg';
// Routing & Configuration
import { AppRoutingModule } from './app-routing.module';
import { environment } from '../environments/environment';
// Feature Modules
import { SharedModule } from './shared/shared.module';
import { ClubModule } from './modules/club/club.module';
import { UserModule } from './modules/user/user.module';
import { LoyaltyModule } from './modules/loyalty/loyalty.module';
import { VouchersModule } from './modules/vouchers/vouchers.module';
import { EcashModule } from './modules/ecash/ecash.module';
import { PurchaseModule } from './modules/purchase/purchase.module';
import { GuardianModule } from './modules/guardian/guardian.module';
import { EventsModule } from './modules/events/events.module';
import { TicketsModule } from './modules/tickets/tickets.module';
import { FantasyModule } from './modules/fantasy/fantasy.module';
import { PassesModule } from './modules/passes/passes.module';
import { DisplayModule } from './modules/display/display.module';

// Utilities
import { MomentDateFormatter } from './shared/utilities/moment-date-formatter';
// @fgb/core
import {
  AuthGuard,
  PopulationGuard,
  ProductCodeGuard,
  MaintenanceGuard,
  jwtTokenGetter,
  AuthService,
  LoggingService,
  ErrorStateService,
  TokenRefreshIntereptor,
  HttpErrorInterceptor,
  MemberQuery,
  RegistrationStepService,
  FirstTimeRegistrationInterceptor,
  TMOauthService,
  TMAuthService,
  tmAuthConfig,
  tmOAuthConfig,
  FGBTranslationLoader,
  FGBMissingTranslationHandler,
  DATE_OPTIONS,
  REGISTRATION_ROUTES,
  DateOptions,
  DEFAULT_DATE_OPTIONS,
  PropertyTypeCode,
  RegistrationRoute,
  RegistrationRouteType
} from '@fgb/core';

//Component Library
import { CardTokenListModule } from '@fgb/portal-component-library/src/lib/ecash/card-token-list';
import { EcashDescriptionModule } from '@fgb/portal-component-library/src/lib/ecash/ecash-description';
import { LoadingModule } from '@fgb/portal-component-library/src/lib/shared/loading';
import { ErrorsModule } from '@fgb/portal-component-library/src/lib/shared/errors';
import { PortalLinkCardModule } from '@fgb/portal-component-library/src/lib/shared/portal-link-card';
import { TicketTransferWindowStatusModule } from '@fgb/portal-component-library/src/lib/tickettransfer/ticket-transfer-window-status';
import { GamesListWithTicketsModule } from '@fgb/portal-component-library/src/lib/tickettransfer/games-list-with-tickets';
import { TicketTransferGamePanelModule } from '@fgb/portal-component-library/src/lib/tickettransfer/ticket-transfer-game-panel';
import { PortalIdModule } from '@fgb/portal-component-library/src/lib/shared/portal-id';
import { EarnPointsModule } from '@fgb/portal-component-library/src/lib/club/earn-points';
import { FaqSectionModule } from '@fgb/portal-component-library/src/lib/club/faq-section';
import { TermsAndConditionsModule } from '@fgb/portal-component-library/src/lib/club/terms-and-conditions';
import { CountryAndRegionsModule } from '@fgb/portal-component-library/src/lib/shared/country-and-regions';
import { DirectivesModule } from '@fgb/portal-component-library/src/lib/directives';
import { AuctionDetailsModule } from '@fgb/portal-component-library/src/lib/loyalty/auctions/auction-details';
import { AuctionPanelModule } from '@fgb/portal-component-library/src/lib/loyalty/auctions/auction-panel';
import { AuctionListModule } from '@fgb/portal-component-library/src/lib/loyalty/auctions/auction-list';
import { LottoDetailsModule } from '@fgb/portal-component-library/src/lib/loyalty/lottos/lotto-details';
import { LottosListItemModule } from '@fgb/portal-component-library/src/lib/loyalty/lottos/lottos-list-item';
import { LottosListModule } from '@fgb/portal-component-library/src/lib/loyalty/lottos/lottos-list';
import { MarketplaceDetailsModule } from '@fgb/portal-component-library/src/lib/loyalty/marketplace/marketplace-details';
import { MarketplaceListItemModule } from '@fgb/portal-component-library/src/lib/loyalty/marketplace/marketplace-list-item';
import { MarketplaceListModule } from '@fgb/portal-component-library/src/lib/loyalty/marketplace/marketplace-list';
import { ScorecardCarouselModule } from '@fgb/portal-component-library/src/lib/loyalty/scorecards/scorecard-carousel';
import { BadgeListItemModule } from '@fgb/portal-component-library/src/lib/loyalty/badges/badge-list-item';
import { MixedLoyaltyListModule } from '@fgb/portal-component-library/src/lib/loyalty/mixed-loyalty/mixed-loyalty-list';
import { BadgeOverviewListModule } from '@fgb/portal-component-library/src/lib/loyalty/badges/badge-overview-list';
import { BadgeFeaturedListModule } from '@fgb/portal-component-library/src/lib/loyalty/badges/badge-featured-list';
import { WalletRedeemListModule } from '@fgb/portal-component-library/src/lib/loyalty/wallet/wallet-redeem-list';
import { WalletRedeemedListModule } from '@fgb/portal-component-library/src/lib/loyalty/wallet/wallet-redeemed-list';
import { BarcodeModule } from '@fgb/portal-component-library/src/lib/user/barcode';
import { FeaturedRewardsModule } from '@fgb/portal-component-library/src/lib/loyalty/featured-rewards';
import { ContactsManagementModule } from '@fgb/portal-component-library/src/lib/tickettransfer/contacts-management';
import { GameBannerModule } from '@fgb/portal-component-library/src/lib/tickettransfer/game-banner';
import { TicketListModule } from '@fgb/portal-component-library/src/lib/tickettransfer/ticket-list';
import { TicketListItemModule } from '@fgb/portal-component-library/src/lib/tickettransfer/ticket-list-item';
import { SelectedTicketsCountModule } from '@fgb/portal-component-library/src/lib/tickettransfer/selected-tickets-count';
import { SelectedTicketsTableModule } from '@fgb/portal-component-library/src/lib/tickettransfer/selected-tickets-table';
import { TransferFormModule } from '@fgb/portal-component-library/src/lib/tickettransfer/transfer-form';
import { AddressBookModule } from '@fgb/portal-component-library/src/lib/user/address-book';
import { RewardsWalletRedeemListModule } from '@fgb/portal-component-library/src/lib/loyalty/wallet/wallet-v2/rewards-wallet-redeem-list';

// General Pages
import { AppComponent } from './app.component';
import { MasterPageComponent } from './pages/master/master-page/master-page.component';
import { HomePageComponent } from './pages/home-page/home-page.component';
import { LoginPageComponent } from './pages/login-page/login-page.component';
import { LoginAccountsPageComponent } from './pages/login-accounts-page/login-accounts-page.component';
import { MaintenancePageComponent } from './pages/maintenance-page/maintenance-page.component';
import { TermsPageComponent } from './pages/terms-page/terms-page.component';
import { FaqPageComponent } from './pages/faq-page/faq-page.component';
import { ContactUsPageComponent } from './pages/contact-us-page/contact-us-page.component';
import { ReferAFriendPageComponent } from './pages/refer-a-friend-page/refer-a-friend-page.component';
import { ReferAFriendSuccessPageComponent } from './pages/refer-a-friend-page/refer-a-friend-success-page/refer-a-friend-success-page.component';
import { NotFoundPageComponent } from './pages/not-found-page/not-found-page.component';
import { CollectAddressModal } from './modules/loyalty/components/collect-address-modal/collect-address-modal.component';
// Loyalty Pages
import { RewardsLayoutComponent } from './pages/rewards-layout/rewards-layout.component';
import { RewardsPageComponent } from './pages/rewards-page/rewards-page.component';
import { AuctionsPageComponent } from './pages/auctions-page/auctions-page.component';
import { LottosPageComponent } from './pages/lottos-page/lottos-page.component';
import { MarketplacePageComponent } from './pages/marketplace-page/marketplace-page.component';
import { EventsPageComponent } from './pages/events-page/events-page.component';
import { WalletPageComponent } from './pages/wallet-page/wallet-page.component';
import { AuctionDetailPageComponent } from './pages/auction-detail-page/auction-detail-page.component';
import { LottoDetailPageComponent } from './pages/lotto-detail-page/lotto-detail-page.component';
import { MarketplaceDetailPageComponent } from './pages/marketplace-detail-page/marketplace-detail-page.component';
import { LottosSuccessPageComponent } from './pages/lottos-page/lottos-success-page/lottos-success-page.component';
import { MarketplacePurchaseSuccessPageComponent } from './pages/marketplace-page/marketplace-purchase-success-page/marketplace-purchase-success-page.component';
import { AuctionBidsPageComponent } from './pages/auction-bids-page/auction-bids-page.component';
import { AuctionBidDetailsPageComponent } from './pages/auction-bid-details-page/auction-bid-details-page.component';
import { LottoWalletDetailsPageComponent } from './pages/lotto-wallet-details-page/lotto-wallet-details-page.component';
import { QuizPageComponent } from './pages/quiz-page/quiz-page.component';
// Polls, Surveys & Trivia (Games)
import { GamesLayoutComponent } from './pages/games-layout/games-layout.component';
import { GamesPageComponent } from './pages/games-page/games-page.component';
import { PollsPageComponent } from './pages/polls-page/polls-page.component';
import { FantasyGamesPageComponent } from './pages/fantasy-games-page/fantasy-games-page.component';
import { FantasyPlayerSelectPageComponent } from './pages/fantasy-player-select-page/fantasy-player-select-page.component';
import { PredictorPageComponent } from './pages/predictor-page/predictor-page.component';
// Account Pages
import { AccountDetailsPageComponent } from './pages/account-details-page/account-details-page.component';
import { ActivityPageComponent } from './pages/activity-page/activity-page.component';
// Ecash Pages
import { EcashPageComponent } from './pages/ecash-page/ecash-page.component';
import { EcashResultPageComponent } from './pages/ecash-page/ecash-result-page/ecash-result-page.component';
import { EcashSuccessPageComponent } from './pages/ecash-page/ecash-success-page/ecash-success-page.component';
import { EcashFailurePageComponent } from './pages/ecash-page/ecash-failure-page/ecash-failure-page.component';
import { EcashHistoryPageComponent } from './pages/ecash-history/ecash-history.component';
// Tickets Pages
import { TicketsPageComponent } from './pages/ticket-transfer-page/tickets-page/tickets-page.component';
import { ManageTicketsPageComponent } from './pages/manage-tickets-page/manage-tickets-page.component';
import { TicketTransferPageComponent } from './pages/ticket-transfer-page/ticket-transfer-page.component';
import { TransferConfirmPageComponent } from './pages/ticket-transfer-page/transfer-confirm-page/transfer-confirm-page.component';
import { TicketTransferSuccessPageComponent } from './pages/ticket-transfer-page/ticket-transfer-success-page/ticket-transfer-success-page.component';
import { ContactsPageComponent } from './pages/contacts-page/contacts-page.component';
import { ManageTicketHistoryPageComponent } from './pages/manage-ticket-history-page/manage-ticket-history-page.component';
import { MobilePassModalComponent } from './modules/passes/components/mobile-pass-modal/mobile-pass-modal.component';
// Offer Pages
import { OffersPageComponent } from './pages/offers-page/offers-page.component';
import { OfferDetailsPageComponent } from './pages/offer-details-page/offer-details-page.component';
// Population Pages
import { TestPopHomePageComponent } from './pages/test-pop-home-page/test-pop-home-page.component';
// Portal Products
import { PortalProductResultsPageComponent } from './pages/portal-products-pages/portal-product-results-page/portal-product-results-page.component';
import { PortalProductSuccessPageComponent } from './pages/portal-products-pages/portal-product-success-page/portal-product-success-page.component';
import { PortalProductFailurePageComponent } from './pages/portal-products-pages/portal-product-failure-page/portal-product-failure-page.component';
import { UpgradeChildrenComponent } from './modules/guardian/components/upgrade-children/upgrade-children.component';
// Benefits
import { BenefitsPageComponent } from './pages/benefits-page/benefits-page.component';
import { BenefitModule } from './modules/benefit/benefit.module';
import { BenefitInfoModalComponent } from './modules/benefit/components/benefit-info-modal/benefit-info-modal.component';
import { BenefitClaimConfirmationModalComponent } from './modules/benefit/components/benefit-claim-confirmation-modal/benefit-claim-confirmation-modal.component';
// External Cash Transfer
import { CardPageComponent } from './pages/card-page/card-page.component';
import { TransferArenaCashPageComponent } from './pages/transfer-arena-cash-page/transfer-arena-cash-page.component';
import { TransferMultipleAccountsPageComponent } from './pages/transfer-multiple-accounts-page/transfer-multiple-accounts-page.component';
import { TransferArenaCashSuccessPageComponent } from './pages/transfer-arena-cash-success-page/transfer-arena-cash-success-page.component';
// Club Pages
import { EarnPageComponent } from './pages/earn-page/earn-page.component';
import { GuardianPageComponent } from './pages/guardian-page/guardian-page.component';
import { LayoutModule } from './modules/layouts/layout.module';

// Points Management Page
import { PointsManagementPageComponent } from './pages/points-management-page/points-management-page.component';

// Partners Pages
import { PartnerPageComponent } from './pages/partner-page/partner-page.component';
import { LeaderboardPageComponent } from './pages/leaderboard-page/leaderboard-page.component';
import { LottosTermsPageComponent } from './pages/lottos-terms-page/lottos-terms-page.component';
import { MarketplaceTermsPageComponent } from './pages/marketplace-terms-page/marketplace-terms-page.component';
import { JokesPageComponent } from './pages/jokes-page/jokes-page.component';
import { WorksheetPageComponent } from './pages/worksheet-page/worksheet-page.component';
import { ZoomMeetingPageComponent } from './pages/zoom-meeting-page/zoom-meeting-page.component';
import { FantasyLeaderboardPageComponent } from './pages/fantasy-leaderboard-page/fantasy-leaderboard-page.component';
import { RenewalBonusTransferPageComponent } from './pages/renewal-bonus-transfer-page/renewal-bonus-transfer-page.component';
import { SubscriptionsPageComponent } from './pages/subscriptions-page/subscriptions-page.component';
import { CartCheckoutPagePortalProductsComponent } from './pages/cart-checkout-page-portal-products/cart-checkout-page-portal-products.component';
import { CartCheckoutPageComponent } from './pages/cart-checkout-page/cart-checkout-page.component';
import { CartCheckoutResultsPageComponent } from './pages/cart-checkout-page/cart-checkout-results-page/cart-checkout-results-page.component';
import { CartCheckoutSuccessPageComponent } from './pages/cart-checkout-page/cart-checkout-success-page/cart-checkout-success-page.component';
import { NotificationManagementPageComponent } from './pages/notification-management-page/notification-management-page.component';
import { TailgatePageComponent } from './pages/tailgate-page/tailgate-page.component';
import { OfferTermsModalComponent } from './modules/club/components/offers/offer-terms-modal/offer-terms-modal.component';
import { ScorePredictorPageComponent } from './pages/score-predictor-page/score-predictor-page.component';
import { ProductsPageComponent } from './pages/products-page/products-page.component';
import { PaymentPageComponent } from './pages/payment-page/payment-page.component';
import { BadgesPageComponent } from './pages/badges-page/badges-page.component';
import { CardLinkingPageComponent } from './pages/card-linking-page/card-linking-page.component';
import { CardLinkingSuccessPageComponent } from './pages/card-linking-success-page/card-linking-success-page.component';
import { CardLinkAffiliatesPageComponent } from './pages/card-link-affiliates-page/card-link-affiliates-page.component';
import { CardLinkActivatedOffersPageComponent } from './pages/card-link-activated-offers-page/card-link-activated-offers-page.component';
import { CardLinkOfferDetailsPageComponent } from './pages/card-link-offer-details-page/card-link-offer-details-page.component';
import { CardLinkAllOffersPageComponent } from './pages/card-link-all-offers-page/card-link-all-offers-page.component';
import { CardLinkModule } from '@fgb/portal-component-library/src/lib/cardlinking';
import { SharedSearchBarModule } from '@fgb/portal-component-library/src/lib/shared/shared-search-bar';
import { CountdownModule } from '@fgb/portal-component-library/src/lib/shared/countdown';
import { OfferSearchBarModule } from '@fgb/portal-component-library/src/lib/club/offer-search-bar';
import { AchievementsPageComponent } from './pages/achievements-page/achievements-page.component';
import { EditQuestionsModule } from '@fgb/portal-component-library/src/lib/user/edit-questions';
import { AnnouncementsModule } from '@fgb/portal-component-library/src/lib/club/announcements';
import { CampaignDetailsPageComponent } from './pages/campaign-details-page/campaign-details-page.component';
import { ScorecardListViewModule } from '@fgb/portal-component-library/src/lib/loyalty/scorecards/scorecard-list-view';
import { RegistrationLayoutPageComponent } from './modules/registration/pages/registration-layout-page/registration-layout-page.component';
import { mergeRegistrationRoutes } from '@fgb/portal-component-library/src/lib/registration';
import { WalletClaimedPageComponent } from './pages/wallet-claimed-page/wallet-claimed-page.component';
import { RewardsWalletRedeemedListModule } from '@fgb/portal-component-library/src/lib/loyalty/wallet/wallet-v2/rewards-wallet-redeemed-list';
import { LottosWalletDetailsModule } from '@fgb/portal-component-library/src/lib/loyalty/wallet/wallet-v2/lottos/lottos-wallet-details';
import { GalleryPageComponent } from './pages/gallery-page/gallery-page.component';
import { RegistrationSuccessPageComponent } from './modules/registration/pages/registration-success-page/registration-success-page.component';
import { RegistrationPaymentComponent } from './modules/registration/pages/registration-payment/registration-payment.component';
import { SelectCountryModule } from '@fgb/portal-component-library/src/lib/shared/select-country';

const tmSSOAuthConfig: tmOAuthConfig = {};

const tmLoginAuthConfig: tmAuthConfig = {
  clientId: '5a341b8e174d.fortress.ducks-ducks.us',
  authUri: 'https://auth.ticketmaster.com/as/authorization.oauth2',
  loginUri: 'https://am.ticketmaster.com/ducks/sso',
  visualPresents: 'ducks',
};

// TODO: Remove this temporary code when the child model is returned from the API.
const temporaryChildModel = [
  {
    "PropertyName": "Firstname",
    "PropertyTypeCode": PropertyTypeCode.String,
    "DisplayStatus": 2,
    "Validate": true,
    "ValidateExpression": "^[a-zA-Z ,.'-]+$", // manually added validate expression since it is not returned from the API
    "IsRequired": true,
    "Ordinal": 1
  },
  {
    "PropertyName": "Lastname",
    "PropertyTypeCode": PropertyTypeCode.String,
    "DisplayStatus": 2,
    "Validate": true,
    "ValidateExpression": "^[a-zA-Z ,.'-]+$", // manually added validate expression since it is not returned from the API
    "IsRequired": true,
    "Ordinal": 2
  },
  {
    "PropertyName": "DateOfBirth",
    "PropertyTypeCode": PropertyTypeCode.DateOfBirth,
    "DisplayStatus": 2,
    "Validate": false,
    "ValidateExpression": null,
    "IsRequired": true,
    "Ordinal": 3
  }
];

const registrationConfig: Partial<RegistrationRoute> = {
  [RegistrationRouteType.AddChild]: {
    data: {
      childModel: temporaryChildModel
    }
  },
  [RegistrationRouteType.Success]: {
    component: RegistrationSuccessPageComponent
  }
};

@NgModule({
  declarations: [
    // General Pages
    AppComponent,
    MasterPageComponent,
    HomePageComponent,
    LoginPageComponent,
    LoginAccountsPageComponent,
    MaintenancePageComponent,
    TermsPageComponent,
    FaqPageComponent,
    ContactUsPageComponent,
    ReferAFriendPageComponent,
    ReferAFriendSuccessPageComponent,
    NotFoundPageComponent,
    // Loyalty Pages
    RewardsLayoutComponent,
    RewardsPageComponent,
    AuctionsPageComponent,
    LottosPageComponent,
    MarketplacePageComponent,
    EventsPageComponent,
    WalletPageComponent,
    AuctionDetailPageComponent,
    LottoDetailPageComponent,
    MarketplaceDetailPageComponent,
    LottosSuccessPageComponent,
    MarketplacePurchaseSuccessPageComponent,
    AuctionBidsPageComponent,
    AuctionBidDetailsPageComponent,
    LottoWalletDetailsPageComponent,
    // Polls, Surveys & Trivia Pages (Games)
    GamesLayoutComponent,
    GamesPageComponent,
    PollsPageComponent,
    FantasyGamesPageComponent,
    FantasyPlayerSelectPageComponent,
    PredictorPageComponent,
    // Account Pages
    AccountDetailsPageComponent,
    ActivityPageComponent,
    PointsManagementPageComponent,
    // Ecash Pages
    EcashPageComponent,
    EcashResultPageComponent,
    EcashSuccessPageComponent,
    EcashFailurePageComponent,
    EcashHistoryPageComponent,
    PaymentPageComponent,
    PortalProductResultsPageComponent,
    PortalProductSuccessPageComponent,
    PortalProductFailurePageComponent,
    // Tickets Pages
    TicketsPageComponent,
    ManageTicketsPageComponent,
    TicketTransferPageComponent,
    TransferConfirmPageComponent,
    TicketTransferSuccessPageComponent,
    ContactsPageComponent,
    ManageTicketHistoryPageComponent,
    // Offer Pages
    OffersPageComponent,
    OfferDetailsPageComponent,
    // Population Pages
    TestPopHomePageComponent,
    // Guardians Pages
    GuardianPageComponent,
    // External Cash Transfers
    CardPageComponent,
    TransferArenaCashPageComponent,
    TransferMultipleAccountsPageComponent,
    TransferArenaCashSuccessPageComponent,
    // Club Pages
    EarnPageComponent,
    BenefitsPageComponent,
    QuizPageComponent,
    PartnerPageComponent,
    LeaderboardPageComponent,
    LottosTermsPageComponent,
    MarketplaceTermsPageComponent,
    JokesPageComponent,
    WorksheetPageComponent,
    ZoomMeetingPageComponent,
    FantasyLeaderboardPageComponent,
    RenewalBonusTransferPageComponent,
    SubscriptionsPageComponent,
    CartCheckoutPagePortalProductsComponent,
    CartCheckoutPageComponent,
    CartCheckoutSuccessPageComponent,
    CartCheckoutResultsPageComponent,
    NotificationManagementPageComponent,
    TailgatePageComponent,
    ScorePredictorPageComponent,
    ProductsPageComponent,
    BadgesPageComponent,
    CardLinkingPageComponent,
    CardLinkingSuccessPageComponent,
    CardLinkAffiliatesPageComponent,
    CardLinkActivatedOffersPageComponent,
    CardLinkOfferDetailsPageComponent,
    CardLinkAllOffersPageComponent,
    CardLinkActivatedOffersPageComponent,
    AchievementsPageComponent,
    CampaignDetailsPageComponent,
    RegistrationLayoutPageComponent,
    WalletClaimedPageComponent,
    GalleryPageComponent,
    RegistrationSuccessPageComponent,
    RegistrationPaymentComponent
  ],
  imports: [
    // External Modules
    PortalIdModule,
    RouterModule,
    CarouselModule,
    AppRoutingModule,
    FormsModule,
    NgbModule,
    BrowserModule,
    HttpClientModule,
    ReactiveFormsModule,
    InlineSVGModule.forRoot(),
    QRCodeModule,
    BrowserAnimationsModule,
    EarnPointsModule,
    FaqSectionModule,
    TermsAndConditionsModule,
    AuctionDetailsModule,
    AuctionPanelModule,
    AuctionListModule,
    LottoDetailsModule,
    LottosListModule,
    LottosListItemModule,
    ScorecardCarouselModule,
    BadgeListItemModule,
    BadgeOverviewListModule,
    BadgeFeaturedListModule,
    MarketplaceDetailsModule,
    MarketplaceListModule,
    MarketplaceListItemModule,
    MixedLoyaltyListModule,
    WalletRedeemListModule,
    WalletRedeemedListModule,
    BarcodeModule,
    LoadingModule,
    CountryAndRegionsModule,
    EcashDescriptionModule,
    CardTokenListModule,
    PortalLinkCardModule,
    TicketTransferWindowStatusModule,
    GamesListWithTicketsModule,
    TicketTransferGamePanelModule,
    CountdownModule,
    ContactsManagementModule,
    GameBannerModule,
    TicketListModule,
    TicketListItemModule,
    SelectedTicketsCountModule,
    SelectedTicketsTableModule,
    TransferFormModule,
    ErrorsModule,
    FeaturedRewardsModule,
    AddressBookModule,
    ToastrModule.forRoot({
      maxOpened: 5,
    }),
    JwtModule.forRoot({
      config: {
        allowedDomains: ['http://localhost/portal2/api'],
        tokenGetter: jwtTokenGetter,
      },
    }),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useClass: FGBTranslationLoader,
        deps: [HttpClient, AuthService, MemberQuery],
      },
      missingTranslationHandler: {
        provide: MissingTranslationHandler,
        useClass: FGBMissingTranslationHandler,
      },
    }),
    environment.production ? [] : AkitaNgDevtools.forRoot(),
    // Feature Modules
    LayoutModule,
    GuardianModule,
    PurchaseModule,
    EventsModule,
    ClubModule,
    EcashModule,
    PurchaseModule,
    VouchersModule,
    UserModule,
    SharedModule,
    FantasyModule,
    TicketsModule,
    LoyaltyModule,
    BenefitModule,
    PassesModule,
    DisplayModule,
    DirectivesModule,
    CardLinkModule,
    OfferSearchBarModule,
    SharedSearchBarModule,
    EditQuestionsModule,
    AnnouncementsModule,
    RewardsWalletRedeemListModule,
    ScorecardListViewModule,
    RewardsWalletRedeemedListModule,
    LottosWalletDetailsModule,
    SelectCountryModule
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenRefreshIntereptor,
      multi: true,
      deps: [AuthService],
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: FirstTimeRegistrationInterceptor,
      multi: true,
      deps: [MemberQuery, RegistrationStepService, LoggingService, Router],
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpErrorInterceptor,
      multi: true,
      deps: [ErrorStateService, LoggingService, TranslateService, Router, AuthService],
    },
    {
      provide: tmOAuthConfig,
      useValue: tmSSOAuthConfig,
    },
    {
      provide: tmAuthConfig,
      useValue: tmLoginAuthConfig,
    },
    {
      provide: REGISTRATION_ROUTES,
      useFactory: () => mergeRegistrationRoutes(registrationConfig)
    },
    {
      provide: DATE_OPTIONS,
      useValue: {
        ...DEFAULT_DATE_OPTIONS,
        LongDateFormat: 'MMM D, yyyy h:mmA',
      } as DateOptions,
    },
    TMOauthService,
    TMAuthService,
    AuthGuard,
    AuthService,
    PopulationGuard,
    ProductCodeGuard,
    MaintenanceGuard,
    DatePipe,
    CurrencyPipe,
    CookieService,
    { provide: NgbDateParserFormatter, useClass: MomentDateFormatter },
  ],
  bootstrap: [AppComponent],
  entryComponents: [
    BenefitInfoModalComponent,
    BenefitClaimConfirmationModalComponent,
    CollectAddressModal,
    MobilePassModalComponent,
    UpgradeChildrenComponent,
    OfferTermsModalComponent,
  ],
})
export class AppModule {}
