import { Component, OnInit } from '@angular/core';
import { PartnerQuery, Partner, PartnerService, PartnerPendingQuery, PartnerPendingService, PartnerPending } from '@fgb/core';
import { Observable } from 'rxjs';

@Component({
  selector: 'fgb-partner-list',
  templateUrl: './partner-list.component.html',
  styleUrls: ['./partner-list.component.scss'],
})
export class PartnerListComponent implements OnInit {
  constructor(
    private partnerQuery: PartnerQuery,
    private partnerService: PartnerService,
    private pendingQuery: PartnerPendingQuery,
    private pendingService: PartnerPendingService
  ) {}
  partners$: Observable<Partner[]>;
  pendingPartners$: Observable<PartnerPending[]>;

  ngOnInit() {
    this.partners$ = this.partnerQuery.selectAll();
    this.pendingPartners$ = this.pendingQuery.selectAll();
  }

  delete(id: number) {
    this.partnerService.remove(id).toPromise();
  }

  deletePending(ticketKey: string) {
    this.pendingService.remove(ticketKey).toPromise();
  }
}
