import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ChildQuery, ChildService, ChildViewModel } from '@fgb/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Observable, Subscription } from 'rxjs';
import { Populations } from 'src/app/shared/utilities/populations';

@Component({
  selector: 'fgb-guardian-page',
  templateUrl: './guardian-page.component.html',
  styleUrls: ['./guardian-page.component.scss'],
})
export class GuardianPageComponent implements OnInit {
  public childLimit: number = 10;
  public emptyChildren: any[];
  isGuardianSubscription: Subscription;
  isGuardian: boolean;

  children$: Observable<ChildViewModel[]>;
  freeChildren: ChildViewModel[];
  modal: any;
  populations = Populations;
  @ViewChild('guardianModal', { static: true }) guardianModalRef: ElementRef;

  constructor(private childQuery: ChildQuery, 
              private childService: ChildService,
              private modalService: NgbModal) {}

  ngOnInit() {
    this.childService.fetchGuardian().toPromise();
    this.isGuardianSubscription = this.childQuery.selectGuardian().subscribe(guardian => {
      this.isGuardian = !!guardian;
    });
  }

  ngOnDestroy(){
    if(this.isGuardianSubscription){
      this.isGuardianSubscription.unsubscribe();
    }
  }

  becomeGuardian(): void {
    this.childService
      .registerAsGuardian()
      .toPromise()
      .then(() => {
        this.isGuardian = true;
        this.modal.close();
      });
  }

  openGuardianModal(): void {
    this.modal = this.modalService.open(this.guardianModalRef, 
      { 
        centered: true,
        windowClass: 'guardian-modal' 
      });
  }
}
