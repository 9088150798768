<div class="survey-answer completed">
  <ng-container *notInPopulation="[ populations.Partial, populations.Default, populations.Kids ]">
    <div class="text-center font-weight-bold pb-3 font-size-16 text-white d-none d-lg-block" *ngIf="pointsWon > 0">
      +{{ pointsWon }} Pts
    </div>
  </ng-container>
  <div class="survey-answer-container py-3 mx-lg-5">
    <div class="col-12 p-0 my-2 my-lg-3"
         [ngClass]="{
          'col-lg-6': survey.AnswerDisplayType != answerDisplayType.Image,
          'col-lg-3': survey.AnswerDisplayType == answerDisplayType.Image
         }" *ngFor="let answer of survey.SurveyAnswers">
      <div class="survey-answer-item w-90"
        [ngClass]="{
          'portrait' : survey.AnswerDisplayType == answerDisplayType.Image
        }">
        <fgb-survey-item-answer-completed-poll-item
          [answer]="answer"
          [entryCount]="survey.SurveyEntryCount"
          [displayType]="survey.AnswerDisplayType"
        ></fgb-survey-item-answer-completed-poll-item>
      </div>
    </div>
  </div>
</div>
