<div class="score col-12 p-0">
  <div
    class="score-predictor-countdown"
    *ngIf="status === scorePredictorStatus.EntryOpen || status === scorePredictorStatus.Answered">
    <ng-container *ngIf="canEditAnswer">
      <span class="icon icon-clock pr-1 text-danger"></span>
      <fgbcl-countdown
        [endDateTime]="survey.SeparateEntryWindow ? survey.EntryEndDate : survey.EndDate"
        [isUTC]="true"
        class="countdown font-size-14 font-weight-bold text-danger"
      ></fgbcl-countdown>
    </ng-container>
  </div>
  <div
    class="d-flex flex-row justify-content-center mt-3 mt-lg-0"
    [ngClass]="{ 'default': status === scorePredictorStatus.ComingSoon }">
    <div class="px-3" *ngIf="status === scorePredictorStatus.ComingSoon">
      <!-- Home Image -->
      <ng-container *ngTemplateOutlet="imageTemplate; context: { $implicit: homeTeam, team: 'home' }"></ng-container>
    </div>
    <div class="align-self-center" [ngClass]="status === scorePredictorStatus.ComingSoon ? 'col-4 col-lg-3' : 'd-lg-none col-12'">
      <ng-container *ngTemplateOutlet="dateTime"></ng-container>
    </div>
    <div class="px-3" *ngIf="status === scorePredictorStatus.ComingSoon">
      <!-- Away Image -->
      <ng-container *ngTemplateOutlet="imageTemplate; context: { $implicit: awayTeam, team: 'away' }"></ng-container>
    </div>
  </div>
  <form
    *ngIf="status !== scorePredictorStatus.ComingSoon"
    class="row no-gutters justify-content-center"
    [ngClass]="
      status == scorePredictorStatus.EntryOpen
        ? 'py-3'
        : status === scorePredictorStatus.Answered && canEditAnswer
        ? 'align-with-submit-body'
        : 'default'
    "
    [formGroup]="scoreForm">
    <div class="d-flex align-items-center justify-content-center col-5">
      <!-- Home Image -->
      <ng-container *ngTemplateOutlet="imageTemplate; context: { $implicit: homeTeam, team: 'home' }"></ng-container>
      <!--Home Count-->
      <div class="d-inline-block px-2 pt-2" *ngIf="status !== scorePredictorStatus.ComingSoon">
        <fieldset [disabled]="status != scorePredictorStatus.EntryOpen">
          <fgb-number-increment-input
            formControlName="home"
            accessibilityLabel="home"
            wscPrefix="score.predictor.score"
            [ngClass]="[
              survey.ProcessStatus === 2 && hasSelectedCorrect
                ? 'text-success'
                : survey.ProcessStatus === 2 && !hasSelectedCorrect
                ? 'text-danger'
                : ''
            ]"
          >
          </fgb-number-increment-input>
        </fieldset>
      </div>
    </div>
    <div class="d-none d-lg-block col-2 align-self-center">
      <ng-container *ngTemplateOutlet="dateTime"></ng-container>
    </div>
    <div class="d-flex align-items-center justify-content-center col-5">
      <!--Away Count-->
      <div class="d-inline-block px-2 pt-2" *ngIf="status !== scorePredictorStatus.ComingSoon">
        <fieldset [disabled]="status != scorePredictorStatus.EntryOpen">
          <fgb-number-increment-input
            formControlName="away"
            accessibilityLabel="away"
            wscPrefix="score.predictor.score"
            [ngClass]="[
              survey.ProcessStatus === 2 && hasSelectedCorrect
                ? 'text-success'
                : survey.ProcessStatus === 2 && !hasSelectedCorrect
                ? 'text-danger'
                : ''
            ]"
          >
          </fgb-number-increment-input>
        </fieldset>
      </div>
      <!-- Away Image -->
      <ng-container *ngTemplateOutlet="imageTemplate; context: { $implicit: awayTeam, team: 'away' }"></ng-container>
    </div>
  </form>
</div>

<!--Entry Window Date-->
<ng-template #dateTime>
  <div
    class="text-center font-size-12 date-time"
    [ngClass]="
      status === scorePredictorStatus.Answered && canEditAnswer
        ? 'align-with-submit-time'
        : status === scorePredictorStatus.Answered || status === scorePredictorStatus.ComingSoon
        ? 'mt-2'
        : 'mt-2'
    ">
    {{
      survey.SeparateEntryWindow
        ? (survey.EntryEndDate | UtcToLocaltimePipe: 'MMM DD, HH:mm')
        : (survey.EndDate | UtcToLocaltimePipe: 'MMM DD, HH:mm')
    }}
  </div>
</ng-template>

<!-- Team Image Template -->
<ng-template #imageTemplate let-item let-team="team">
  <div
    class="team-{{ team }}-img-container"
    [ngClass]="{ 'px-2': status !== scorePredictorStatus.ComingSoon }">
    <ng-container *ngIf="survey.SurveyAnswers.length && survey.SurveyAnswers[item]">
      <img
        class="{{ team + '-team-image' }}"
        alt=""
        src="{{ survey.SurveyAnswers[item].ImageURL | contentImage: '7' }}"
        draggable="false"/>
  </ng-container>
</div>
</ng-template>
