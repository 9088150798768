<div class="full-page w-100 d-md-flex d-block px-0">
  <!--Left Side-->
  <img
    class="shared-login-banner d-md-block d-none"
    draggable="false"
    src="assets/images/login-registration/ducks-login-banner.png"
  />
  <div class="bg-primary d-flex w-100 d-md-none m-auto mobile-header"></div>
  <!--Right Side-->
  <div class="container right-side-login d-block align-items-center">
    <div class="login-container">
        <h5 class="my-lg-4 mb-3">{{ 'login.page.title' | translate }}</h5>
        <div class="panel-text mlb-text font-size-14" [innerHTML]="'login.page.description' | translate | markdownTranslate"></div>

        <fgb-tm-user-login class="pb-lg-5"></fgb-tm-user-login>

        <p class="font-size-14">
          {{ 'login.page.instruction' | translate : { fgbdefault: 'Click below to finish completing your Mighty Loyalty account.' } }}
        </p>

        <div class="mt-lg-4">
          <a [routerLink]="['/register/new-member']">
            <div
              class="btn btn-secondary btn-block create-account-button d-flex justify-content-center w-100 font-size-14 font-weight-bold"
            >
              <span class="font-weight-bold"> {{ 'login.page.create.account' | translate }} </span>
            </div>
          </a>
        </div>

        <div class="form-group mt-3 mt-lg-5">
          <a [routerLink]="['/register/partner']">
            <button
              class="btn btn-tertiary partner-btn d-flex align-items-center justify-content-center w-100 font-weight-bold font-size-14"
            >
              {{ 'login.page.partner.invitation.btn' | translate }}
            </button></a
          >
        </div>

        <div class="panel-text mb-1 font-weight-bold font-size-12 mt-lg-5">
          {{ 'login.page.no.account' | translate }}
        </div>

        <p class="mb-2 font-size-12">
          {{ 'login.page.create.account.description' | translate }}
        </p>
    </div>
  </div>
</div>
