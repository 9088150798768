import { Component, OnDestroy, OnInit } from '@angular/core';
import { ChildQuery, ChildService, ChildViewModel, PaymentOptionType } from '@fgb/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Observable, Subscription, combineLatest } from 'rxjs';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { UpgradeChildrenComponent } from '../upgrade-children/upgrade-children.component';
import { OwlCarouselSettings } from 'src/app/shared/utilities/owl-carousel-settings';

@Component({
  selector: 'fgb-child-list',
  templateUrl: 'child-list.component.html',
  styleUrls: ['child-list.component.scss'],
})
export class ChildListComponent implements OnInit, OnDestroy {
  public childLimit: number = 0;
  public emptyChildren: any[];
  private subscription: Subscription;

  children$: Observable<ChildViewModel[]>;
  freeChildren: ChildViewModel[] = [];

  customOptions: OwlOptions = {
    margin: OwlCarouselSettings.Margin,
    pullDrag: true,
    navSpeed: 700,
    navText: ['', ''],
    responsive: {
      0: { items: 1.2, autoWidth: true },
      400: { items: 1.2, autoWidth: true },
      740: { items: 3 },
    },
  };

  constructor(private childQuery: ChildQuery, private childService: ChildService, private modalService: NgbModal) {}

  ngOnInit() {
    this.childService.fetchChildren().toPromise();
    this.childService.fetchChildCountLimit().toPromise();
    this.children$ = this.childQuery.selectChildren();

    this.subscription = combineLatest([
      this.children$,
      this.childQuery.select(state => state.childLimit)
    ])
    .subscribe(([ children, childcountlimit ]) => {
      this.childLimit = childcountlimit || this.childLimit;
      this.emptyChildren = [];
      const len = this.childLimit - children.length;

      if( len > 0 ){
        this.emptyChildren = new Array(len);
      }
      
      this.freeChildren = children.filter((y) => y.MembershipOption.PaymentType === PaymentOptionType.Free);
    });
    
  }

  ngOnDestroy(): void {
    if( this.subscription ){
      this.subscription.unsubscribe();
    }
  }

  upgradeChildren() {
    const modal = this.modalService.open(UpgradeChildrenComponent, {
      ariaLabelledBy: 'modal-basic-title',
      windowClass: 'upgrade-children-modal',
      size: 'lg',
    });
    modal.componentInstance.children = this.freeChildren;
  }

}
