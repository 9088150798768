import { AfterViewInit, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder, Validators, ValidationErrors, AbstractControl, ValidatorFn } from '@angular/forms';
import { EMPTY, from, fromEvent, merge, Observable, Subject } from 'rxjs';
import { ContactService, Contact } from '@fgb/core';
import { debounceTime, exhaustMap, map, mapTo, repeat, take, takeUntil, tap } from 'rxjs/operators';
import { FGBValidators } from '@fgb/portal-component-library/src/lib/shared/validators';

@Component({
  selector: 'fgb-contact-us-page',
  templateUrl: './contact-us-page.component.html',
  styleUrls: ['./contact-us-page.component.scss'],
})
export class ContactUsPageComponent implements OnInit, AfterViewInit {
  contactUsForm: FormGroup;
  categories$: Observable<string[]>;
  sentSuccessfully$: Subject<any> = new Subject();
  showSuccessMessage$: Observable<boolean>;
  submitBtn$: Observable<any>;
  selectedCategory: string;

  @ViewChild('submitBtn') submitBtn: ElementRef<HTMLButtonElement>;

  constructor(private formBuilder: FormBuilder, 
              private contactService: ContactService) {}

  ngOnInit(): void {

    this.contactUsForm = this.formBuilder.group({
      category: [''],
      message: ['', [ Validators.required, FGBValidators.Empty, htmlCodeValidator() ] ],
    });

    this.categories$ = this.contactService.getCategories().pipe(
      take(1),
      map((categories) => categories.map((cat) => cat.Value)),
      tap((categories) => this.contactUsForm.get('category')?.patchValue((this.selectedCategory = categories[0])))
    );
    this.showSuccessMessage$ = merge(this.contactUsForm.valueChanges.pipe(mapTo(false)), this.sentSuccessfully$);
  }

  ngAfterViewInit(): void {
    this.submitBtn$ = fromEvent(this.submitBtn.nativeElement, 'click');
    this.submitBtn$
      .pipe(
        // Prevent from clicking the button multiple times and spamming the server
        debounceTime(1000),
        exhaustMap(() => {
          this.contactUsForm.markAllAsTouched();

          if (this.contactUsForm.valid) {
            const value: Contact = this.contactUsForm.value;
            return from(this.contactService.sendMessage(value)).pipe(
              tap(() => {
                // Clear message after successfully sent
                this.contactUsForm.get('message')?.patchValue('');
                this.contactUsForm.get('message')?.markAsUntouched();
                this.contactUsForm.get('category')?.patchValue(this.selectedCategory);
                this.sentSuccessfully$.next(true);
              })
            );
          }

          this.sentSuccessfully$.next(false);
          return EMPTY;
        }),
        takeUntil(this.contactUsForm.valueChanges),
        repeat()
      )
      .subscribe();
  }
  
}

export function htmlCodeValidator(): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const found = /<.*?>|<\/.*?>/g.test(control.value);
    return found ? { htmlError: true } : null;
  };
}
