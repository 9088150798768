import { Component, OnInit } from '@angular/core';
import { CampaignEntitlements, CampaignOffers, CampaignOffersQuery, CampaignOffersService } from '@fgb/core';
import { OwlCarouselSettings } from '@fgb/portal-component-library/src/lib/shared/utilities';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { Observable } from 'rxjs';

@Component({
  selector: 'fgb-campaigns-offers-list',
  templateUrl: './campaigns-offers-list.component.html',
  styleUrls: ['./campaigns-offers-list.component.scss'],
})
export class CampaignsOffersListComponent implements OnInit {
  campaignOffers$: Observable<CampaignOffers | undefined>;
  isDragging: boolean = false;
  customOptions: OwlOptions = {
    margin: 8,
    autoWidth: true,
    pullDrag: true,
    autoplay: false,
    autoplayTimeout: OwlCarouselSettings.AutoplayTimeout,
    autoplaySpeed: OwlCarouselSettings.AutoplaySpeed,
    navSpeed: 700,
    nav: false,
    responsive: {
      0: { items: 2 },
      900: { items: 4 },
    },
  };
  constructor(private campaignOffersService: CampaignOffersService, private campaignOffersQuery: CampaignOffersQuery) {}

  ngOnInit() {
    this.campaignOffersService.fetchOffers().toPromise();
    this.campaignOffers$ = this.campaignOffersQuery.selectFirst();
  }

  navigateToCampaignOffer(item: CampaignEntitlements) {
    return `/campaign/details/${item.CampaignID}`;
  }
}
