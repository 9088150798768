<div class="partners">
  <div class="banner" role="banner">
  </div>
  
  <fgb-account-nav></fgb-account-nav>
  <div class="container mt-4" *ngIf="partnerLimit$ | async as partnerLimit">
    <div class="card mb-2 mt-2 mt-lg-0">
      <div class="row m-0 partner-card">
        <ng-container *inPopulation="[ populations.Orange ]">
          <p class="text-black" [innerHTML]="'partner.page.new.partner.instruction' | translate | markdownTranslate"></p>
            
          <button
              class="partner-button border-0 text-center text-white pt-0"
              (click)="toggleAddForm()"
              *ngIf="currentPartnerCount < partnerLimit"
            >
            <i class="icon material-icons d-block mx-auto mt-1">add_circle</i>
            <label class="mt-1">{{ 'partner.page.add.new.partner.button' | translate : { fgbdefault: 'Add New Partner' } }}</label>
          </button>
        </ng-container>

        <ng-container *inPopulation="[ populations.Default, populations.Partial ]">
          <p class="text-black" [innerHTML]="'partner.page.accept.partner.instruction' | translate | markdownTranslate"></p>
          
            <button
                class="partner-button border-0 text-center text-white pt-0"
                (click)="toggleAcceptForm()"
                *ngIf="currentPartnerCount < partnerLimit"
              >
                <i class="icon material-icons d-block mx-auto mt-1">add_circle</i>
                <label class="mt-1">{{ 'partner.page.accept.partner.button' | translate: { fgbdefault: 'Accept Partner'} }}</label>
            </button>

        </ng-container>

        <ng-container *inPopulation="[ populations.Partner ]">
          <p class="text-black" [innerHTML]="'partner.page.partner.instruction' | translate | markdownTranslate"></p>
        </ng-container>
      </div>
      <ng-container *ngIf="showAddForm || showAcceptForm">
        <hr class="mt-4 mt-lg-0"/>
        <div class="card-body">
          <fgb-partner-add-form (close)="toggleAddForm()" *ngIf="showAddForm"></fgb-partner-add-form>
          <fgb-partner-accept-form (close)="toggleAcceptForm()" *ngIf="showAcceptForm"></fgb-partner-accept-form>
        </div>
      </ng-container>
    </div>
    <fgbcl-errors></fgbcl-errors>
    <ng-container *ngIf="partners.length > 0 || pendingPartners.length > 0; else partnerPlaceholder">
      <fgb-partner-list></fgb-partner-list>
    </ng-container>
    <ng-template #partnerPlaceholder>
      <div class="d-flex no-partners justify-content-center flex-column align-items-center mt-4">
        <i class="material-icons text-grey no-partner-icon">people</i>
        <div class="no-partner-text text-center mt-2">
          <h5>{{ 'partner.page.no.partner' | translate : { fgbdefault: 'No Partners' } }}</h5>
          <ng-container *notInPopulation="[ populations.Default, populations.Partner, populations.Partial ]">
            <div class="text-info partner-instruction instruction font-size-12">
              {{ 'partner.page.no.partner.instruction' | translate : { fgbdefault: 'To add your first partner please click on add new partner and insert your partners details.' } }}  
            </div>
          </ng-container>
          <ng-container *inPopulation="[ populations.Default, populations.Partial ]">
            <div class="text-info partner-instruction instruction font-size-12">
              {{ 'partner.page.no.partner.instruction' | translate : { fgbdefault: 'To accept your first partner please click on Accept Partner and insert your details.' } }}  
            </div>
          </ng-container>
          <ng-container *inPopulation="[ populations.Partner ]">
            <div class="text-info partner-instruction instruction font-size-12">
              {{ 'partner.page.no.partner.partner.instruction' | translate }}  
            </div>
          </ng-container>
        </div>
      </div>
    </ng-template>
  </div>
  
</div>