<div class="banner d-flex">
  <div class="banner-logo"></div>
</div>

<!--Barcode-->
<div class="d-flex" *ifScreenSize="screenType.Mobile">
  <div class="container barcode-container my-4">
    <fgb-barcode></fgb-barcode>
  </div>
</div>

<fgb-game-nav></fgb-game-nav>

<div class="container text-center mt-3">
  <fgb-worksheet-list></fgb-worksheet-list>
</div>
