<div class="d-none d-lg-block">
  <div class="banner" role="banner">
  </div>
  <fgb-mixed-loyalty-nav></fgb-mixed-loyalty-nav>
</div>

<!-- Points Summary -->
<div class="container">
  <fgb-points-summary-bar
    [backButtonLink]="'/rewards/marketplace'"
    [marketplaceItemGroup]="marketplaceItemGroup"
  ></fgb-points-summary-bar>
</div>

<fgbcl-marketplace-details [itemId]="id" [actionLabels]="actionLabels"></fgbcl-marketplace-details>

<div class="container">
  <div class="text-quaternary pt-3 cursor-pointer d-none d-lg-flex w-20 align-items-center" [routerLink]="'/rewards/marketplace'">
    <i class="icon material-icons">chevron_left</i>
    <ng-container *notInPopulation="[ populations.Kids ]">
      <span class="font-size-12 font-weight-bold">{{ 'back.to.rewards' | translate }}</span>
    </ng-container>
    <ng-container *inPopulation="[ populations.Kids ]">
      <span class="font-size-12 font-weight-bold">{{ 'back.to.rewards.kids' | translate }}</span>
    </ng-container>
  </div>
</div>
