import { Component, OnInit, Input } from '@angular/core';
import { AuctionBidQuery, AuctionBidDetails } from '@fgb/core';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

@Component({
  selector: 'fgb-auction-bid-details',
  templateUrl: './auction-bid-details.component.html',
  styleUrls: ['./auction-bid-details.component.scss'],
})
export class AuctionBidDetailsComponent implements OnInit {
  constructor(private auctionBidQuery: AuctionBidQuery) {}

  @Input() id: number;
  auctionBid$: Observable<AuctionBidDetails | undefined>;

  readMoreShowing: boolean = false;
  shouldShowReadMore: boolean = true;
  private readMoreDescriptionLength: number = 300;

  ngOnInit() {
    this.auctionBid$ = this.auctionBidQuery.selectBid(this.id).pipe(
      tap((item) => {
        if (item && item.ItemDescription.length > this.readMoreDescriptionLength) {
          this.shouldShowReadMore = true;
        } else {
          this.shouldShowReadMore = false;
        }
      })
    );
  }

  showReadMore = () => (this.readMoreShowing = true);
  hideReadMore = () => (this.readMoreShowing = false);
}
