<div class="card border-0 shadow voucher-box">
  <div class="card-content px-3">
    <h3 class="mb-lg-0 text-center">{{ 'promo.code.title' | translate }}</h3>
    <div>
      <span class="input-group mt-lg-1">
        <input
          type="text"
          [(ngModel)]="voucherCode"
          placeholder="Enter Your Promo Code Here"
          class="form-control text-center border-0 px-5 px-lg-0"
          (keyup.enter)="redeemVoucher()"
        />
        <div class="input-group-append">
          <button
            class="btn btn-secondary icon icon-arrow-1-right text-white font-weight-bold py-0"
            type="button"
            (click)="redeemVoucher()"
            [disabled]="disableButton"
          ></button>
        </div>
      </span>
      <div class="result-box pt-1 text-center">
        <div class="result mt-0">
          <fgbcl-errors></fgbcl-errors>
          <div class="font-size-12"
               [ngClass]="{
                'empty': isEmpty,
                'success': sucessfulRedemption,
                'error': !sucessfulRedemption
               }"
                *ngIf="redemptionResult">
            {{ redemptionResult }}
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
