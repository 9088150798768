import { Component, OnInit } from '@angular/core';
import { WizardType, RegistrationStepQuery } from '@fgb/core';

@Component({
  selector: 'fgb-registration-success-page',
  templateUrl: './registration-success-page.component.html',
  styleUrls: ['./registration-success-page.component.scss']
})
export class RegistrationSuccessPageComponent implements OnInit {
  wizardType?: WizardType;
  wizardTypes = WizardType;
  url: string;

  constructor(private registrationStepQuery: RegistrationStepQuery) {}

  ngOnInit() {
    this.wizardType = this.registrationStepQuery.getWizardType();

    let redirectToUrlAfterRegistration = sessionStorage.getItem('redirectToUrlAfterRegistration');
    if (redirectToUrlAfterRegistration) {
      this.url = redirectToUrlAfterRegistration;
    }
  }
}
