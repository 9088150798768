<div class="banner" role="banner">
  <h1>Ticket Forwarding</h1>
</div>

<div class="container mt-4">
  <div class="row">
    <div class="col-12">
      <button [routerLink]="['/contacts']" class="btn btn-danger">
        <span class="icon icon-account-book-1" aria-hidden="true"></span>
        MANAGE MY CONTACTS
      </button>
      <div class="col-12">
        <h4 class="games-title mt-3 mb-4 text-primary">Home Game</h4>
      </div>
    </div>
  </div>
  <fgbcl-games-list-with-tickets></fgbcl-games-list-with-tickets>

  <div class="col-12">
    <h4 class="games-title mt-3 mb-4 text-primary">Past Home Games</h4>
  </div>
  <fgbcl-games-list-with-tickets [showPastGamesOnly]="true"></fgbcl-games-list-with-tickets>
</div>
