<fgb-navigation-indicator></fgb-navigation-indicator>
<div class="PopluationId-{{ populationId }}">
  <fgb-mobile-nav-menu *ifScreenSize="screenType.Mobile"></fgb-mobile-nav-menu>
  <fgb-nav-bar *ifScreenSize="screenType.Desktop"></fgb-nav-bar>
</div>

<div class="PopluationId-{{ populationId }}">
  <div id="main" role="main">
    <router-outlet></router-outlet>
  </div>
</div>

<div class="PopluationId-{{ populationId }}">
  <fgb-footer></fgb-footer>
</div>
