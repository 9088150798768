<div class="banner" role="banner">
  <h1>{{ 'pick.player.leaderboard.page.title' | translate }}</h1>
</div>

<div class="container">
  <div class="leaderboard-back-btn font-weight-bold d-flex d-lg-none my-2">
    <a
      [routerLink]="[backButtonLink]"
      class="btn btn-primary flex-grow-0 flex-shrink-0 d-flex align-items-center justify-content-center h-100 pr-2 px-3 py-2 font-weight-bold mr-2"
    >
      <span class="icon-arrow-1-left font-weight-bold"></span>
    </a>

    <div class="d-flex align-items-center justify-content-center rounded border border-black w-100">
      <div class="text-primary font-weight-bold pt-1">{{ 'pick.player.leaderboard.title' | translate }}</div>
    </div>
  </div>
  <div class="card mt-3 rounded border-0 shadow">
    <div class="nav d-flex align-items-center justify-content-center">
      <a class="nav-item w-25 text-center" [ngClass]="{ selected: showMonthly }" (click)="showMonthly = true">
        <label class="font-size-12 font-weight-bold text-center">{{ 'pick.player.leaderboard.monthly' | translate }}</label>
      </a>
      <a class="nav-item w-25 text-center" [ngClass]="{ selected: !showMonthly }" (click)="showMonthly = false">
        <label class="font-size-12 font-weight-bold text-center">{{ 'pick.player.leaderboard.yearly' | translate }}</label>
      </a>
    </div>
    <div class="leaderboard-nav" *ngIf="showMonthly; else yearly">
      <owl-carousel-o [options]="customOptions" #monthCarousel>
        <ng-template carouselSlide *ngFor="let item of monthlyLeaderboard; let i = index" [id]="i.toString()">
          <div class="py-2 bg-muted leaderboard-date-toggle">
            <h2 class="text-center font-weight-bold font-size-14 mb-0">{{ item.month }}</h2>
          </div>
          <fgb-fantasy-leaderboard [fantasyEntries]="item.entries"></fgb-fantasy-leaderboard>
        </ng-template>
      </owl-carousel-o>
    </div>
    <ng-template #yearly>
      <div class="py-2 yearly-header bg-muted">
        <h2 class="text-center font-weight-bold font-size-14 mb-0">{{ year }}</h2>
      </div>
      <fgb-fantasy-leaderboard [fantasyEntries]="yearlyLeaderboards"></fgb-fantasy-leaderboard>
    </ng-template>
  </div>
  <div class="d-none d-lg-block">
    <a [routerLink]="[backButtonLink]" class="btn d-flex align-items-center font-weight-bold px-0 mt-2">
      <span class="pr-2 icon-arrow-1-left text-primary font-weight-bold"></span>
      <span class="font-weight-bold font-size-14 text-primary">{{ 'pick.player.back.button' | translate }}</span>
    </a>
  </div>
</div>
