import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { PartnerPending, PartnerPendingQuery, PartnerPendingService } from '@fgb/core';
import { FGBValidators } from '@fgb/portal-component-library/src/lib/shared/validators';
import { Observable, Subscription } from 'rxjs';
import { Regex } from 'src/app/shared/utilities/regex';
@Component({
  selector: 'fgb-partner-add-form',
  templateUrl: './partner-add-form.component.html',
  styleUrls: ['./partner-add-form.component.scss'],
})
export class PartnerAddFormComponent implements OnInit {
  constructor(
    private fb: FormBuilder,
    private partnerPendingService: PartnerPendingService,
    private pendingQuery: PartnerPendingQuery
  ) {}
  @Output() close = new EventEmitter();
  addForm: FormGroup;
  pendingPartners$: Observable<PartnerPending[]>;
  pendingPartnerEmails: string[] = [];
  pendingPartnerSub: Subscription;
  errorText: string = '';

  ngOnInit() {
    this.addForm = this.fb.group({
      fullName: ['', [ Validators.required, FGBValidators.Empty ]],
      email: ['', [Validators.pattern(Regex.email), Validators.required]],
    });

    this.pendingPartnerSub = this.pendingQuery.selectAll().subscribe((pendingPartners) => {
      pendingPartners.forEach((pendingPatner) => {
        this.pendingPartnerEmails.push(pendingPatner.Email.toLowerCase());
      });
    });
  }

  ngOnDestroy() {
    if (this.pendingPartnerSub) {
      this.pendingPartnerSub.unsubscribe();
    }
  }

  submit() {
    this.addForm.markAllAsTouched();
    this.errorText = '';
    let pendingExists = false;
    this.pendingPartnerEmails.forEach((pendingEmail) => {
      if (this.addForm.controls.email.value.toLowerCase() === pendingEmail) {
        pendingExists = true;
        return;
      }
    });

    if (pendingExists) {
      this.errorText = 'This email is already used for an existing pending partner';
    } else {
      const fullName: string = this.addForm.value.fullName.trim();
      const email: string = this.addForm.value.email.trim();

      if( this.addForm.valid ){

        this.partnerPendingService
          .add(fullName, email, '')
          .toPromise()
          .then(() => {
            this.close.emit();
          })
          .catch((e) => {
            this.errorText = e.error && !!e.error.Message ? e.error.Message : e.message;
          });
      }
    }
  }
}
