import { Component, OnInit, EventEmitter, Output } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { AuthService, PartnerService } from '@fgb/core';
import { FGBValidators } from '@fgb/portal-component-library';

@Component({
  selector: 'fgb-partner-accept-form',
  templateUrl: './partner-accept-form.component.html',
  styleUrls: ['./partner-accept-form.component.scss'],
})
export class PartnerAcceptFormComponent implements OnInit {  
  acceptForm: FormGroup;
  errorText: string = '';
  @Output() close = new EventEmitter();

  constructor(private fb: FormBuilder, 
              private partnerService: PartnerService,
              private authService: AuthService) {}


  ngOnInit() {
    this.acceptForm = this.fb.group({
      code: ['', [ Validators.required, FGBValidators.Empty ]],
      secretCode: ['', [Validators.required, FGBValidators.Empty ]],
    });
  }

  submit() {
    this.errorText = '';

    if( this.acceptForm.valid ){
      const code: string = this.acceptForm.value.code.trim();
      const secretCode: string = this.acceptForm.value.secretCode.trim();

      this.partnerService
      .confirmPartner(code, secretCode)
      .toPromise()
      .then(() => {
        this.close.emit();
        this.authService.refreshAccessToken().toPromise().then(() => {
          // reload to refresh theme and data
          location.reload();
        });
      })
      .catch((e) => {
        this.errorText = e.error && !!e.error.Message ? e.error.Message : e.message;
      });
    }
  }
}
