<div class="card child-panel rounded text-on-primary shadow">
  <div class="barcode-container" *ifScreenSize="screenType.Mobile">
    <qrcode [qrdata]="child.CardBarcode" [size]="256" [level]="'M'" [usesvg]="true"></qrcode>
  </div>
  <div class="card-body p-3 bg-primary">
    <div class="d-flex align-items-center">
      <div class="d-inline-block pr-2">
        <div class="svg-user svg activity font-weight-bold" [inlineSVG]="'assets/images/icons/account-icon.svg'"></div>
      </div>
      <div class="child-panel d-inline-block text-white">
        <div class="m-0 font-weight-bold font-size-14">{{ child.Firstname }} {{ child.Lastname }}</div>
        <p class="m-0 font-size-12">
          {{ 'child.panel.kid.wild.wingers' | translate : { fgbdefault: 'Wild Wingers' } }}
          <span class="member-id">- {{ child.MemberId }}</span>
        </p>
      </div>
    </div>
    <div class="date-of-birth-wrapper mt-5">
      <label class="mb-1 text-white">{{ 'child.panel.dob.label' | translate : { fgbdefault: 'Date of Birth' } }}</label>
      <div class="d-flex">
        <span class="icon text-white icon-calendar mr-1"></span>
        <span class="font-size-14 text-white">
          {{ child.DateOfBirth | date }}
        </span>
      </div>
    </div>
  </div>
  <div class="d-flex justify-content-center p-2 shadow login-panel">
    <button type="button" (click)="loginToChild()" class="btn login-btn my-1 btn-quaternary font-weight-bold font-weight-bold text-white">
      {{ 'child.panel.login.button' | translate : { fgbdefault: 'Login' } }}
    </button>
  </div>
  
  <!-- <div class="d-flex d-lg-none justify-content-between p-2 shadow bg-white rounded-bottom login-kids-panel">
    <button
      type="button"
      (click)="loginToChild()"
      class="btn btn-quaternary mobile-child-login-btn font-weight-bold text-white font-size-14"
    >
      Login
    </button>
  
    <button
      type="button"
      (click)="openChildBarcode()"
      class="btn btn-quaternary mobile-child-login-btn font-weight-bold text-white font-size-14"
    >
      Show Barcode
    </button>
  </div> -->
</div>

