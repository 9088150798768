<div class="partner-list">
  <h2 class="mt-4 mb-3">{{ 'partner.list.title' | translate: { fgbdefault: 'Partner' } }}</h2>

  <div class="card border-0 shadow card-primary my-2 bg-white">
    <div class="card-body pt-1 pb-2">
      <div class="row text-left pt-1">
        <div class="col-lg-3 d-lg-block d-none">
          <label class="font-weight-bold">{{ 'partner.list.fullname.label' | translate : { fgbdefault: 'Full Name' } }}</label>
        </div>
        <div class="col-8 d-lg-none d-block">
          <label class="font-weight-bold">{{ 'partner.list.peronal.details.label' | translate : { fgbdefault: 'Partner Details' } }}</label>
        </div>
        <div class="col-4 d-lg-block d-none">
          <label class="font-weight-bold">{{ 'partner.list.email.label' | translate : { fgbdefault: 'Email' } }}</label>
        </div>
        <div class="col-3 d-lg-block d-none">
          <label class="font-weight-bold">{{ 'partner.list.status.label' | translate: { fgbdefault: 'Status' } }}</label>
        </div>
        <div class="col-4 d-lg-none d-block text-right">
          <label class="font-weight-bold mr-2 mr-lg-0">{{ 'partner.list.action.label' | translate: { fgbdefault: 'Action' } }}</label>
        </div>
        <div class="col-2 d-lg-block d-none">
          <label class="font-weight-bold">{{ 'partner.list.action.label' | translate: { fgbdefault: 'Action' } }}</label>
        </div>
      </div>
      <hr class="m-0" />
      <div *ngFor="let partner of partners$ | async" class="row text-left align-items-lg-center mb-lg-0 mb-2 mt-3 mt-lg-0">
        <div class="col-lg-3 col-10 partner-info">
          <div>{{ partner.FullName }}</div>
          <div class="d-flex d-lg-none flex-column">
            <div class="text-primary">{{ partner.Email }}</div>
            <div class="text-success font-weight-bold">{{ 'partner.list.partner.label' | translate : { fgbdefault: 'Partner' } }}</div>
          </div>
        </div>
        <div class="col-4 d-lg-block d-none partner-info">
          <div class="text-primary">{{ partner.Email }}</div>
        </div>
        <div class="col-3 d-lg-block d-none partner-info">
          <div class="text-success font-weight-bold">{{ 'partner.list.partner.label' | translate : { fgbdefault: 'Partner' } }}</div>
        </div>
        <div class="col-2 px-1">
          <button
            class="btn text-grey delete-btn"
            confirmModal
            [confirmContent]="confirmContent"
            [confirmContext]="partner"
            (confirm)="delete(partner.RelationshipId)"
            aria-label="Delete partner"
          >
          <span class="icon material-icons"> delete_forever </span>
          </button>
        </div>
      </div>

      <div *ngFor="let partner of pendingPartners$ | async" class="row text-left align-items-lg-center mb-lg-0 mb-2 mt-3 mt-lg-0">
        <div class="col-lg-3 col-10 partner-info">
          <div>{{ partner.FullName }}</div>
          <div class="d-flex d-lg-none flex-column">
            <div class="text-grey">{{ partner.Email }}</div>
            <div class="text-primary font-weight-bold">{{ 'partner.list.pending.label' | translate : { fgbdefault: 'Pending' } }}</div>
          </div>
        </div>
        <div class="col-4 d-lg-block d-none partner-info">
          <div class="text-grey">{{ partner.Email }}</div>
        </div>
        <div class="col-3 d-lg-block d-none partner-info">
          <div class="text-primary font-weight-bold">{{ 'partner.list.pending.label' | translate : { fgbdefault: 'Pending' } }}</div>
        </div>
        <div class="col-2 btn-wrapper">
          <button
            class="btn text-grey delete-btn"
            confirmModal
            [confirmContent]="confirmContent"
            [confirmContext]="partner"
            (confirm)="deletePending(partner.TicketKey)"
            aria-label="Delete partner"
          >
          <span class="icon material-icons"> delete_forever </span>
          </button>
        </div>
      </div>
      <ng-template #confirmContent let-partner>
        <div class="text-center">
          <b>{{ 'partner.list.partner.delete.prompt' | translate : { fullName: partner.FullName } }}</b>
        </div>
      </ng-template>
    </div>
  </div>

</div>