<div class="banner d-flex">
  <div class="banner-logo"></div>
</div>

<fgb-mixed-loyalty-nav></fgb-mixed-loyalty-nav>

<div class="container mt-3">
  <fgbcl-marketplace-list 
      [isList]="true" 
      [marketplaceItemTypes]="[3, 5]" 
      [eventItemView]="true"
      [defaultSort]="0"></fgbcl-marketplace-list>
</div>
