<!--Barcode-->
<div class="d-flex" *ifScreenSize="screenType.Mobile">
  <div class="container barcode-container my-4">
    <fgb-barcode></fgb-barcode>
  </div>
</div>

<!--Vouchers-->
<ng-container *inPopulation="[ populations.Default ]">
  <div class="d-flex" *ifScreenSize="screenType.Mobile">
    <div class="container my-4">
      <fgb-vouchers></fgb-vouchers>
    </div>
  </div>
</ng-container>

<div class="mt-lg-0 mt-2 game-nav-wrapper">
  <fgb-game-nav></fgb-game-nav>
</div>

<div class="container mt-4">
  <ng-container *ngIf="quizzes$ | async as quizzes">
    <div *ngIf="!quizzes.length">
      <div class="no-items w-100 text-center">
        <div class="icon material-icons-two-tone no-results-placeholder-icon text-center" aria-hidden="true">live_help</div>
        <div class="no-results-placeholder-title font-weight-bold text-primary text-uppercase">
          {{ 'trivia.placeholder.title' | translate }}
        </div>
        <div class="no-results-placeholder-text text-info mb-5">{{ 'trivia.placeholder.description' | translate }}</div>
      </div>
    </div>
    <div *ngFor="let quiz of quizzes">
      <fgb-survey-item [survey]="quiz"></fgb-survey-item>
    </div>
  </ng-container>
</div>

