<ng-container *ngIf="children$ | async as children">
  <div class="mb-3">
    <div class="card bg-primary shadow text-white">
      <div class="card-body py-2 px-3">
        <div class="row">
          <div class="col-12 col-lg-6">
            <div class="kids-text">
              <h3 class="heading-text mt-2 d-flex align-items-center">
                {{ 'child.list.kids.title' | translate : { fgbdefault: 'Kids you have added' } }} <span class="length-num ml-4 text-primary">{{ children.length }}/{{ childLimit }}</span>
              </h3>
            </div>
          </div>
          <div class="col-12 col-lg-6 align-items-center d-flex justify-content-lg-end justify-content-center my-2 my-lg-0">
            
            <a class="btn btn-primary add-btn" [routerLink]="['/register/newchildren']" *ngIf="children.length < childLimit">
              <i class="material-icons add-btn-icon">add_circle</i>
              <div class="text-white font-size-14 font-weight-bold">{{ 'child.list.add.kid.button' | translate : { fgbdefault: 'Add New Kid' } }}</div>
            </a>
            <!-- TODO: No xd for upgrade children, remove comment if upgrade is included -->
              <!-- <div *ngIf="freeChildren.length > 0">
                <div class="icons-and-buttons ml-5">
                  <img src="assets/images/kids/upgrade-kid.svg" class="button-icon" (click)="upgradeChildren()" />
                  <div class="text-white font-size-14 font-weight-bold p-0 mt-1 text-uppercase" (click)="upgradeChildren()">
                    Upgrade Child
                  </div>
                </div>
              </div> -->
          </div>
        </div>
      </div>
    </div>
  </div>
  
  <ng-container *ngIf="children?.length > 0">
    <ng-container *ngTemplateOutlet="childrenTemplate"></ng-container>
  </ng-container>
  
  <!-- Children Template -->

  <ng-template #childrenTemplate>
    <div class="child-list">
      <owl-carousel-o [options]="customOptions">
        <ng-template *ngFor="let child of children" carouselSlide>
          <div class="mb-1">
            <fgb-child-panel [child]="child"></fgb-child-panel>
          </div>
        </ng-template>
        <ng-template *ngFor="let empty of emptyChildren" carouselSlide>
          <div class="no-child-border d-flex align-items-center justify-content-center rounded mx-2 mb-1">
            <img class="logo" src="assets/images/logo.svg" draggable="false" />
          </div>
        </ng-template>
      </owl-carousel-o>
    </div>
  </ng-template>

</ng-container>
