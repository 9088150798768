
<div class="step-container registration-success-page">
  <div class="registration-content">
    <h5 class="registration-success-page-title">{{ 'fgbcl.registration.success.page.title' | translate : { fgbdefault: 'Processed Successfully'} }}</h5> 
    <ng-container *ngIf="wizardType === wizardTypes.ChildRegistration; else defaultMessage">
      <h5 class="registration-success-page-summary-title">{{ 'registration.success.page.thank.you.message' | translate : { fgbdefault: 'Thank you for your purchase' } }}</h5>
    
      <a class="btn back-btn" [routerLink]="['/guardians']">{{ 'fgbcl.registration.success.page.back.button' | translate }}</a>
    </ng-container>
   
  </div>
  
  <ng-template #defaultMessage>
    <p class="message">{{ 'fgbcl.registration.success.page.account.message' | translate }}<em>{{ 'fgbcl.registration.success.page.account.instruction' | translate }}</em></p>
    <a class="btn back-btn" [routerLink]="['/']">{{ 'fgbcl.registration.success.page.login.button' | translate }}</a>
  </ng-template>
</div>
