import { Component, OnInit, AfterViewInit, ViewChild, ElementRef, HostListener, OnDestroy } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';
import {
  config,
  MemberDetails,
  MemberQuery,
  BalanceQuery,
  ProviderName,
  MemberCard,
  MemberCardQuery,
  KeyValuePair,
  Cart,
  CartQuery,
  Card,
  CardQuery,
} from '@fgb/core';
import { CardService } from '@fgb/core';
import { AuthService } from '@fgb/core';
import { Observable, interval, Subject } from 'rxjs';
import { map, distinctUntilChanged, takeUntil, tap } from 'rxjs/operators';
import { Populations } from 'src/app/shared/utilities/populations';
import { ProductCode } from 'src/app/shared/utilities/product-code';
import { NgbDropdown } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'fgb-nav-bar',
  templateUrl: './nav-bar.component.html',
  styleUrls: ['./nav-bar.component.scss'],
})
export class NavBarComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild('greedyNav', { static: true }) greedyNav: ElementRef;
  @ViewChild('greedyDropdown', { static: true }) greedyDropdown: ElementRef;
  breakPoints: number[] = [];
  shownLinks: number = 0;
  hiddenLinks: number = 0;
  populations = Populations;
  productcode = ProductCode;

  memberDetails$: Observable<MemberDetails | undefined>;
  combinedCard$: Observable<number | undefined>;
  loyaltyPointsCard$: Observable<number | undefined>;
  card$: Observable<MemberCard | undefined>;
  unseenNotificationCount$: Observable<number>;
  isCollapsed = false;
  sub: any;
  name$: Observable<string>;
  selectedMember: string;
  cards$: Observable<Card[]>;
  totalItems$: Observable<number>;
  basketPointsTotal$: Observable<number | undefined>;
  items$: Observable<Cart[] | undefined>;
  currentLanguage: string = '';
  private _destroyed$ = new Subject();

  constructor(
    private translate: TranslateService,
    public router: Router,
    public cardService: CardService,
    public memberQuery: MemberQuery,
    private authService: AuthService,
    private balanceQuery: BalanceQuery,
    private memberCardQuery: MemberCardQuery,
    private cardQuery: CardQuery,
    private cartQuery: CartQuery
  ) {}

  useLanguage(language: string) {
    this.translate.use(language);
  }

  ngOnInit() {
    this.loyaltyPointsCard$ = this.balanceQuery.selectPurse(config.purseConfig.virtualLoyalty);
    this.combinedCard$ = this.balanceQuery.selectPurse(config.purseConfig.combined);
    this.memberDetails$ = this.memberQuery.selectMemberDetails();
    this.card$ = this.memberCardQuery.selectObservedMemberCard();
    this.initMarketplaceCart();
    this.initAccountSwitch();
    this.initLcid();
  }

  ngAfterViewInit() {
    this.sub = interval(10).pipe(
      map(() => this.greedyNav.nativeElement.clientWidth),
      distinctUntilChanged()
    );
  }

  ngOnDestroy() {
    this._destroyed$.next();
    this._destroyed$.complete();
  }

  @HostListener('window:resize', ['$event'])
  initAccountSwitch() {
    this.name$ = this.memberQuery.selectMemberDetails().pipe(
      map((m) => {
        if (m) {
          return `${m.FirstName} ${m.Surname}`;
        }
        return '';
      })
    );

    this.selectedMember = this.memberQuery.getUserContext().memberId;
    this.cards$ = this.cardQuery.selectCards().pipe(
      map((cards: Card[]) =>
        cards
          .sort((a, b) => parseInt(a.Seat) - parseInt(b.Seat))
          .sort((a, b) => parseInt(a.Row) - parseInt(b.Row))
          .sort((a, b) => parseInt(a.Area) - parseInt(b.Area))
      )
    );
  }

  initLcid() {
    this.memberQuery.userContext$.pipe(takeUntil(this._destroyed$)).subscribe((user) => {
      if (user && user.lcid) {
        if (user.lcid === '2057') {
          this.currentLanguage = 'EN';
        } else if (user.lcid === '3084') {
          this.currentLanguage = 'FR';
        } else if (user.lcid === '1037') {
          this.currentLanguage = 'HE';
        } else if (user.lcid === '1033') {
          this.currentLanguage = 'US';
        }
      }
    });
  }

  initMarketplaceCart() {
    this.totalItems$ = this.cartQuery.updateTotalItems();
    this.basketPointsTotal$ = this.cartQuery.updateBasketPointsTotal();
    this.items$ = this.cartQuery.selectAllMarketplaceCartData();
  }

  initGreedyNav() {
    // recalculate on resize
    const navElement: HTMLElement = this.greedyNav.nativeElement;
    let totalWidth = 0;
    // tslint:disable-next-line: prefer-for-of
    for (let i = 0; i < navElement.children.length - 1; i++) {
      totalWidth += navElement.children[i].clientWidth;
      if (totalWidth > 0) {
        this.breakPoints[i] = totalWidth;
      }
    }
    this.shownLinks = navElement.children.length - 1;
    this.hiddenLinks = 0;
  }

  logout() {
    this.authService
      .logout()
      .pipe(tap(() => this.router.navigate(['/login'])))
      .toPromise()
      .then(() => {
        // Remove the class so it doesn't inherit the theme styles when logging out
        document.body.className = '';
      });
  }

  selectCard() {
    this.router.navigate(['/login']);
  }

  LoginThirdParty() {
    let options = [{ key: 'Url', value: 'https://sdp.sportsbingo.ballparkapps.com/?bingoUser={1}' }] as KeyValuePair[];
    this.authService.loginThirdParty(ProviderName.SidelineSports, 1, '', true, options);
  }

  toggleNotificationCenter(open: boolean) {}

  loginWithCard(memberId: string) {
    this.authService.switchUser(memberId).toPromise();
  }

  closeDropDown(dropdown: NgbDropdown) {
    dropdown.close();
  }
  
  switchToGuardian() {
    this.authService
      .switchToGuardian()
      .toPromise()
      .then(() => {
        this.router.navigate(['/']).then(() => {
          location.reload();
        });
      });
  }
}
