import { Component, OnInit } from '@angular/core';
import {
  CardLinkingFidelCountries,
  CardLinkingQuery,
  CardLinkingService,
  CardLinkingSettings,
  LinkedCard,
  MemberQuery,
} from '@fgb/core';
import { ScreenType } from '@fgb/portal-component-library';
import { Observable } from 'rxjs';

@Component({
  selector: 'fgb-card-linking-page',
  templateUrl: './card-linking-page.component.html',
  styleUrls: ['./card-linking-page.component.scss'],
})
export class CardLinkingPageComponent implements OnInit {
  screenType = ScreenType;

  linkedCardList$: Observable<LinkedCard[]>;
  memberId: string;
  dateFormat: string = 'MMM D, YYYY - hh:mm a';
  minDate = new Date('Aug 2,2022');
  cardLinkingSettings: CardLinkingSettings = {
    allowMultipleCardLinking: false,
  };
  countries: CardLinkingFidelCountries[] = [CardLinkingFidelCountries.GBR, CardLinkingFidelCountries.USA];
  constructor(
    private cardLinkingServices: CardLinkingService,
    private cardLinkingQuary: CardLinkingQuery,
    private memberQuery: MemberQuery
  ) {}

  ngOnInit(): void {
    this.memberId = this.memberId || this.memberQuery.getUserContext().memberId;
    this.cardLinkingServices.fetchFidelLinkedCard().toPromise();
    this.getLinkedCards();
  }

  /* Fetch the list of linked cards from the api*/
  getLinkedCards() {
    this.linkedCardList$ = this.cardLinkingQuary.selectAll();
  }
}
