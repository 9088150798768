<div class="banner" role="banner"></div>

<div class="container mt-4">
  <ng-container *notInPopulation="[ populations.Kids ]">
    <ng-container *ngIf="isGuardian; else notGuardian">
      <div>
        <fgb-child-list></fgb-child-list>
      </div> 
      <ng-container *ngTemplateOutlet="cardTemplate; context: 
          { 
            title: 'guardian.page.guardian.title' | translate : { fgbdefault: 'Wild Wingers Membership' },
            description: 'guardian.page.guardian.description' | translate | markdownTranslate
          }">
      </ng-container>
    </ng-container>
    <ng-template #notGuardian>
      <div class="text-left my-2">
        <ng-container *ngTemplateOutlet="cardTemplate; context: 
            { 
              title: 'guardian.page.not.guardian.title' | translate : { fgbdefault: 'Wild Wingers Membership' },
              description: 'guardian.page.not.guardian.description' | translate | markdownTranslate
            }">
        </ng-container>
      </div>
      <!-- <div class="text-center my-2">
        <div class="row">
          <div class="col-lg-6 col-12">
            <div class="membership-panel rounded overflow-hidden shadow mt-lg-4 mt-3">
              <div class="membership-body text-white pt-3 pb-3 px-2">
                <h2 class="mb-4">Free</h2>
                <h4>Basic Membership</h4>
                <div class="mt-3 text-uppercase font-size-16 font-weight-bold">available for all fans ages 12 and under!</div>
              </div>
              <div class="membership-dropdown">
                <ngb-accordion #a="ngbAccordion" [destroyOnHide]="false">
                  <ngb-panel>
                    <ng-template ngbPanelHeader>
                      <div class="row">
                        <div class="col-10 membership-include-text font-weight-bold text-primary text-left">
                          Basic Membership Benefits Include:
                        </div>
                        <div class="col-1 offset-1 px-0">
                          <button
                            class="btn"
                            type="button"
                            attr.aria-label="{{ 'guardian.membership.details' | translate }}"
                            ngbPanelToggle
                          >
                            <div>
                              <div class="dropdown-icon kid-dropdown svg" [inlineSVG]="'assets/images/icons/down-icon.svg'"></div>
                            </div>
                          </button>
                        </div>
                      </div>
                    </ng-template>
                    <ng-template ngbPanelContent>
                      <div class="row">
                        <div class="content font-size-12 col-12">
                          <div class="text-left">
                            - Membership card and lanyard<br />
                            - Play games in the Fortress Portal<br />
                            - Exclusive email offers<br />

                            *Offers subject to change
                          </div>
                        </div>
                      </div>
                    </ng-template>
                  </ngb-panel>
                </ngb-accordion>
              </div>
            </div>
          </div>
          <div class="col-lg-6 col-12">
            <div class="membership-panel rounded overflow-hidden shadow mt-lg-4 mt-3">
              <div class="membership-body mvp text-white pt-3 pb-3 px-2">
                <h2 class="mb-4">$20</h2>
                <h4>King membership</h4>
                <div class="mt-3 text-uppercase font-size-16 font-weight-bold">available to all fans ages 4-12!</div>
              </div>
              <div class="membership-dropdown">
                <ngb-accordion #a="ngbAccordion" [destroyOnHide]="false">
                  <ngb-panel>
                    <ng-template ngbPanelHeader>
                      <div class="row">
                        <div class="col-10 membership-include-text font-weight-bold text-primary text-left">
                          King Membership Benefits Include:
                        </div>
                        <div class="col-1 offset-1 px-0">
                          <button
                            class="btn"
                            type="button"
                            attr.aria-label="{{ 'guardian.membership.details' | translate }}"
                            ngbPanelToggle
                          >
                            <div>
                              <div class="dropdown-icon kid-dropdown svg" [inlineSVG]="'assets/images/icons/down-icon.svg'"></div>
                            </div>
                          </button>
                        </div>
                      </div>
                    </ng-template>
                    <ng-template ngbPanelContent>
                      <div class="row">
                        <div class="content font-size-12 col-12">
                          <div class="text-left">
                            - Membership card and lanyard<br />
                            - Play games in the Fortress Portal<br />
                            - Exclusive email offers<br />
                            - 2020 Fortress yearbook<br />

                            *Offers subject to change
                          </div>
                        </div>
                      </div>
                    </ng-template>
                  </ngb-panel>
                </ngb-accordion>
              </div>
            </div>
          </div>
        </div>
      </div> -->
    </ng-template>
  </ng-container>
</div>

<!-- Card Template -->

<ng-template #cardTemplate let-title="title" let-message="message" let-description="description">
  <div class="card shadow rounded overflow-hidden guardian-info text-left my-4">
    <div class="img-wrapper">
      <img alt="" class="w-100 h-100" src="assets/images/guardian.png" />
    </div>
    <div class="description">
        <h5 class="text-primary">{{ title }}</h5>
        <p class="font-size-14 mt-2" [innerHtml]="description">
        </p>
        <button
          *ngIf="!isGuardian"
          (click)="openGuardianModal()"
          class="btn btn-primary btn-guardian font-size-14 text-center text-white mt-auto w-100 font-weight-bold"
        >
          {{ 'guardian.page.button.register.as.guardian' | translate : { fgbdefault: 'Register as a Guardian' } }}
        </button>

    </div>

</div>
</ng-template>

<!-- Guardian Modal -->

<ng-template #guardianModal>
  <div class="guardian-modal-header">
    <span class="guardian-modal-logo"></span>
    <h5 class="guardian-modal-title text-primary" id="modal-basic-title">{{ 'guardian.page.modal.title' | translate : { fgbdefault: 'Guardian' } }}</h5>
  </div>
  <hr/>
  <div class="modal-body">
    <p class="guardian-modal-prompt">
      {{ 'guardian.page.modal.prompt.message' | translate : { fgbdefault: 'Are you sure you wish to become a Guardian?' } }}
    </p>
    <div class="action-wrapper">
      <button class="btn btn-danger cancel-btn" (click)="modal.close()">{{ 'guardian.page.modal.button.cancel' | translate : { fgbdefault: 'Cancel' } }}</button>
      <button class="btn btn-success cancel-btn" (click)="becomeGuardian()">{{ 'guardian.page.modal.button.confirm' | translate : { fgbdefault: 'Confirm' } }}</button>
    </div>
  </div>
</ng-template>
