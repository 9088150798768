<div class="my-4">
  <div class="survey-answer shadow">
    <div class="survey-answer-container justify-content-center pending">
      <div class="survey-answer-item w-100" *ngFor="let answer of selectedAnswers">
        <div class="d-flex flex-row">
          <img class="image-container icon" src="{{ answer.ImageURL | contentImage: '7' }}" alt="{{ answer.ImageURL }}" />
          <div class="answer-text-landscape text-center text-white">
            <span>{{ answer.Title }}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="d-flex flex-column align-items-center">
    <div class="text-center font-weight-bold font-size-12 mt-4 mb-2 submitted-text">{{ 'survey.predictor.pending' | translate: { fgbdefault: 'Thank you for submitting your selection' } }}</div>
    <div class="text-center font-size-12 mb-lg-5 mb-3 instructions">
      {{ 'survey.predictor.pending.instructions' | translate : { fgbdefault: 'Please come back after the conclusion of this predictor to view your results.' } }}
    </div>
  </div>
</div>
